import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  coords: {
    [panoId: string]: number[][]
  }
}

interface Payload {
  id: string
  coords: number[][]
}

const initialState: State = {
  coords: {}
}

const panoramaSlice = createSlice({
  name: 'panorama',
  initialState,
  reducers: {
    addCoords: (state, action: PayloadAction<Payload>) => {
      state.coords[action.payload.id] = action.payload.coords
    }
  }
})

export const { addCoords } = panoramaSlice.actions

export default panoramaSlice
