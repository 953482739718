import { ElectricalSteps, Groups, MechanicalSteps } from '@modules/missions/keaz/types'

export const labels: {
  [Groups.mechanical]: Record<MechanicalSteps, string>
  [Groups.electrical]: Record<ElectricalSteps, string>
} = {
  [Groups.mechanical]: {
    [MechanicalSteps.machineIdentification]: 'Идентификация станка',
    [MechanicalSteps.hydraulicConnectionCheck]:
      'Проверка гидравлических соединений, рукавов и хомутов',
    [MechanicalSteps.pressureAndPumpCheck]:
      'Проверка давления нагнетания и мощности гидравлического насоса',
    [MechanicalSteps.safetyCheck]:
      'Проверка защитных экранов на надежность фиксации и отсутствие люфтов, а также стопоров и рычагов',
    [MechanicalSteps.limitSwitchCheck]:
      'Проверка концевиков основного защитного экрана и экрана сопла и их направляющих',
    [MechanicalSteps.injectionUnitLeakCheck]:
      'Проверка на отсутствие течей масла цилиндров узлов впрыска, при необходимости замена уплотнений',
    [MechanicalSteps.clampingUnitLeakCheck]:
      'Проверка на отсутствие течей масла цилиндров узлов смыкания, при необходимости замена уплотнений',
    [MechanicalSteps.ejectionUnitLeakCheck]:
      'Проверка на отсутствие течей масла цилиндров узлов выталкивания, при необходимости замена уплотнений',
    [MechanicalSteps.fasteningCheck]: 'Проверка надежности затяжки всех крепежных элементов',
    [MechanicalSteps.coolingSystemCheck]:
      'Проверка системы охлаждения - соединения шлангов на кольцо охлаждения цилиндра',
    [MechanicalSteps.coolingCircuitFlush]: 'Промывка и проверка работы контура охлаждения',
    [MechanicalSteps.oilChangeAndFilterCleaning]:
      'Замена масла, очистка всасывающего и заливного фильтров',
    [MechanicalSteps.fineOilFilterCheck]: 'Проверка и замена масляного фильтра тонкой очистки',
    [MechanicalSteps.lubricationSystemCheck]: 'Проверка системы смазки',
    [MechanicalSteps.equipmentCleaning]: 'Чистка оборудования'
  },
  [Groups.electrical]: {
    [ElectricalSteps.machineIdentification]: 'Идентификация станка',
    [ElectricalSteps.currentCheckUnderLoad]: 'Проверка силы тока по фазе при номинальной нагрузке',
    [ElectricalSteps.noiseAndVibrationCheck]:
      'Проверка на наличие нестандартного шума и вибрации двигателя',
    [ElectricalSteps.cabinetAndMotorCleaning]:
      'Очистка электрического шкафа и электродвигателя от пыли и СОЖ',
    [ElectricalSteps.heaterZonesCheck]: 'Проверка зональных нагревателей',
    [ElectricalSteps.electricalConnectionsCheck]:
      'Проверка надежности всех электрических соединений',
    [ElectricalSteps.injectionMoldingMachineMovementsCheck]:
      'Проверка всех ходов термопластавтомата'
  }
}
