import { BaseReportFields, StepStatus } from '@modules/missions/keaz/types'
import { useTimer } from '@modules/missions/keaz/group/hooks/useTimer'
import { AgentumReportWithRelations } from '@api/generated'

export interface InProgressTimeProps {
  step: AgentumReportWithRelations
}

export function InProgressTime({ step }: InProgressTimeProps) {
  const [timeSpent] = useTimer(
    step[BaseReportFields.timestampStart] as number | undefined,
    step[BaseReportFields.timestampEnd] as number | undefined,
    step[BaseReportFields.status] !== StepStatus.inProgress
  )

  return <>{timeSpent}</>
}
