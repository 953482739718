import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import isObject from 'lodash/isObject'

export interface ErrorType {
  message: string
  name?: unknown
  statusCode?: unknown
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isErrorType(e: any): e is ErrorType {
  return isObject(e) && 'message' in e
}

export const fakeQuery = function fakeBaseQuery<ErrorType>(): BaseQueryFn<
  unknown,
  unknown,
  ErrorType
> {
  return function () {
    throw new Error(
      'When using `fakeBaseQuery`, all queries & mutations must use the `queryFn` definition syntax.'
    )
  }
}

export type FakeQueryType = ReturnType<typeof fakeQuery>
