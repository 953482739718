import * as THREE from 'three'
import { Point } from '@modules/panorama/types'

export enum Element {
  Point = 'point',
  Line = 'line',
  Polygon = 'polygon'
}

export interface Item {
  id: Element
  label: string
}

export interface ObjectItem {
  id: string
  type: Element
}

export interface PointItem extends ObjectItem {
  type: Element.Point
  entity: THREE.Intersection<THREE.Object3D<THREE.Object3DEventMap>>
}

export type LineItemPoints = [Point, Point]

export interface LineItem extends ObjectItem {
  type: Element.Line
  points: LineItemPoints
}

export interface PolygonItem extends ObjectItem {
  type: Element.Polygon
  points: Point[]
}
