import { useState } from 'react'
import { useCursor } from '@react-three/drei'
import { ThreeEvent } from '@react-three/fiber'

export function useHovered() {
  const [isHovered, setIsHover] = useState(false)

  useCursor(isHovered)

  const onPointerOver = (event: ThreeEvent<MouseEvent>) => {
    if (event.ctrlKey === true) {
      return
    }

    setIsHover(true)
  }

  const onPointerOut = (event: ThreeEvent<MouseEvent>) => {
    if (event.ctrlKey === true) {
      return
    }

    setIsHover(false)
  }

  return { isHovered, onPointerOver, onPointerOut }
}
