import React, { PropsWithChildren } from 'react'
import cn from 'classnames'
import style from './title.module.less'

export interface TitleProps {
  className?: string
  level?: 1 | 2 | 3 | 4
}

export const Title = ({ children, className, level = 1 }: PropsWithChildren<TitleProps>) => {
  const Tag = `h${level}` as const
  return <Tag className={cn(className, style.title, style[`title-h${level}`])}>{children}</Tag>
}
