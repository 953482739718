import style from './formGroup.module.less'
import { ReactNode } from 'react'
import cs from 'classnames'

interface FormGroupProps {
  children: ReactNode | ReactNode[]
  compact?: boolean
  className?: string
}

export function FormGroup({ compact, className, children }: FormGroupProps) {
  return (
    <div
      className={cs(
        {
          [style.formGroupCompact]: compact,
          [style.formGroup]: !compact
        },
        className
      )}
    >
      {children}
    </div>
  )
}
