import styles from './errorMessage.module.less'
import React from 'react'
import { useFormState } from 'react-final-form'
import get from 'lodash/get'
import { Text } from '@/components/ui/typography/text'
import cn from 'classnames'

export interface ErrorDivProps<T> {
  fieldName: keyof T
  className?: string
}

export function ErrorMessage<T>({ fieldName, className }: ErrorDivProps<T>) {
  const { errors, touched } = useFormState<T>()
  const error = get(errors, fieldName) as T[keyof T]

  const isTouched = touched ? Object.values(touched).find((el) => el) : false

  return (
    <>
      {!!error && !!isTouched && (
        <Text size='s' tag='div' className={cn(className, styles.errorMessage)}>
          {error.toString()}
        </Text>
      )}
    </>
  )
}
