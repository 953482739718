export const config = {
  apiUrl: process.env.REACT_APP_API_URL || `REACT_APP_API_URL is not set`,
  map: {
    style:
      process.env.REACT_APP_MAP_STYLE || 'https://map.agentum.beget.tech/styles/agentum/style.json'
  },
  panorama: {
    origin: process.env.REACT_APP_PANORAMA_ORIGIN || 'https://lk.agentum.beget.tech',
    imageUrl: process.env.REACT_APP_PANORAMA_IMGAE_URL || 'panorama'
  }
}
