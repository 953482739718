import styles from './rejectStepConfirmationModal.module.less'
import { Button, Modal } from '@smwb/summer-ui'
import { Title } from '@/components/ui/typography/title'
import { Icon } from '@/components/ui/base/icon/icon'
import { Form } from 'react-final-form'
import { yupValidate } from '@utils/yupValidate'
import * as yup from 'yup'
import { TextArea } from '@/components/ui/connects/rff'
import cn from 'classnames'

export interface CancelStepModalProps {
  isOpen: boolean
  loading?: boolean
  onAccept: (reason: string) => void
  onCancel: () => void
}

const schema = yup.object().shape({
  reason: yup.string().required('Комментарий обязательное поле')
})

const validate = yupValidate(schema)

export function RejectStepConfirmationModal({
  isOpen,
  loading,
  onAccept,
  onCancel
}: CancelStepModalProps) {
  const onSubmit = (values: { reason: string }) => {
    void onAccept(values.reason)
  }

  return (
    <Modal
      animation={'fade'}
      asForm={false}
      backdrop='dark'
      className={cn(styles.modal, styles['smwb-modal'], styles['smwb-large'])}
      closeOnOutsideClick={true}
      onClose={onCancel}
      open={isOpen}
      size='large'
      disableAppearAnimation={false}
    >
      <div className={styles.closeButton} onClick={onCancel}>
        <Icon icon='close' />
      </div>

      <Modal.Header className={styles.modalHeader}>
        <Title level={1}>Укажите причину отклонения объекта</Title>
      </Modal.Header>

      <Modal.Content className={styles.modalContent}>
        <Form
          validate={validate}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form>
              <TextArea
                label='Комментарий'
                name='reason'
                rows={5}
                fullWidth
                placeholder='Опишите подробно причину, ошибки и пожелания'
              />

              <div className={styles.buttons}>
                <Button
                  variant='outlined'
                  type='button'
                  label='Отмена'
                  size='large'
                  disabled={loading}
                  onClick={onCancel}
                />

                {/* eslint-disable @typescript-eslint/no-misused-promises */}
                <Button
                  type='button'
                  label='Подтвердить'
                  size='large'
                  disabled={loading}
                  onClick={handleSubmit}
                />
                {/* eslint-enable @typescript-eslint/no-misused-promises */}
              </div>
            </form>
          )}
        />
      </Modal.Content>
    </Modal>
  )
}
