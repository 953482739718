import React, { PropsWithChildren } from 'react'
import cn from 'classnames'
import style from './text.module.less'

export interface TextProps {
  className?: string
  tag?: 'span' | 'p' | 'div'
  size?: 's' | 'm' | 'l'
}

export const Text = ({
  children,
  className,
  tag = 'span',
  size = 'm'
}: PropsWithChildren<TextProps>) => {
  const Tag = tag
  return <Tag className={cn(className, style.text, style[`text-${size}`])}>{children}</Tag>
}
