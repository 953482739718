import { apiCall } from '@api/resources/apiCall'
import { useParams } from 'react-router-dom'
import { MissionType } from '@modules/missions/const'
import { PotokFmMission } from '@modules/missions/potok-fm'
import { KeazMission } from '@modules/missions/keaz'

export function Landing() {
  const { id } = useParams()
  const { data = [], isLoading } = apiCall.useMissionsControllerFindQuery([])
  if (isLoading) {
    return <div>'loading'</div>
  }
  const mission = data.find((el) => el._id === id)
  if (!mission) {
    return <div>{`Миссия с id ${id || 'unknown'} не найден`}</div>
  }

  switch (mission.mission) {
    case MissionType.potokFM:
      return <PotokFmMission />
    case MissionType.keaz:
      return <KeazMission />
  }

  return (
    <div>
      <div>Шаблон для миссия не найден</div>
      <pre>{JSON.stringify(mission, undefined, 4)}</pre>
    </div>
  )
}
