export const leadingZero = (num: number): string => String(num).padStart(2, '0')

export const secondsToHHMMSS = (secondsArray?: number[] | number): string => {
  const totalSeconds = (Array.isArray(secondsArray) ? secondsArray : [secondsArray || 0]).reduce(
    (acc, sec) => acc + sec,
    0
  )

  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  if (hours > 0) {
    return `${leadingZero(hours)}:${leadingZero(minutes)}:${leadingZero(seconds)}`
  } else {
    return `${leadingZero(minutes)}:${leadingZero(seconds)}`
  }
}

export const secondsSince = (timestampStart?: number, timestampEnd = Date.now()): number => {
  if (!timestampStart) {
    return 0
  }

  const elapsedMilliseconds = timestampEnd - timestampStart

  return Math.floor(elapsedMilliseconds / 1000)
}
