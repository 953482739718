import { rtkBuilderQuery } from '@api/utils/rtkBuilderQuery'
import { BaseAPI } from '@api/generated/base'
import { ClassMethodsObject } from '@utils/utility-types'
import type { EndpointBuilder, BaseQueryFn } from '@reduxjs/toolkit/query'
import { rtkBuilderMutation } from '@api/utils/rtkBuilderMutation'
import { FakeQueryType } from '@api/utils/fakeQuery'

function isFunctionType<C extends BaseAPI, K extends keyof ClassMethodsObject<C>>(
  value: C[K]
  // eslint-disable-next-line @typescript-eslint/ban-types
): value is C[K] & Function {
  return typeof value === 'function'
}

function bindMethods<
  B extends typeof rtkBuilderQuery,
  C extends BaseAPI,
  K extends keyof ClassMethodsObject<C>,
  TagTypes extends string
>(
  rtkBuilder: B,
  build: EndpointBuilder<BaseQueryFn, TagTypes, string>,
  cl: C,
  m: K[],
  tags?: TagTypes
): {
  [T in K]: ReturnType<
    typeof rtkBuilderQuery<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Parameters<C[T] extends (...args: any) => any ? C[T] : never>,
      TagTypes,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Awaited<ReturnType<C[T] extends (...args: any) => any ? C[T] : never>>['data']
    >
  >
}
function bindMethods<
  B extends typeof rtkBuilderMutation,
  C extends BaseAPI,
  K extends keyof ClassMethodsObject<C>,
  TagTypes extends string
>(
  rtkBuilder: B,
  build: EndpointBuilder<BaseQueryFn, TagTypes, string>,
  cl: C,
  m: K[],
  tags?: TagTypes
): {
  [T in K]: ReturnType<
    typeof rtkBuilderMutation<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Parameters<C[T] extends (...args: any) => any ? C[T] : never>,
      TagTypes,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Awaited<ReturnType<C[T] extends (...args: any) => any ? C[T] : never>>['data']
    >
  >
}
function bindMethods<
  B extends typeof rtkBuilderQuery | typeof rtkBuilderMutation,
  C extends BaseAPI,
  K extends keyof ClassMethodsObject<C>,
  TagTypes extends string
>(
  rtkBuilder: B,
  build: EndpointBuilder<BaseQueryFn, TagTypes, string>,
  cl: C,
  m: K[],
  tags?: TagTypes
) {
  const result = {} as {
    [T in K]: ReturnType<
      typeof rtkBuilder<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Parameters<C[T] extends (...args: any) => any ? C[T] : never>,
        TagTypes,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Awaited<ReturnType<C[T] extends (...args: any) => any ? C[T] : never>>['data']
      >
    >
  }
  for (const methodName of m) {
    const method = cl[methodName]
    if (isFunctionType(method)) {
      // eslint-disable-next-line  @typescript-eslint/no-unsafe-argument
      result[methodName] = rtkBuilder(build, method.bind(cl), tags ? [tags] : undefined)
    } else {
      console.error('is not e function', methodName, method, typeof method)
    }
  }
  return result
}

export function bindMethodsQuery<
  C extends BaseAPI,
  K extends keyof ClassMethodsObject<C>,
  TagTypes extends string
>(build: EndpointBuilder<FakeQueryType, never, string>, cl: C, m: K[], tags?: TagTypes) {
  return bindMethods(rtkBuilderQuery, build, cl, m, tags)
}

export function bindMethodsMutation<
  C extends BaseAPI,
  K extends keyof ClassMethodsObject<C>,
  TagTypes extends string
>(build: EndpointBuilder<FakeQueryType, never, string>, cl: C, m: K[], tags?: TagTypes) {
  return bindMethods(rtkBuilderMutation, build, cl, m, tags)
}
