import React from 'react'
import cn from 'classnames'
import style from './icon.module.less'

import { ReactComponent as IconCheck } from './icons/check.svg'
import { ReactComponent as IconClose } from './icons/close.svg'
import { ReactComponent as IconConnection } from './icons/connection.svg'
import { ReactComponent as IconDelete } from './icons/delete.svg'
import { ReactComponent as IconDiscoverTune } from './icons/discover_tune.svg'
import { ReactComponent as IconDownload } from './icons/download.svg'
import { ReactComponent as IconEdit } from './icons/edit.svg'
import { ReactComponent as IconImageRotate } from './icons/image_rotate.svg'
import { ReactComponent as IconLayers } from './icons/layers.svg'
import { ReactComponent as IconList } from './icons/list.svg'
import { ReactComponent as IconMap } from './icons/map.svg'
import { ReactComponent as IconMark } from './icons/mark.svg'
import { ReactComponent as IconMinus } from './icons/minus.svg'
import { ReactComponent as IconMore } from './icons/more.svg'
import { ReactComponent as IconPlus } from './icons/plus.svg'
import { ReactComponent as IconRing } from './icons/ring.svg'
import { ReactComponent as IconRuler } from './icons/ruler.svg'
import { ReactComponent as IconSearch } from './icons/search.svg'
import { ReactComponent as IconSettings } from './icons/settings.svg'
import { ReactComponent as IconSquare } from './icons/square.svg'
import { ReactComponent as IconSquareSelect } from './icons/square_select.svg'
import { ReactComponent as IconArrowBack } from './icons/arrow_back.svg'

export const iconsMap = {
  check: IconCheck,
  close: IconClose,
  connection: IconConnection,
  delete: IconDelete,
  discover_tune: IconDiscoverTune,
  download: IconDownload,
  edit: IconEdit,
  image_rotate: IconImageRotate,
  layers: IconLayers,
  list: IconList,
  map: IconMap,
  mark: IconMark,
  minus: IconMinus,
  more: IconMore,
  plus: IconPlus,
  ring: IconRing,
  ruler: IconRuler,
  search: IconSearch,
  settings: IconSettings,
  square: IconSquare,
  square_select: IconSquareSelect,
  arrow_back: IconArrowBack
}

export type IconNameType = keyof typeof iconsMap

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  icon: IconNameType
  color?: 'orange' | 'black' | 'silver' | 'white'
}

export const Icon = ({ className, icon, color, ...props }: IconProps) => {
  const SvgIcon = iconsMap[icon]
  return (
    <SvgIcon {...props} className={cn(className, style.icon, color && style[`icon-${color}`])} />
  )
}
