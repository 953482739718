import style from './stepsTable.module.less'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow
} from '@smwb/summer-ui'
import { BaseReportFields, StepStatus } from '@modules/missions/keaz/types'
import { InProgressTime } from '@modules/missions/keaz/group/components/inProgressTime'
import { secondsToHHMMSS } from '@modules/missions/keaz/utils/time'
import { AgentumReportWithRelations } from '@api/generated'
import { getStepTitle } from '@modules/missions/keaz/utils/step'

interface StepsTableProps {
  steps: AgentumReportWithRelations[]
  isLoading: boolean
  className?: string
  handleRowButtonClick: (step: AgentumReportWithRelations) => void
}

const headers = [
  'Название шага',
  'Планируемое время выполнения',
  'Фактическое время выполнения',
  'Статус',
  ''
]

const getStatusColor = (status: StepStatus) => {
  let color = 'black'

  switch (status) {
    case StepStatus.signed:
      color = 'gray2'
      break
    case StepStatus.default:
      color = 'gray5'
      break
    case StepStatus.approved:
      color = 'success'
      break
    case StepStatus.inProgress:
      color = 'warning'
      break
    case StepStatus.rejected:
      color = 'orange'
      break
  }

  return `var(--agm-color-${color})`
}

export function StepsTable({ steps, isLoading, className, handleRowButtonClick }: StepsTableProps) {
  return (
    <Table isLoading={isLoading} className={className}>
      <TableHead>
        <TableRow>
          {headers.map((h) => (
            <TableHeadCell key={h} cellAlign='left'>
              {h}
            </TableHeadCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {steps.map((step) => (
          <TableRow key={step[BaseReportFields.id]}>
            <TableCell className={style.stepNameColumn}>
              {step[BaseReportFields.stepNumber]}. {getStepTitle(step)}
            </TableCell>

            <TableCell>
              {secondsToHHMMSS((step[BaseReportFields.timeNorm] as number[]) || [])}
            </TableCell>

            <TableCell>{<InProgressTime step={step} />}</TableCell>

            <TableCell
              style={{ color: getStatusColor(step[BaseReportFields.status] as StepStatus) }}
            >
              {step[BaseReportFields.status]}
            </TableCell>

            <TableCell>
              <Button size='small' onClick={() => handleRowButtonClick(step)}>
                Перейти
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
