import styles from './qrScannerField.module.less'
import stylesIndex from '../index.module.less'
import React from 'react'
import { useForm, useFormState } from 'react-final-form'
import { Icon } from '@smwb/summer-ui'
import cn from 'classnames'
import { Text } from '@/components/ui/typography/text'
import { Card } from '@modules/missions/keaz/step/components/card'
import { TextField } from '@smwb/summer-ui/dist/connects/rff'
import { FormValues } from '@modules/missions/keaz/types'

export interface QrScannerCardProps<T> {
  compact: boolean
  fieldName: keyof T
}

export function QrScannerCard<T extends FormValues>({ compact, fieldName }: QrScannerCardProps<T>) {
  const { change } = useForm()
  const { values } = useFormState<T>()

  return (
    <Card className={stylesIndex.card}>
      <div className={stylesIndex.inputGroup}>
        <TextField
          label='Серийный номер'
          fullWidth={true}
          disabled={true}
          value={values[fieldName] ? values[fieldName].toString() : ''}
          name='serialNumber'
        />
      </div>

      <div className={stylesIndex.inputGroup}>
        <div>
          <div className={styles.inputLayout}>
            <button
              disabled={compact}
              className={cn(styles.sideButton, styles.scanButton)}
              onClick={() => ({})}
            >
              <Icon name='qr_code_scanner' />
            </button>

            <TextField name={fieldName as string} fullWidth={true} disabled={compact} />

            <button
              disabled={compact}
              className={cn(styles.sideButton, styles.resetButton)}
              onClick={() => change(fieldName as string, '')}
            >
              <Icon name='crop_square' fill={1} />
            </button>
          </div>
        </div>
      </div>
    </Card>
  )
}
