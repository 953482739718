import { apiCall } from '@api/resources/apiCall'
import { Message } from '@smwb/summer-ui'
import { getErrorMessage } from '@api/utils/errorHandler'
import { generatePath, Link } from 'react-router-dom'
import style from './missions.module.less'
import { Urls } from '@/urls'

export function Missions() {
  const { data = [], isLoading, error } = apiCall.useMissionsControllerFindQuery([])

  if (isLoading) {
    return <div>'loading'</div>
  }
  return (
    <div>
      <div className={style.layout}>
        {data.map((el) => {
          return (
            <div key={el._id} className={style.missionBlock}>
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              <Link to={generatePath(Urls.Mission, { id: el._id! })}>
                <div className={`${style.missionBlock} ${style.icon}`}>
                  {el.icon && (
                    <img
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(el.icon)}`}
                      width={40}
                      height={40}
                    />
                  )}
                </div>
              </Link>
              <div>{el.mission_name}</div>
            </div>
          )
        })}
        {!!error && (
          <Message variant={'filled'} type='error'>
            {getErrorMessage(error)}
          </Message>
        )}
      </div>
    </div>
  )
}
