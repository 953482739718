import { BaseReportFields, GroupStatus, StepStatus } from '@modules/missions/keaz/types'
import { AgentumReportWithRelations } from '@api/generated'
import { secondsSince } from '@modules/missions/keaz/utils/time'

export const isCompletedStep = (step: AgentumReportWithRelations) => {
  return isApprovedStep(step) || step[BaseReportFields.status] === StepStatus.signed
}

export const isApprovedStep = (step: AgentumReportWithRelations) => {
  return step[BaseReportFields.status] === StepStatus.approved
}

export const getSummaryStepsData = (steps: AgentumReportWithRelations[]) => {
  /*
   * Правила отображения статусов задач и разделов
   * 1 "Не обследован": Если все шаги в задаче/разделе имеют статус "Не обследован".
   * 2 "В работе": Если хотя бы один шаг в задаче/разделе имеет статус "В работе".
   * 3 "Ожидает проверки": Если хотя бы один шаг в задаче/разделе имеет статус "Ожидает проверки".
   * 4 "Отклонен": Если хотя бы один шаг в задаче/разделе имеет статус "Отклонен".
   * 5 "Принят": Если все шаги в задаче/разделе имеют статус "Принят".
   * 6 (такие статусы пока не реализованы) "Отправлено в ERP...": Если все шаги в задаче имеют статус "Принят" и отчетность отправлена в ERP клиента.
   * 7 (такие статусы пока не реализованы) Дополнительный статус (не прописан в таблице): Если все шаги в задаче имеют статус "Принят" и отчетность отклонена в ERP клиента.
   */

  let hasInProgress = false
  let hasSigned = false
  let hasRejected = false
  let allApproved = true

  let timeSpent = 0
  let timePlan = 0
  let completedSteps = 0
  let approvedSteps = 0
  const totalSteps = steps.length

  steps.forEach((step) => {
    timeSpent += secondsSince(
      step[BaseReportFields.timestampStart] as number | undefined,
      step[BaseReportFields.timestampEnd] as number | undefined
    )
    timePlan += ((step[BaseReportFields.timeNorm] as number[]) || []).reduce(
      (acc: number, sec: number) => acc + sec,
      0
    )
    completedSteps += +isCompletedStep(step)
    approvedSteps += +isApprovedStep(step)

    if (step.status === GroupStatus.inProgress) {
      hasInProgress = true
    }
    if (step.status === GroupStatus.signed) {
      hasSigned = true
    }
    if (step.status === GroupStatus.rejected) {
      hasRejected = true
    }
    if (step.status !== GroupStatus.approved) {
      allApproved = false
    }
  })

  let status = GroupStatus.default

  if (hasRejected) {
    status = GroupStatus.rejected
  } else if (hasSigned) {
    status = GroupStatus.signed
  } else if (hasInProgress) {
    status = GroupStatus.inProgress
  } else if (allApproved) {
    // if (erpStatus === 'sended') {
    //   status = GroupStatus.sendedToERP;
    // } else if (erpStatus === 'rejected') {
    //   status = GroupStatus.rejected;
    // } else {
    status = GroupStatus.approved
    // }
  }

  return { timeSpent, timePlan, totalSteps, completedSteps, status, approvedSteps }
}
