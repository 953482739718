// Адаптация Yup для работы с react-final-form
import { ValidationError, ObjectSchema, AnyObject } from 'yup'
import { setIn } from 'final-form'

export const yupValidate =
  <K extends AnyObject>(schema: ObjectSchema<K>) =>
  async (values: K) => {
    try {
      await schema.validate(values, { abortEarly: false })
    } catch (e) {
      const errors = (e as ValidationError).inner.reduce((formError, innerError) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return setIn(formError, innerError.path!, innerError.message)
      }, {})
      return errors
    }
  }
