import { useEffect, useRef, useState } from 'react'
import { FloatWindow } from '@modules/common/floatWindow/floatWindow'
import { Map, Point } from '@modules/common/map/map'
import { apiCall } from '@api/resources/apiCall'
import { Panorama } from '@modules/panorama/panorama'
import { config } from '@/settings'
import axios from 'axios'
import { ProgressIndicator } from '@smwb/summer-ui'
import { GeoData } from '@modules/panorama/types'
import style from './potok-fm.module.less'

const initSize = {
  width: 400,
  height: 400
}

const initZoom = 19

function getPanoId(id?: string, geo: GeoData[] = []): string | undefined {
  const point = geo.find((el) => el._id === id)
  return point ? point.properties?.panoId : undefined
}

function getPanoramaImageUrl(id?: string, geo: GeoData[] = []) {
  const panoId = getPanoId(id, geo)
  return panoId ? `${config.panorama.origin}/${config.panorama.imageUrl}?id=${panoId}` : undefined
}

function usePrefetchPano(geo: GeoData[] = [], id?: string): [string | undefined, boolean] {
  const [panoramaImageUrl, setPanoramaImageUrl] = useState<string | undefined>(undefined)
  const [loading, setIsLoading] = useState(false)
  useEffect(() => {
    if (!id) {
      return
    }
    const imgUrl = getPanoramaImageUrl(id, geo)
    if (imgUrl) {
      setIsLoading(true)
      axios(imgUrl)
        .then(() => {
          setPanoramaImageUrl(getPanoramaImageUrl(id, geo))
          setIsLoading(false)
          const index = geo.findIndex((el) => el._id === id) - 3
          for (let i = index; i < index + 6 && i < geo.length; i++) {
            if (i >= 0 && i !== index) {
              const panoUrl = getPanoramaImageUrl(geo[i]._id, geo)
              if (panoUrl) {
                void axios(panoUrl)
              }
            }
          }
        })
        .catch((e) => {
          setIsLoading(false)
          console.error(e)
        })
    }
  }, [geo, id])
  return [panoramaImageUrl, loading]
}

function getStepGeoData(geo: GeoData[], startIndex: number): GeoData[] {
  const result: GeoData[] = []
  const step = 2
  const stepX2 = step * 2

  if (geo.length < stepX2 + 3) {
    return result
  }

  if (geo[startIndex + step]) {
    result.push(geo[startIndex + step])

    if (geo[startIndex + stepX2]) {
      result.push(geo[startIndex + stepX2])
    } else {
      result.push(geo[startIndex - step])
    }
  } else {
    result.push(geo[startIndex - step])
    result.push(geo[startIndex - stepX2])
  }

  return result
}

export function PotokFmMission() {
  const { data: geoDataQuery = [] as GeoData[] } = apiCall.useGeoJsonControllerFindQuery([])
  const data = geoDataQuery as GeoData[]
  const [points, setPoints] = useState<Point[]>([])
  const [[longitude, latitude], setCenter] = useState([30.322883, 59.933947])
  const [currentGeoId, setCurrentGeoId] = useState<string | undefined>(undefined)
  const [initialGeoId, setInitialGeoId] = useState<string | undefined>(undefined)
  const [panoramaImageUrl, loading] = usePrefetchPano(data, currentGeoId)
  const isDisabledMapGeoChangeRef = useRef<boolean>(false)

  useEffect(() => {
    if (data.length) {
      setCenter([data[0].geometry.coordinates[0], data[0].geometry.coordinates[1]])
      setPoints(
        data.map((el) => ({
          id: el._id,
          longitude: el.geometry.coordinates[0],
          latitude: el.geometry.coordinates[1],
          onClick: (id: string) => {
            if (isDisabledMapGeoChangeRef.current) {
              return
            }

            setCurrentGeoId(id)
          }
        }))
      )
      setCurrentGeoId(data[0]._id)
    }
  }, [data])

  const onGeoIdChange = (id: string | undefined) => {
    setCurrentGeoId(id)
  }

  const setInitialGeoIdChange = (id: string | undefined) => {
    setInitialGeoId(id)
    isDisabledMapGeoChangeRef.current = !!id
  }

  const id = initialGeoId ?? currentGeoId
  const currentGeoDataIndex = data.findIndex((geoData) => geoData._id === id)
  const currentGeoData = data[currentGeoDataIndex]
  const stepGeoData = getStepGeoData(data, currentGeoDataIndex)
  const [windowSize, onWindowResize] = useState(initSize)

  return (
    <>
      {loading && (
        <ProgressIndicator variant='linear' type='indeterminate' className={style.progress} />
      )}
      {panoramaImageUrl && (
        <Panorama
          panoramaUrl={panoramaImageUrl}
          currentGeoData={currentGeoData}
          onGeoIdChange={onGeoIdChange}
          setInitialGeoIdChange={setInitialGeoIdChange}
          initialGeoId={id}
          stepGeoData={stepGeoData}
        />
      )}
      <FloatWindow
        initWidth={initSize.width}
        initHeight={initSize.height}
        onResize={onWindowResize}
        children={
          <Map
            style={windowSize}
            longitude={longitude}
            latitude={latitude}
            zoom={initZoom}
            points={points}
          />
        }
      />
    </>
  )
}
