import styles from '../index.module.less'
import { Text } from '@/components/ui/typography/text'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import {
  BaseReportFields,
  ElectricalStepCabinetAndMotorCleaning,
  ElectricalStepCabinetAndMotorCleaningFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

export interface CabinetAndMotorCleaningProps {
  compact: boolean
  step: ElectricalStepCabinetAndMotorCleaning
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function CabinetAndMotorCleaning({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: CabinetAndMotorCleaningProps) {
  return (
    <div>
      <StepForm<ElectricalStepCabinetAndMotorCleaning>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepCabinetAndMotorCleaning>
              compact={compact}
              title='Фото очищенного шкафа'
              fieldName={ElectricalStepCabinetAndMotorCleaningFields.cabinetCleaningPhoto}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepCabinetAndMotorCleaning>
              compact={compact}
              title='Фото очищенного двигателя'
              fieldName={ElectricalStepCabinetAndMotorCleaningFields.motorCleaningPhoto}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={ElectricalStepCabinetAndMotorCleaningFields.deviations}
          commentFieldName={ElectricalStepCabinetAndMotorCleaningFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
