import React, { DetailedHTMLProps, FC, HTMLAttributes, useEffect, useRef, useState } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import cn from 'classnames'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import './imageMagnifier.less'

// На основе https://github.com/samuelmeuli/react-magnifier

export const ImageMagnifierPropTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  magnifierRadius: PropTypes.number
}

export type ImageMagnifierProps = InferProps<typeof ImageMagnifierPropTypes> &
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    alt?: NonNullable<InferProps<typeof ImageMagnifierPropTypes>['alt']>
    magnifierRadius?: NonNullable<InferProps<typeof ImageMagnifierPropTypes>['magnifierRadius']>
  }

const zoomImgSrc = ''
const zoomFactor = 2

const mgBorderWidth = 2
const mgShowOverflow = true
const mgMouseOffsetX = 0
const mgMouseOffsetY = 0
const mgTouchOffsetX = 0
const mgTouchOffsetY = 0

export const ImageMagnifier: FC<ImageMagnifierProps> = ({
  className,
  src,
  alt = '',
  magnifierRadius = 75,
  ...props
}) => {
  const img = useRef<HTMLImageElement>(null)
  const [imgBounds, setImgBounds] = useState<DOMRect | null>(null)

  const [showZoom, setShowZoom] = useState(false)
  const [mgOffsetX, setMgOffsetX] = useState(0)
  const [mgOffsetY, setMgOffsetY] = useState(0)
  const [relX, setRelX] = useState(0)
  const [relY, setRelY] = useState(0)

  const mgWidth = magnifierRadius * 2
  const mgHeight = magnifierRadius * 2

  const calcImgBounds = () => {
    if (img.current) {
      setImgBounds(img.current.getBoundingClientRect())
    }
  }

  useEffect(() => {
    const calcImgBoundsDebounced = debounce(calcImgBounds, 200)

    const onMouseEnter = () => {
      calcImgBounds()
    }

    const onMouseMove = (e: MouseEvent) => {
      setMagnifierPositionMouse(e)
    }

    const setMagnifierPositionMouse = (e: MouseEvent) => {
      if (imgBounds) {
        const target = e.target as HTMLElement
        const relX = (e.clientX - imgBounds.left) / target.clientWidth
        const relY = (e.clientY - imgBounds.top) / target.clientHeight

        setMgOffsetX(mgMouseOffsetX)
        setMgOffsetY(mgMouseOffsetY)
        setRelX(relX)
        setRelY(relY)
      }
    }

    const setMagnifierPositionTouch = (e: TouchEvent) => {
      if (imgBounds) {
        const target = e.target as HTMLElement
        const relX = (e.targetTouches[0].clientX - imgBounds.left) / target.clientWidth
        const relY = (e.targetTouches[0].clientY - imgBounds.top) / target.clientHeight

        // Only show magnifying glass if touch is inside image
        if (relX >= 0 && relY >= 0 && relX <= 1 && relY <= 1) {
          setMgOffsetX(mgTouchOffsetX)
          setMgOffsetY(mgTouchOffsetY)
          setRelX(relX)
          setRelY(relY)
        }
      }
    }

    const onTouchStart = (e: TouchEvent) => {
      e.preventDefault() // Prevent mouse event from being fired

      calcImgBounds()
      setMagnifierPositionTouch(e)
      setShowZoom(true)
    }

    const onTouchMove = (e: TouchEvent) => {
      e.preventDefault() // Disable scroll on touch

      setMagnifierPositionTouch(e)
    }

    const onTouchMoveThrottled = throttle(onTouchMove, 20, {
      trailing: false
    })

    const onMouseMoveThrottled = throttle(onMouseMove, 20, {
      trailing: false
    })

    const onToggleZoom = () => {
      setShowZoom(() => !showZoom)
    }

    const imgEl = img.current
    if (imgEl) {
      imgEl.addEventListener('mouseenter', onMouseEnter, { passive: false })
      imgEl.addEventListener('mousemove', onMouseMoveThrottled, {
        passive: false
      })
      imgEl.addEventListener('touchstart', onTouchStart, { passive: false })
      imgEl.addEventListener('touchmove', onTouchMoveThrottled, {
        passive: false
      })
      imgEl.addEventListener('click', onToggleZoom, { passive: false })
    }

    window.addEventListener('resize', calcImgBoundsDebounced)
    window.addEventListener('scroll', calcImgBoundsDebounced, true)

    return () => {
      if (imgEl) {
        imgEl.removeEventListener('mouseenter', onMouseEnter)
        imgEl.removeEventListener('mousemove', onMouseMoveThrottled)
        imgEl.removeEventListener('touchstart', onTouchStart)
        imgEl.removeEventListener('touchmove', onTouchMoveThrottled)
        imgEl.removeEventListener('click', onToggleZoom)
      }

      window.removeEventListener('resize', calcImgBoundsDebounced)
      window.removeEventListener('scroll', calcImgBoundsDebounced, true)
    }
  }, [imgBounds, showZoom])

  return (
    <div
      {...props}
      data-testid='image_magnifier'
      className={cn('smwb-image-magnifier', className)}
      style={{
        overflow: mgShowOverflow ? 'visible' : 'hidden'
      }}
    >
      <img
        className='smwb-image-magnifier__image'
        src={src ?? undefined}
        alt={alt}
        width='100%'
        height='100%'
        ref={img}
      />

      {showZoom && imgBounds && (
        <div
          className='smwb-image-magnifier__magnifier'
          style={{
            width: mgWidth,
            height: mgHeight,
            left: `calc(${relX * 100}% - ${mgWidth / 2}px + ${mgOffsetX}px - ${mgBorderWidth}px)`,
            top: `calc(${relY * 100}% - ${mgHeight / 2}px + ${mgOffsetY}px - ${mgBorderWidth}px)`,
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            backgroundImage: `url("${zoomImgSrc || src}")`,
            backgroundPosition: `calc(${relX * 100}% + ${mgWidth / 2}px - ${
              relX * mgWidth
            }px) calc(${relY * 100}% + ${mgHeight / 2}px - ${relY * mgWidth}px)`,
            backgroundSize: `${zoomFactor * imgBounds.width}% ${zoomFactor * imgBounds.height}%`,
            borderWidth: mgBorderWidth
          }}
        >
          <div
            style={{
              marginTop: mgHeight + 6,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <div className='smwb-image-magnifier__close' onClick={() => setShowZoom(false)}>
              x
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ImageMagnifier
