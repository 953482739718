import { GeoJsonWithRelations } from '@api/generated'

export interface GeoProperties {
  pitch: number
  roll: number
  yaw: number
  panoId: string
}

export interface GeoGeometry {
  coordinates: number[]
}

export interface GeoData extends GeoJsonWithRelations {
  _id: string
  properties: GeoProperties
  geometry: GeoGeometry
}

export type Point = [x: number, y: number, z: number]

export type Duplet = [Point, Point]

export enum StepStatus {
  None,
  Start,
  Done
}
