import style from './pageTitle.module.less'
import { Title } from '@/components/ui/typography/title'
import { Icon } from '@/components/ui/base/icon/icon'

interface PageTitleProps {
  title: string
  onBack?: () => void
}

export function PageTitle({ title, onBack }: PageTitleProps) {
  return (
    <div className={style.wrapper}>
      {onBack && <Icon icon='arrow_back' className={style.back} onClick={onBack} />}
      <Title level={1}>{title}</Title>
    </div>
  )
}
