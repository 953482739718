import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { jwtDecode } from 'jwt-decode'
import { COOKIES } from '@api/utils/const'
import { Cookies } from 'react-cookie'

const cookies = new Cookies(null, { path: '/' })

export interface UserMissions {
  mission: string
  projects: string[]
}

export interface UserStateLoggedIn {
  access: string
  id: string
  info: {
    firstName: string
    lastName: string
    profession: string
  }
  missions: UserMissions[]
  username: string
  loggedIn: boolean
}

export interface UserStateLoggedOut {
  access: string
  missions: UserMissions[]
  loggedIn: boolean
}

const initialState: UserStateLoggedIn | UserStateLoggedOut = {
  access: '',
  missions: [],
  loggedIn: false
}

export function isUserLoggedIn(
  value: UserStateLoggedIn | UserStateLoggedOut
): value is UserStateLoggedIn {
  return value.loggedIn
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedOut: () => {
      return initialState
    },
    userLoggedIn: (_state, action: PayloadAction<UserStateLoggedIn>) => {
      return action.payload
    }
  },
  selectors: {
    userLoggedIn: (sliceState) => sliceState.loggedIn,
    userInfo: (sliceState) => sliceState,
    userMissions: (sliceState) => sliceState.missions
  }
})
export const selectors = userSlice.selectors
export const { userLoggedOut, userLoggedIn } = userSlice.actions
export default userSlice

export function setLoggedIn(dispatch: Dispatch, accessToken?: string, refreshToken?: string) {
  if (!accessToken) {
    console.error('accessToken is empty')
    return
  }
  if (!refreshToken) {
    console.error('refreshToken is empty')
    return
  }
  const token = jwtDecode(accessToken)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-non-null-assertion
  cookies.set(COOKIES.JWT_TOKEN, accessToken, { expires: new Date(token.exp! * 1000) })
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const rToken = jwtDecode(refreshToken)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  cookies.set(COOKIES.REFRESH_TOKEN, refreshToken, { expires: new Date(rToken.exp! * 1000) })
  dispatch(userLoggedIn({ ...(token as UserStateLoggedIn), loggedIn: true }))
}

export function setLogout() {
  cookies.remove(COOKIES.JWT_TOKEN)
  cookies.remove(COOKIES.REFRESH_TOKEN)
}
