import styles from '../index.module.less'
import { Text } from '@/components/ui/typography/text'
import { Card } from '@modules/missions/keaz/step/components/card'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import {
  BaseReportFields,
  MechanicalStepSafetyCheck,
  MechanicalStepSafetyCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

import omit from 'lodash/omit'

export interface SafetyCheckProps {
  compact: boolean
  step: MechanicalStepSafetyCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function SafetyCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: SafetyCheckProps) {
  return (
    <div>
      <StepForm<MechanicalStepSafetyCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepSafetyCheckFields.frontClampingUnitDoorChecked}
              label='Передняя дверь узла смыкания проверена'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepSafetyCheckFields.rearClampingUnitDoorChecked}
              label='Задняя дверь узла смыкания проверена'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepSafetyCheckFields.nozzleGuardChecked}
              label='Защитный экран сопла проверен'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepSafetyCheckFields.deviations}
          commentFieldName={MechanicalStepSafetyCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
