import React from 'react'
import cn from 'classnames'
import style from './logo.module.less'

import { ReactComponent as LogoHorizontal } from './assets/images/logo_horizontal.svg'
import { ReactComponent as LogoVertical } from './assets/images/logo_vertical.svg'

const svgLogoMap = {
  horizontal: LogoHorizontal,
  vertical: LogoVertical
}

type LogoType = keyof typeof svgLogoMap

export interface LogoProps extends React.SVGProps<SVGSVGElement> {
  type?: LogoType
  color?: 'orange' | 'white'
}

export const Logo = ({ className, type = 'horizontal', color = 'orange', ...props }: LogoProps) => {
  const SvgLogo = svgLogoMap[type]
  return (
    <SvgLogo {...props} className={cn(className, style.logo, color && style[`logo-${color}`])} />
  )
}
