import * as math from 'mathjs'
import { Duplet, Point } from '@modules/panorama/types'

// WGS 84 consts
const Ra = 6378137 // Экваториальный радиус Земли (в метрах)
// const Re = 6371000 // Средний радиус Земли (в метрах)
const f = 1 / 298.257223563 // Геометрическое сжатие эллипсоида Земли
//const f = 1 / 298.257222101; // Геометрическое сжатие эллипсоида Земли GRS80
const e2 = f * (2 - f) // Первый эксцентриситет

// Получить дуплет (координаты кадра в картезианском представлении и направление точки)
export function getDuplet(lat: number, lon: number, alt: number, azm: number, w: Point): Duplet {
  const latR = toRad(lat)
  const lonR = toRad(lon)

  w = normalize(threeJSToOpenGL(w))
  const a = (-1 * toDeg(Math.atan2(w[0], w[2])) + azm) % 360
  const b = toDeg(Math.asin(w[1]))

  const aR = toRad(a)
  const bR = toRad(b)

  // Координаты кадра в картезианской системе
  const P = WGS84ToCartesian(lat, lon, alt)

  // Матрица ортогональности
  const Morth = [
    [-1 * Math.sin(lonR), -1 * Math.sin(latR) * Math.cos(lonR), Math.cos(latR) * Math.cos(lonR)],
    [Math.cos(lonR), -1 * Math.sin(latR) * Math.sin(lonR), Math.cos(latR) * Math.sin(lonR)],
    [0, Math.cos(latR), Math.sin(latR)]
  ]
  // Вектор направления
  const A = [Math.sin(aR) * Math.cos(bR), Math.cos(aR) * Math.cos(bR), Math.sin(bR)]

  // Вектор направления точки в пространстве
  const D = normalize(math.multiply(Morth, A) as Point)

  return [P, D]
}

// Получить географические координаты точки клика
export function clickToCoords(duplets: Duplet[]) {
  // Усреднение точек
  let Aa = math.matrix([
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0]
  ])
  let Ba = math.matrix([0, 0, 0])

  for (const duplet of duplets) {
    const P = duplet[0]
    const D = duplet[1]
    const Da = normalize(D)
    const kron = math.kron(Da, Da) as unknown as number[][]
    const kronM = [
      [kron[0][0], kron[0][1], kron[0][2]],
      [kron[0][3], kron[0][4], kron[0][5]],
      [kron[0][6], kron[0][7], kron[0][8]]
    ]
    const Ma = math.subtract(math.identity(3, 3), kronM)

    Aa = math.add(Aa, Ma) as math.Matrix
    Ba = math.add(Ba, math.multiply(Ma, P)) as math.Matrix
  }

  const Pa = math.multiply(math.inv(Aa), Ba)

  return cartesianToWGS84(Pa)
}

export function threeJSToOpenGL(P: Point): Point {
  return [P[2], P[1], -P[0]]
}

export function WGS84ToCartesian(lat: number, lon: number, alt: number): Point {
  const latR = toRad(lat)
  const lonR = toRad(lon)

  const N = Ra / Math.pow(1 - e2 * Math.sin(latR) ** 2, 0.5)

  return [
    (N + alt) * Math.cos(latR) * Math.cos(lonR),
    (N + alt) * Math.cos(latR) * Math.sin(lonR),
    (N * (1 - e2) + alt) * Math.sin(latR)
  ]
}

export function cartesianToWGS84(Pa: math.Matrix) {
  const pa = Pa.valueOf() as number[]
  // Географическая широта точки
  const ACC = 9.000000000000001e-10 // Точность

  function calculateLatitude(curLat: number): number {
    const curLatR = toRad(curLat)
    const N = Ra / Math.pow(1 - e2 * Math.sin(curLatR) ** 2, 0.5)
    const p = Math.pow(pa[0] ** 2 + pa[1] ** 2, 0.5)
    const latI = Math.atan((pa[2] + N * e2 * Math.sin(curLatR)) / p)
    const latAsDeg = toDeg(latI)

    if (latAsDeg - curLat > ACC) {
      return calculateLatitude(latAsDeg)
    } else {
      return latAsDeg
    }
  }

  const curLat = calculateLatitude(0)

  // Географическая долгота точки
  const curLon = toDeg(Math.atan(pa[1] / pa[0]))

  // Высота над уровнем моря
  const curLatR = toRad(curLat)
  const N = Ra / Math.pow(1 - e2 * Math.sin(curLatR) ** 2, 0.5)
  const p = Math.pow(pa[0] ** 2 + pa[1] ** 2, 0.5)

  const alt =
    p * Math.cos(curLatR) + pa[2] * Math.sin(curLatR) - N * (1 - e2 * Math.sin(curLatR) ** 2)

  return [curLat, curLon, alt]
}

export function normalize(w: Point): Point {
  const coef = 1 / Math.pow(w[0] ** 2 + w[1] ** 2 + w[2] ** 2, 0.5)
  return [coef * w[0], coef * w[1], coef * w[2]]
}

export function toRad(angle: number): number {
  return (angle * Math.PI) / 180
}

export function toDeg(angle: number): number {
  return (angle * 180) / Math.PI
}
