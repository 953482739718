import { TableCell, TableRow } from '@smwb/summer-ui'
import { MouseEvent, useMemo } from 'react'
import { BaseReportFields, GroupStatus, ReportSubtype } from '@modules/missions/keaz/types'
import { AgentumReportWithRelations } from '@api/generated'
import { useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'
import { PROJECT } from '@modules/missions/keaz/const'
import { skipToken } from '@reduxjs/toolkit/query'
import { getSummaryStepsData } from '@modules/missions/keaz/utils/steps'

interface TasksTableProps {
  task: AgentumReportWithRelations
  className?: string
  onClick: (e: MouseEvent<HTMLDivElement>) => void
}

const getStatusColor = (status: GroupStatus) => {
  let color = 'black'

  switch (status) {
    case GroupStatus.signed:
      color = 'gray2'
      break
    case GroupStatus.default:
      color = 'gray5'
      break
    case GroupStatus.approved:
      color = 'success'
      break
    case GroupStatus.inProgress:
      color = 'warning'
      break
    case GroupStatus.rejected:
      color = 'orange'
      break
  }

  return `var(--agm-color-${color})`
}

export function TasksTableRow({ task, className, onClick }: TasksTableProps) {
  const { id } = useParams()
  const { data: steps = [] } = apiCall.useAgentumReportControllerFindQuery(
    id
      ? [
          id,
          PROJECT,
          {
            where: {
              [BaseReportFields.subtype]: ReportSubtype.step,
              [BaseReportFields.taskId]: task[BaseReportFields.origin]
            }
          }
        ]
      : skipToken
  )

  const summaryStepsData = useMemo(() => getSummaryStepsData(steps), [steps])

  return (
    <TableRow className={className} onClick={onClick}>
      <TableCell>{task[BaseReportFields.name]}</TableCell>
      <TableCell>{task[BaseReportFields.userId]}</TableCell>
      <TableCell>{task[BaseReportFields.equipment]}</TableCell>

      <TableCell style={{ color: getStatusColor(summaryStepsData.status) }}>
        {steps.length ? summaryStepsData.status : ''}
      </TableCell>

      <TableCell>{`${summaryStepsData.completedSteps}/${summaryStepsData.totalSteps}`}</TableCell>

      <TableCell>{`${summaryStepsData.approvedSteps}/${summaryStepsData.totalSteps}`}</TableCell>
    </TableRow>
  )
}
