import style from './tasksTable.module.less'
import { Table, TableBody, TableHead, TableHeadCell, TableRow } from '@smwb/summer-ui'
import { useMemo, useState } from 'react'
import { BaseReportFields } from '@modules/missions/keaz/types'
import { AgentumReportWithRelations } from '@api/generated'
import { TasksTableRow } from '@modules/missions/keaz/components/tasksTableRow'

interface TasksTableProps {
  tasks: AgentumReportWithRelations[]
  isLoading: boolean
  className?: string
  handleRowClick: (task: AgentumReportWithRelations) => void
}

const headers = [
  'Номер задачи',
  'Ответственный',
  // 'Тип задачи'
  'Наименование оборудования',
  // 'Плановые даты выполнения',
  // 'Исполнитель',
  'Статус',
  'Выполнено шагов',
  'Проверено шагов'
]

export function TasksTable({ tasks, isLoading, className, handleRowClick }: TasksTableProps) {
  const [limit, setLimit] = useState<number>(10)
  const [page, setPage] = useState<number>(1)

  const onNextPage = () => {
    setPage(page + 1)
  }

  const onPrevPage = () => {
    if (page === 1) return
    setPage(page - 1)
  }

  const onLimitChange = (num: number) => {
    setPage(1)
    setLimit(num)
  }

  const filteredTasks = useMemo(() => {
    const start = (page - 1) * limit
    const end = start + limit

    return tasks.slice(start, end)
  }, [limit, page, tasks])

  return (
    <Table
      isLoading={isLoading}
      paginationSettings={{
        limit,
        page,
        totalCount: tasks.length,
        onNext: onNextPage,
        onPrev: onPrevPage,
        limitOptions: [10, 20, 30],
        onLimitChange
      }}
      className={className}
    >
      <TableHead>
        <TableRow>
          {headers.map((h) => (
            <TableHeadCell key={h} cellAlign='left'>
              {h}
            </TableHeadCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {filteredTasks.map((task) => (
          <TasksTableRow
            key={task[BaseReportFields.id]}
            task={task}
            className={style.tableBodyRow}
            onClick={() => handleRowClick(task)}
          />
        ))}
      </TableBody>
    </Table>
  )
}
