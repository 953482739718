import styles from './chip.module.less'
import cn from 'classnames'
import { ReactNode } from 'react'
import { Text } from '@/components/ui/typography/text'

export type ChipVariant = 'default' | 'gray' | 'gray2' | 'success' | 'warning' | 'orange'

export interface ChipProps {
  className?: string
  children: ReactNode
  variant?: ChipVariant
}

export function Chip({ children, variant = 'default', className }: ChipProps) {
  return (
    <Text className={cn(className, styles.chip, styles[`chip_${variant}`])} tag='div' size='l'>
      {children}
    </Text>
  )
}
