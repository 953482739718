import { useState } from 'react'
import { MenuItem, Button } from '@smwb/summer-ui'
import { Canvas } from '@react-three/fiber'
import { Item, Element } from '@modules/panorama/elements/types'
import { Scene } from '@modules/panorama/scene'
import { Compass } from '@modules/panorama/compass/compass'
import { GeoData, Point, StepStatus } from '@modules/panorama/types'
import s from './panorama.module.less'
import { getCoords } from '@modules/panorama/utils/getCoords'
import { addCoords } from '@redux/slices/panoramaSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'

const items: Item[] = [
  { id: Element.Point, label: 'Точка' },
  { id: Element.Line, label: 'Линия' },
  { id: Element.Polygon, label: 'Многоугольник' }
]

const stepButtonTexts = ['Вычислить координаты', 'Далее', 'Далее', 'Вывести результат']

export interface PanoramaProps {
  panoramaUrl: string
  currentGeoData: GeoData
  onGeoIdChange: (id: string | undefined) => void
  setInitialGeoIdChange: (id: string | undefined) => void
  stepGeoData: GeoData[]
  initialGeoId: string | undefined
}

const procFramesCount = 3

export function Panorama({
  panoramaUrl,
  currentGeoData,
  onGeoIdChange,
  stepGeoData,
  initialGeoId,
  setInitialGeoIdChange
}: PanoramaProps) {
  const [selectedItemId, setSelectedItemId] = useState<Element>()
  const [step, setStep] = useState<number>(0)
  const [stepStatus, setStepStatus] = useState<StepStatus>(StepStatus.None)
  const [pointObjects, setPointObjects] = useState<Point[][]>([])
  const coords = useAppSelector((state) => state.panorama.coords[currentGeoData._id] ?? [])
  const dispatch = useAppDispatch()

  const onMenuItemClick = (itemId: Element) => () => {
    setSelectedItemId(itemId === selectedItemId ? undefined : itemId)
  }

  const onStepButtonClick = () => {
    setStep(step === 3 ? 0 : step + 1)
    setStepStatus(step === 3 ? StepStatus.None : StepStatus.Start)

    if (step === 0) {
      setInitialGeoIdChange(initialGeoId)
      dispatch(addCoords({ id: currentGeoData._id, coords: [] }))
    } else if (step === 1) {
      onGeoIdChange(stepGeoData[0]._id)
    } else if (step === 2) {
      onGeoIdChange(stepGeoData[1]._id)
    } else if (step === 3) {
      onGeoIdChange(currentGeoData._id)
      setInitialGeoIdChange(undefined)

      if (pointObjects.at(-1)?.length !== procFramesCount) {
        return
      }

      const coords = getCoords(pointObjects, currentGeoData)

      dispatch(addCoords({ id: currentGeoData._id, coords }))
      setPointObjects([])
    }
  }

  const onStepDone = (points: Point[]) => {
    if (step === 0) {
      return
    }

    const newPoints = pointObjects.slice()

    points.forEach((point, index) => {
      if (newPoints[index] === undefined) {
        newPoints[index] = [point]
      } else {
        newPoints[index].push(point)
      }
    })

    setPointObjects(newPoints)
    setStepStatus(StepStatus.Done)
  }

  const onStepStart = () => {
    if (step !== 0) {
      setStepStatus(StepStatus.Start)
    }
  }

  const onStepRemovePoint = () => {
    const newPoints = pointObjects.map((points) => points.slice(0, -1))

    setPointObjects(newPoints)
  }

  const isStepStatusStart = stepStatus === StepStatus.Start
  const isStepStatusDone = stepStatus === StepStatus.Done
  const selectedObjectId = stepStatus === StepStatus.Done ? undefined : selectedItemId
  const isMenuObjectsDisabled =
    selectedItemId !== undefined && (isStepStatusDone || (isStepStatusStart && step > 1))

  return (
    <div className={s.root}>
      <div className={s.inner}>
        <Canvas
          dpr={window.devicePixelRatio}
          camera={{
            fov: 75,
            aspect: window.innerWidth / window.innerHeight,
            near: 1,
            far: 1100
          }}
          flat
        >
          <Compass />
          <Scene
            selectedObjectId={selectedObjectId}
            panoramaUrl={panoramaUrl}
            geoProperties={currentGeoData.properties}
            stepStatus={stepStatus}
            onStepDone={onStepDone}
            onStepStart={onStepStart}
            onStepRemovePoint={onStepRemovePoint}
          />
        </Canvas>
        <div className={s.sideBar}>
          <ul className={s.menu}>
            {items.map(({ id, label }) => (
              <MenuItem
                key={id}
                onClick={onMenuItemClick(id)}
                selected={id === selectedItemId}
                disabled={isMenuObjectsDisabled}
              >
                {label}
              </MenuItem>
            ))}
          </ul>
          {stepGeoData.length === 2 && (
            <div className={s.paper}>
              <Button
                className={s.button}
                onClick={onStepButtonClick}
                disabled={isStepStatusStart}
                fullWidth
              >
                {stepButtonTexts[step]}
              </Button>
              {coords.length > 0 && (
                <div className={s.coords}>
                  <pre>{JSON.stringify(coords, null, 2)}</pre>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
