import { createSlice, combineSlices } from '@reduxjs/toolkit'
import { apiCall } from '@api/resources/apiCall'
import userSlice from '@redux/slices/userSlice'
import panoramaSlice from '@redux/slices/panoramaSlice'

export const emptySlice = createSlice({
  name: 'emptySlice',
  initialState: {},
  reducers: {
    noop: () => {
      // empty
    }
  }
})

export const rootReducer = combineSlices(
  { [apiCall.reducerPath]: apiCall.reducer },
  emptySlice,
  userSlice,
  panoramaSlice
)
