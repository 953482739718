import React from 'react'
import { AppBarProps as SmwbAppBarProps } from '@smwb/summer-ui/dist/components/navigation/appBar'
import { AppBar as SmwbAppbar } from '@smwb/summer-ui'
import style from './appBar.module.less'
import cn from 'classnames'

export interface AppBarProps extends SmwbAppBarProps {
  hasShadow?: boolean
}

export const AppBar = ({ hasShadow = false, className, ...props }: AppBarProps) => {
  return (
    <SmwbAppbar
      {...props}
      className={cn(className, style.appbar, hasShadow && style['appbar-shadow'])}
    />
  )
}
