import styles from '../index.module.less'
import { Text } from '@/components/ui/typography/text'
import { Card } from '@modules/missions/keaz/step/components/card'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import {
  BaseReportFields,
  MechanicalStepFasteningCheck,
  MechanicalStepFasteningCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'
import { ErrorMessage } from '@modules/missions/keaz/step/components/contentBySteps/components/errorMessage'

import omit from 'lodash/omit'

export interface FasteningCheckProps {
  compact: boolean
  step: MechanicalStepFasteningCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function FasteningCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: FasteningCheckProps) {
  return (
    <div>
      <StepForm<MechanicalStepFasteningCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepFasteningCheckFields.tailstockFormEngagementFastening}
              label='Крепеж зацепа хвостовика формы'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={MechanicalStepFasteningCheckFields.tailstockFormEngagementFastening}
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepFasteningCheckFields.oilScreensFastening}
              label='Крепеж масляных экранов'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={MechanicalStepFasteningCheckFields.oilScreensFastening}
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepFasteningCheckFields.deviations}
          commentFieldName={MechanicalStepFasteningCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
