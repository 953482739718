import styles from '../index.module.less'
import { Text } from '@/components/ui/typography/text'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import {
  BaseReportFields,
  ElectricalStepInjectionMoldingMachineMovementsCheck,
  ElectricalStepInjectionMoldingMachineMovementsCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

import { ErrorMessage } from '@modules/missions/keaz/step/components/contentBySteps/components/errorMessage'

export interface InjectionMoldingMachineMovementsCheckProps {
  compact: boolean
  step: ElectricalStepInjectionMoldingMachineMovementsCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function InjectionMoldingMachineMovementsCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: InjectionMoldingMachineMovementsCheckProps) {
  return (
    <div>
      <StepForm<ElectricalStepInjectionMoldingMachineMovementsCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={
                ElectricalStepInjectionMoldingMachineMovementsCheckFields.movablePlateMovementCheck
              }
              label='Проверка хода подвижной плиты'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={
                ElectricalStepInjectionMoldingMachineMovementsCheckFields.movablePlateMovementCheck
              }
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepInjectionMoldingMachineMovementsCheck>
              compact={compact}
              title='Фото дисплея с результатом проверки хода подвижной плиты'
              fieldName={
                ElectricalStepInjectionMoldingMachineMovementsCheckFields.movablePlateMovementDisplayPhoto
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={
                ElectricalStepInjectionMoldingMachineMovementsCheckFields.injectionUnitMovementCheck
              }
              label='Проверка хода узла впрыска'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={
                ElectricalStepInjectionMoldingMachineMovementsCheckFields.injectionUnitMovementCheck
              }
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepInjectionMoldingMachineMovementsCheck>
              compact={compact}
              title='Фото дисплея с результатом проверки хода узла впрыска'
              fieldName={
                ElectricalStepInjectionMoldingMachineMovementsCheckFields.injectionUnitMovementDisplayPhoto
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={ElectricalStepInjectionMoldingMachineMovementsCheckFields.ejectorMovementCheck}
              label='Проверка хода толкателей'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={
                ElectricalStepInjectionMoldingMachineMovementsCheckFields.ejectorMovementCheck
              }
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepInjectionMoldingMachineMovementsCheck>
              compact={compact}
              title='Фото дисплея с результатом проверки хода толкателей'
              fieldName={
                ElectricalStepInjectionMoldingMachineMovementsCheckFields.ejectorMovementDisplayPhoto
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={ElectricalStepInjectionMoldingMachineMovementsCheckFields.screwMovementCheck}
              label='Проверка хода шнека'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={
                ElectricalStepInjectionMoldingMachineMovementsCheckFields.screwMovementCheck
              }
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepInjectionMoldingMachineMovementsCheck>
              compact={compact}
              title='Фото дисплея с результатом проверки хода шнека'
              fieldName={
                ElectricalStepInjectionMoldingMachineMovementsCheckFields.screwMovementDisplayPhoto
              }
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={ElectricalStepInjectionMoldingMachineMovementsCheckFields.deviations}
          commentFieldName={
            ElectricalStepInjectionMoldingMachineMovementsCheckFields.deviationsComment
          }
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
