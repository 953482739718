import CompassSVG from '@modules/common/images/compass.svg'
import React, { useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { Html } from '@react-three/drei'
import style from './compass.module.less'

export const Compass: React.FC = () => {
  const { camera } = useThree()
  const [rotation, setRotation] = useState({ yaw: 0, pitch: 0 })

  useFrame(() => {
    const camDirection = new THREE.Vector3()
    camera.getWorldDirection(camDirection)
    const yaw = Math.atan2(-camDirection.x, -camDirection.z)
    const pitch = Math.asin(camDirection.y)
    setRotation({ yaw, pitch })
  })

  return (
    <Html fullscreen style={{ pointerEvents: 'none' }}>
      <div
        className={style.layout}
        style={{
          transform: `rotateZ(${rotation.yaw}rad) rotateX(${rotation.pitch}rad)`
        }}
      >
        <img src={CompassSVG} alt='Compass' />
      </div>
    </Html>
  )
}
