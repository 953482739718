import styles from '../index.module.less'
import { Text } from '@/components/ui/typography/text'
import { Card } from '@modules/missions/keaz/step/components/card'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import {
  BaseReportFields,
  ElectricalStepNoiseAndVibrationCheck,
  ElectricalStepNoiseAndVibrationCheckFields,
  Step
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

export interface NoiseAndVibrationCheckProps {
  compact: boolean
  step: Step
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function NoiseAndVibrationCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: NoiseAndVibrationCheckProps) {
  return (
    <div>
      <StepForm<ElectricalStepNoiseAndVibrationCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={ElectricalStepNoiseAndVibrationCheckFields.abnormalNoiseCheck}
              label='Проверка на нестандартные шумы в двигателе'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={ElectricalStepNoiseAndVibrationCheckFields.vibrationCheck}
              label='Проверка на наличие вибрации двигателя'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={ElectricalStepNoiseAndVibrationCheckFields.deviations}
          commentFieldName={ElectricalStepNoiseAndVibrationCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
