import baseStyle from '../task/index.module.less'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'
import { BaseReportFields, Groups, ReportSubtype } from '@modules/missions/keaz/types'
import { skipToken } from '@reduxjs/toolkit/query'
import { PageTitle } from '@modules/missions/keaz/components/pageTitle'
import { StepsTable } from '@modules/missions/keaz/group/components/stepsTable'
import { Urls } from '@/urls'
import { PROJECT } from '@modules/missions/keaz/const'

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function Index() {
  const { id, taskOrigin, groupIndex } = useParams()

  const { data: task } = apiCall.useAgentumReportControllerFindByIdQuery(
    id ? [id, PROJECT, taskOrigin || ''] : skipToken
  )

  const group = Object.values(Groups)[parseInt(groupIndex || '0')]
  const { data: steps = [], isLoading } = apiCall.useAgentumReportControllerFindQuery(
    id
      ? [
          id,
          PROJECT,
          {
            where: {
              [BaseReportFields.subtype]: ReportSubtype.step,
              [BaseReportFields.taskId]: taskOrigin,
              [BaseReportFields.group]: group
            },
            order: ['stepNumber ASC']
          }
        ]
      : skipToken
  )

  const navigate = useNavigate()

  if (isLoading) {
    return <div>'loading'</div>
  }

  if (!task) {
    return <div>Задача не найдена</div>
  }

  return (
    <>
      <PageTitle
        title={`Задача №${String(task[BaseReportFields.name])}. ${capitalizeFirstLetter(group)}`}
        onBack={() =>
          navigate(
            generatePath(Urls.KeazTask, { id: id || null, taskOrigin: taskOrigin || null }),
            {
              replace: true
            }
          )
        }
      />

      <div className={baseStyle.section}>
        <StepsTable
          steps={steps}
          isLoading={isLoading}
          handleRowButtonClick={(step) =>
            navigate(
              generatePath(Urls.KeazStep, {
                id: id || null,
                taskOrigin: taskOrigin || null,
                groupIndex: groupIndex || null,
                stepOrigin: step[BaseReportFields.origin] || null
              })
            )
          }
        />
      </div>
    </>
  )
}
