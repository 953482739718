import { CSSProperties, useEffect, useRef, useState } from 'react'
import maplibregl from 'maplibre-gl'
import isEqual from 'lodash/isEqual'
import { config } from '@/settings'
import 'maplibre-gl/dist/maplibre-gl.css'

export interface Point {
  id: string
  longitude: number
  latitude: number
  onClick?: (id: string, longitude: number, latitude: number) => void
}

export interface MapProps {
  style?: Pick<CSSProperties, 'height' | 'width'>
  className?: string
  longitude?: number
  latitude?: number
  zoom?: number
  points?: Point[]
}

export function Map({
  className,
  style,
  longitude = 40,
  latitude = 40,
  zoom = 9,
  points = []
}: MapProps) {
  const map = useRef<maplibregl.Map | null>(null)
  const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null)

  const [lng] = useState(longitude)
  const [lat] = useState(latitude)
  const [initZoom] = useState(zoom)

  useEffect(() => {
    if (map.current !== null) {
      map.current?.resize()
    }
  }, [style])

  useEffect(() => {
    if (map.current == null) {
      return
    }
    const center = map.current.getCenter()
    if (!isEqual([longitude, latitude], [center.lng, center.lat])) {
      map.current.setCenter([longitude, latitude])
    }
  }, [latitude, longitude])

  useEffect(() => {
    if (map.current == null) {
      return
    }
    if (zoom !== map.current?.getZoom()) {
      map.current?.setZoom(zoom)
    }
  }, [zoom])

  useEffect(() => {
    if (map.current === null) {
      return
    }
    points.forEach(function (point) {
      const marker = new maplibregl.Marker()
        .setLngLat([point.longitude, point.latitude])
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .addTo(map.current!)
      if (point.onClick) {
        marker
          .getElement()
          .addEventListener('mousedown', () =>
            point.onClick?.(point.id, point.longitude, point.latitude)
          )
      }
    })
  }, [points])

  useEffect(() => {
    if (map.current !== null || mapContainer === null) {
      return // initialize map only once
    }
    const mapInstance = new maplibregl.Map({
      container: mapContainer,
      style: config.map.style,
      center: [lng, lat],
      zoom: initZoom,
      attributionControl: false
    })

    map.current = mapInstance

    mapInstance.on('load', () => {
      // TODO это надо вынести, когда будет более понятна общая логика работы
      points.forEach(function (point) {
        const marker = new maplibregl.Marker()
          .setLngLat([point.longitude, point.latitude])
          .addTo(mapInstance)
        if (point.onClick) {
          marker
            .getElement()
            .addEventListener('mousedown', () =>
              point.onClick?.(point.id, point.longitude, point.latitude)
            )
        }
      })
    })

    return () => {
      console.log('remove!')
      map.current?.remove()
      map.current = null
    }
    // Нам надо инициализировать карту только один раз
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapContainer])
  return <div className={className} style={style} ref={(ref) => setMapContainer(ref)} />
}
