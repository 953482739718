import { Button, Column, Grid, Row, Message } from '@smwb/summer-ui'
import { Form } from 'react-final-form'
import { TextField, Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import { FormGroup } from '@modules/common/formGroup/FormGroup'
import Logo from '@modules/common/images/logo-lage-orange.png'
import style from './login.module.less'
import { apiCall } from '@api/resources/apiCall'
import * as yup from 'yup'
import { UsersControllerLoginRequest } from '@/@api/generated'
import { yupValidate } from '@utils/yupValidate'
import { getErrorMessage } from '@api/utils/errorHandler'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setLoggedIn } from '@redux/slices/userSlice'
import { useNavigate } from 'react-router-dom'
import { Urls } from '@/urls'

const schema = yup.object().shape({
  username: yup.string().required('Username обязательное поле'),
  password: yup
    .string()
    .min(6, 'Пароль должен быть больше 6 символов')
    .required('Password обязательное поле')
})

const validate = yupValidate(schema)

export function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [login, { data, isLoading, error }] = apiCall.useUsersControllerLoginMutation()
  const onSubmit = (values: UsersControllerLoginRequest) => {
    void login([{ username: values.username, password: values.password }])
  }

  useEffect(() => {
    if (!isLoading && !!data && !error) {
      setLoggedIn(dispatch, data.accessToken, data.refreshToken)
      navigate(Urls.Missions.toString())
    }
  }, [dispatch, isLoading, data, error, navigate])

  return (
    <div className={style.background}>
      <Form
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form>
            <Grid>
              <Row justifyContent='center'>
                <Column md={4}>
                  <Row justifyContent='center'>
                    <Column>
                      <img className={style.logo} src={Logo} />
                    </Column>
                  </Row>
                  <FormGroup>
                    <TextField name='username' label='Логин' fullWidth />
                  </FormGroup>
                  <FormGroup>
                    <TextField name='password' label='Пароль' type='password' fullWidth />
                  </FormGroup>
                  <FormGroup>
                    <Checkbox name='memorizeMe' label='Запомнить меня' />
                  </FormGroup>
                  <FormGroup>
                    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    <Button label='Войти' onClick={handleSubmit} disabled={isLoading} />
                  </FormGroup>
                </Column>
              </Row>
              <Row justifyContent='center'>
                <Column md={16}>
                  {!!error && (
                    <Message variant={'filled'} type='error'>
                      {getErrorMessage(error)}
                    </Message>
                  )}
                </Column>
              </Row>
            </Grid>
          </form>
        )}
      />
    </div>
  )
}
