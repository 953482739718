import { useEffect, useRef, useState } from 'react'
import { ThreeEvent, useLoader } from '@react-three/fiber'
import { Line2 } from 'three/examples/jsm/lines/Line2'
import * as THREE from 'three'
import { TextureLoader } from 'three'
import { v4 as uuidv4 } from 'uuid'
import { Point } from '@modules/panorama/elements/point'
import { Line } from '@modules/panorama/elements/line'
import { OrbitControls } from '@react-three/drei'
import { PolygonGroup } from '@modules/panorama/elements/polygonGroup'
import { GeoProperties, Point as PointType, StepStatus } from '@modules/panorama/types'
import { PointItem, Element, LineItem, LineItemPoints, PolygonItem } from './elements/types'

export interface SceneProps {
  selectedObjectId: Element | undefined
  panoramaUrl: string
  geoProperties?: GeoProperties
  stepStatus?: StepStatus
  onStepDone?: (points: PointType[]) => void
  onStepStart?: () => void
  onStepRemovePoint?: () => void
}

const onSphereUpdate = (self: THREE.SphereGeometry) => {
  self.scale(-1, 1, 1)
}

export function Scene({
  selectedObjectId,
  panoramaUrl,
  geoProperties,
  stepStatus,
  onStepDone,
  onStepStart,
  onStepRemovePoint
}: SceneProps) {
  const [objects, setObjects] = useState<(PointItem | LineItem | PolygonItem)[]>([])
  const [selectedObjects, setSelectedObjects] = useState<string[]>([])
  const [isDrawing, setIsDrawing] = useState<boolean>(false)
  const lineRef = useRef<Line2>(null)
  const polygonLineRef = useRef<Line2>(null)
  const [isCreatingPolygon, setIsCreatingPolygon] = useState<boolean>(false)

  const [textureMap] = useLoader(TextureLoader, [panoramaUrl])
  textureMap.anisotropy = 16

  const onClick = (event: ThreeEvent<MouseEvent>) => {
    if (event.ctrlKey === false) {
      return
    }

    if (selectedObjectId === Element.Point) {
      const object = event.intersections[0]
      const uniqueId = uuidv4()
      const newObjects = objects.slice()
      newObjects.push({ id: uniqueId, type: Element.Point, entity: object })

      setObjects(newObjects)
      onStepDone?.([object.point.toArray()])
    } else if (selectedObjectId === Element.Polygon) {
      if (isCreatingPolygon === false) {
        const point = event.point.toArray()
        const newObjects = objects.slice()
        const uniqueId = uuidv4()

        newObjects.push({
          type: Element.Polygon,
          id: uniqueId,
          points: [point]
        })

        setObjects(newObjects)
        setIsCreatingPolygon(true)
      } else {
        const lastPolygon = objects.at(-1)

        if (lastPolygon === undefined || lastPolygon.type !== Element.Polygon) {
          return
        }

        const { point } = event
        const xyzSize = 7
        const clickArea = new THREE.Box3(
          new THREE.Vector3(point.x - xyzSize, point.y - xyzSize, point.z - xyzSize),
          new THREE.Vector3(point.x + xyzSize, point.y + xyzSize, point.z + xyzSize)
        )
        const firstPoint = lastPolygon.points[0]
        const isStart = clickArea.containsPoint(new THREE.Vector3(...firstPoint))

        let endPoint: PointType

        if (isStart) {
          endPoint = firstPoint
          setIsCreatingPolygon(false)
        } else {
          endPoint = point.toArray()
        }

        const newObjects = objects.slice(0, -1)
        const points = [...lastPolygon.points, endPoint]

        newObjects.push({ ...lastPolygon, points })
        setObjects(newObjects)

        if (isStart && onStepDone) {
          onStepDone(lastPolygon.points)
        }
      }
    }
  }

  const onSelected = (id: string) => () => {
    if (selectedObjects.includes(id)) {
      setSelectedObjects(selectedObjects.filter((selectedId) => selectedId !== id))
    } else {
      setSelectedObjects([...selectedObjects, id])
    }
  }

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.ctrlKey === true && event.code === 'KeyZ' && objects.length !== 0) {
        setObjects(objects.slice(0, -1))
        onStepStart?.()
        onStepRemovePoint?.()
      } else if (event.code === 'Delete' && objects.length !== 0 && selectedObjects.length !== 0) {
        setObjects(objects.filter((object) => !selectedObjects.includes(object.id)))
        setSelectedObjects([])
        onStepStart?.()
        onStepRemovePoint?.()
      }
    }

    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [objects, onStepRemovePoint, onStepStart, selectedObjects])

  const onPointerDown = (event: ThreeEvent<PointerEvent>) => {
    if (event.ctrlKey === false) {
      return
    }

    if (selectedObjectId === Element.Line) {
      setIsDrawing(true)

      const uniqueId = uuidv4()
      const point = event.point.toArray()
      const newObjects = objects.slice()

      newObjects.push({
        type: Element.Line,
        id: uniqueId,
        points: [point, point]
      })

      setObjects(newObjects)
    }
  }

  const onPointerMove = (event: ThreeEvent<PointerEvent>) => {
    if (event.ctrlKey === false) {
      return
    }

    if (selectedObjectId === Element.Line && isDrawing && lineRef.current) {
      const lastLine = objects.at(-1)

      if (lastLine === undefined || lastLine.type !== Element.Line) {
        return
      }

      const point = event.point.toArray()

      lineRef.current.geometry.setPositions([lastLine.points[0], point].flat())
    } else if (
      selectedObjectId === Element.Polygon &&
      isCreatingPolygon &&
      polygonLineRef.current
    ) {
      const lastPolygon = objects.at(-1)

      if (lastPolygon === undefined || lastPolygon.type !== Element.Polygon) {
        return
      }

      const prevPoint = lastPolygon.points[lastPolygon.points.length - 1]
      const nextPoint = event.point.toArray()

      polygonLineRef.current.geometry.setPositions([prevPoint, nextPoint].flat())
    }
  }

  const onPointerUp = (event: ThreeEvent<PointerEvent>) => {
    if (event.ctrlKey === false) {
      return
    }

    if (selectedObjectId === Element.Line) {
      setIsDrawing(false)

      const lastLine = objects.at(-1)

      if (lastLine === undefined || lastLine.type !== Element.Line) {
        return
      }

      const point = event.point
      const newObjects = objects.slice(0, -1)
      const firstPoint = lastLine.points[0]
      const isEqualPoint = point.equals(new THREE.Vector3(...firstPoint))

      if (!isEqualPoint) {
        const points = [firstPoint, point.toArray()] as LineItemPoints

        newObjects.push({ ...lastLine, points: points })
        onStepDone?.(points)
      }

      setObjects(newObjects)
    }
  }

  useEffect(() => {
    if (isCreatingPolygon && selectedObjectId !== Element.Polygon) {
      setObjects((objects) => objects.slice(0, -1))
      setIsCreatingPolygon(false)
    }
  }, [isCreatingPolygon, selectedObjectId])

  const [sphereRotation, setSphereRotation] = useState<THREE.Euler>(new THREE.Euler())
  const sphereRotateYRef = useRef<number>()

  useEffect(() => {
    if (!geoProperties) {
      return
    }

    const rotation = new THREE.Euler()
    const sphereRotateX = (-1 * geoProperties.roll * Math.PI) / 180
    const sphereRotateZ = (-1 * geoProperties.pitch * Math.PI) / 180

    if (sphereRotateYRef.current === undefined) {
      const azimuth = (-1 * Math.PI + 90 + geoProperties.yaw + 360) % 360
      sphereRotateYRef.current = (-1 * azimuth * Math.PI) / 180
    }

    rotation.reorder('YXZ')
    rotation.set(sphereRotateX, sphereRotateYRef.current, sphereRotateZ)

    setSphereRotation(rotation)
  }, [geoProperties])

  useEffect(() => {
    if (stepStatus === StepStatus.Start || stepStatus === StepStatus.None) {
      setObjects([])
    }
  }, [stepStatus])

  return (
    <>
      <mesh
        onClick={onClick}
        onPointerDown={onPointerDown}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerUp}
        rotation={sphereRotation}
      >
        <sphereGeometry onUpdate={onSphereUpdate} args={[500, 60, 40]} />
        <meshBasicMaterial map={textureMap} />
      </mesh>
      <group>
        {objects.map((object) => {
          switch (object.type) {
            case Element.Point:
              return (
                <Point
                  key={object.id}
                  position={object.entity.point}
                  isSelected={selectedObjects.includes(object.id)}
                  onSelected={onSelected(object.id)}
                />
              )
            case Element.Line:
              return (
                <Line
                  key={object.id}
                  ref={lineRef}
                  points={object.points}
                  isSelected={selectedObjects.includes(object.id)}
                  onSelected={onSelected(object.id)}
                />
              )
            case Element.Polygon:
              return (
                <PolygonGroup
                  key={object.id}
                  lineRef={polygonLineRef}
                  object={object}
                  isSelected={selectedObjects.includes(object.id)}
                  onSelected={onSelected(object.id)}
                />
              )
          }
        })}
      </group>
      <OrbitControls
        rotateSpeed={0.4}
        zoomSpeed={5}
        maxDistance={300}
        minDistance={10}
        enablePan={false}
        makeDefault
        reverseOrbit
      />
    </>
  )
}
