import {
  BaseReportFields,
  ElectricalSteps,
  Groups,
  MechanicalSteps
} from '@modules/missions/keaz/types'
import { AgentumReportWithRelations } from '@api/generated'
import { labels } from '@modules/missions/keaz/units/reports/labels'

export const getStepTitle = (step: AgentumReportWithRelations) => {
  let title = ''
  if (step) {
    switch (step[BaseReportFields.group]) {
      case Groups.mechanical:
        title = labels[Groups.mechanical][step[BaseReportFields.stepType] as MechanicalSteps]
        break
      case Groups.electrical:
        title = labels[Groups.electrical][step[BaseReportFields.stepType] as ElectricalSteps]
        break
    }
  }

  return title
}
