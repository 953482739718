import React from 'react'
import { SelectField as SmwbSelectField } from '@smwb/summer-ui'
import type { SelectFieldProps as SmwbSelectFieldProps } from '@smwb/summer-ui'
import style from './selectField.module.less'
import cn from 'classnames'

export type SelectFieldProps = Omit<SmwbSelectFieldProps, 'menuMaxHeight' | 'menuModifiers'>

export const SelectField = ({ className, ...props }: SelectFieldProps) => {
  return (
    <SmwbSelectField
      {...props}
      menuModifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 6]
          }
        }
      ]}
      menuMaxHeight={200}
      className={cn(className, style['select-field'])}
    />
  )
}
