import React, {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Icon, Button } from '@smwb/summer-ui'
import { useDrag } from 'react-dnd'
import style from './floatWindow.module.less'

const typeDrag = 'floatWindow'

interface Point {
  x: number
  y: number
}

const minHeight = 100
const minWidth = 100

export interface FloatWindowProps {
  children: ReactNode
  initWidth?: number
  initHeight?: number
  initPositionX?: number
  initPositionY?: number
  onResize?: (newSize: { width: number; height: number }) => void
  resetPosition?: () => void
}

const toolBarHeight = +style.width

export function FloatWindow({
  children,
  onResize,
  initHeight = 300,
  initWidth = 300,
  initPositionX = 0,
  initPositionY = 0
}: FloatWindowProps) {
  const [position, setPosition] = useState<Point>({ x: initPositionX, y: initPositionY })
  const [size, setSize] = useState({ width: initWidth, height: initHeight + toolBarHeight })
  const [isResizing, setIsResizing] = useState(false)
  const [startResizePosition, setStartResizePosition] = useState<[Point, Point] | null>(null)
  const setPositionRef = useRef(setPosition)
  const setSizeRef = useRef(setSize)
  // Обновляем ссылку при изменении setV
  useEffect(() => {
    setPositionRef.current = setPosition
    setSizeRef.current = setSize
  }, [setSize, setPosition])

  useEffect(() => {
    if (onResize) {
      onResize({ ...size, height: size.height - toolBarHeight })
    }
  }, [onResize, size])

  const [, dragRef, preview] = useDrag(
    () => ({
      type: typeDrag,
      item: { x: position.x, y: position.y },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      }),
      end: (item, monitor) => {
        const delta = monitor.getDifferenceFromInitialOffset()
        if (delta) {
          const x = Math.round(item.x + delta.x)
          const y = Math.round(item.y - delta.y)
          setPosition({ x, y })
        }
      }
    }),
    [position]
  )

  preview(<div />)
  const handleResizeMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setIsResizing(true)
      setStartResizePosition([
        { x: e.clientX, y: e.clientY },
        { x: size.width, y: size.height }
      ])
    },
    [size.height, size.width]
  )

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isResizing || !startResizePosition) return

      const deltaX = e.clientX - startResizePosition[0].x
      const deltaY = e.clientY - startResizePosition[0].y
      const newWidth = Math.max(startResizePosition[1].x + deltaX, minWidth)
      const newHeight = Math.max(startResizePosition[1].y - deltaY, minHeight)
      setSize({ width: newWidth, height: newHeight })
    },
    [isResizing, startResizePosition]
  )

  const handleMouseUp = useCallback(() => {
    setIsResizing(false)
    setStartResizePosition(null)
  }, [])

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isResizing, handleMouseMove, handleMouseUp])

  return (
    <div
      style={{
        left: position.x,
        bottom: position.y,
        width: size.width,
        height: size.height
      }}
      className={style.layout}
    >
      <div ref={dragRef} className={style.drag}>
        <Icon name='drag_indicator' />
      </div>
      {children}
      <Button
        icon={'restart_alt'}
        className={style.reset}
        size='small'
        onClick={() => {
          setPosition({ x: initPositionX, y: initPositionY })
          setSize({ width: initWidth, height: initHeight })
        }}
      />
      <div className={style.resize} onMouseDown={handleResizeMouseDown}>
        <Icon name='width' />
      </div>
    </div>
  )
}
