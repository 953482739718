import React from 'react'
import { Line2 } from 'three/examples/jsm/lines/Line2'
import { PolygonItem } from '@modules/panorama/elements/types'
import { Line as DreiLine } from '@react-three/drei/core/Line'
import { FilledPolygon } from '@modules/panorama/elements/filledPolygon'
import * as THREE from 'three'
import { ThreeEvent } from '@react-three/fiber'
import { useHovered } from '@modules/panorama/hooks/useHovered'
import { colors } from '@styles/colors'

export interface PolygonGroupProps {
  lineRef: React.Ref<Line2>
  object: PolygonItem
  isSelected: boolean
  onSelected: () => void
}

export function PolygonGroup({ lineRef, object, isSelected, onSelected }: PolygonGroupProps) {
  const { isHovered, onPointerOver, onPointerOut } = useHovered()

  const onClick = (event: ThreeEvent<MouseEvent>) => {
    if (event.ctrlKey === true) {
      return
    }

    onSelected?.()
  }

  const color = isSelected ? colors.red : isHovered ? colors.yellow : colors.green
  // TODO почему Points не вектора ?
  // TODO FilledPolygon можно сразу делать с контуром
  return (
    <>
      <FilledPolygon
        onSelected={onClick}
        points={object.points.map((el) => new THREE.Vector3(...el))}
        color={color}
        isHovered={isHovered}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
      />
      <group onClick={onClick} onPointerOver={onPointerOver} onPointerOut={onPointerOut}>
        {object.points.map((point, index) => {
          const startPoint = point
          const endPoint = object.points.at(index + 1)
          const points = [startPoint, endPoint === undefined ? startPoint : endPoint]

          return <DreiLine key={index} ref={lineRef} points={points} lineWidth={2} color={color} />
        })}
      </group>
    </>
  )
}
