import { Button, Column, Grid, Row, Sheet } from '@smwb/summer-ui'
import get from 'lodash/get'
import { useFormState } from 'react-final-form'
import style from './photoView.module.less'
import { Key, useState } from 'react'
import { Text } from '@/components/ui/typography/text'
import { useParams } from 'react-router-dom'
import { config } from '@/settings'
import { PROJECT } from '@modules/missions/keaz/const'
import ImageMagnifier from './imageMagnifier/imageMagnifier'
import cs from 'classnames'

export interface PhotoUrl {
  original: string
  miniature: string
}

const altImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII='

export interface PhotoViewProps<T> {
  fieldName: keyof T
  subField?: string
  maxPhoto?: number
  title?: string
  compact?: boolean
}

export function PhotoView<T extends Record<string, any>>({
  fieldName,
  subField,
  maxPhoto = 8,
  title,
  compact
}: PhotoViewProps<T>) {
  const [viewOpenPhoto, setViewOpenPhoto] = useState<{ url: string; index: number } | undefined>(
    undefined
  )
  const { values, errors, touched } = useFormState<T>()
  const { id } = useParams()

  // костыль для типов со сложной структурой формы...
  const value =
    (get(values, `${fieldName.toString()}${subField ? '.' + subField : ''}`) as T[keyof T]) ??
    ([] as T[keyof T])

  const isTouched = touched ? Object.values(touched).find((el) => el) : false

  const error = get(
    errors,
    `${fieldName.toString()}${subField ? '.' + subField : ''}`
  ) as T[keyof T]

  return (
    <>
      {title && <Text size='l'>{title}</Text>}
      {!!error && !!isTouched && (
        <h3 className={style.error}>{typeof error === 'object' ? JSON.stringify(error) : error}</h3>
      )}
      <Grid className={style.layout}>
        <Row spacing={2}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
          {value.map((el: PhotoUrl, i: number) => {
            const urlCreator = window.URL || window.webkitURL
            const imageUrl =
              typeof el.miniature === 'string'
                ? el.miniature
                : (el.miniature as unknown) instanceof Blob
                ? urlCreator.createObjectURL(el.miniature as unknown as Blob)
                : `${config.apiUrl}/mission/${id || ''}/project/${PROJECT}/files/${
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    el.original
                  }.jpeg`
            return (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
              <Column xs={2} key={el.original}>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                <img
                  src={imageUrl}
                  alt={altImage}
                  onClick={() => setViewOpenPhoto({ url: imageUrl, index: i })}
                />
              </Column>
            )
          })}
        </Row>
      </Grid>

      <Sheet
        size='80%'
        withSizeToggle={false}
        isOpen={!!viewOpenPhoto}
        onClose={() => setViewOpenPhoto(undefined)}
        variant='modal'
      >
        <div className={cs(style.imgView)}>
          <ImageMagnifier
            className={style.scale}
            src={viewOpenPhoto?.url}
            alt={altImage}
            magnifierRadius={100}
          />
        </div>
      </Sheet>
    </>
  )
}
