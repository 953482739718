import { useState, useEffect } from 'react'
import { secondsSince, secondsToHHMMSS } from '@modules/missions/keaz/utils/time'

export function useTimer(
  timestampStart: number = new Date().getTime(),
  timestampEnd?: number | undefined,
  isStatic = false
) {
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    setSeconds(secondsSince(timestampStart, timestampEnd))

    if (!timestampEnd && !isStatic) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [timestampEnd, isStatic, timestampStart])

  const timer = secondsToHHMMSS(seconds)
  return [timer]
}
