import styles from '../index.module.less'
import { Text } from '@/components/ui/typography/text'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import {
  BaseReportFields,
  MechanicalStepOilChangeAndFilterCleaning,
  MechanicalStepOilChangeAndFilterCleaningFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import { Title } from '@/components/ui/typography/title'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

export interface OilChangeAndFilterCleaningProps {
  compact: boolean
  step: MechanicalStepOilChangeAndFilterCleaning
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function OilChangeAndFilterCleaning({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: OilChangeAndFilterCleaningProps) {
  return (
    <div>
      <StepForm<MechanicalStepOilChangeAndFilterCleaning>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepOilChangeAndFilterCleaningFields.oilReplacementRequired}
              label='Необходимо заменить масло'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepOilChangeAndFilterCleaning>
              compact={compact}
              title='Фото состояния масла'
              fieldName={MechanicalStepOilChangeAndFilterCleaningFields.oilConditionPhoto}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepOilChangeAndFilterCleaning>
              compact={compact}
              title='Фото состояния масла после замены'
              fieldName={
                MechanicalStepOilChangeAndFilterCleaningFields.oilConditionAfterReplacementPhoto
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepOilChangeAndFilterCleaningFields.suctionFilterCleaningRequired}
              label='Необходимо очистить всасывающий фильтр'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepOilChangeAndFilterCleaning>
              compact={compact}
              title='Фото всасывающего фильтра до очистки'
              fieldName={
                MechanicalStepOilChangeAndFilterCleaningFields.suctionFilterBeforeCleaningPhoto
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepOilChangeAndFilterCleaning>
              compact={compact}
              title='Фото всасывающего фильтра после очистки'
              fieldName={
                MechanicalStepOilChangeAndFilterCleaningFields.suctionFilterAfterCleaningPhoto
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepOilChangeAndFilterCleaningFields.fillingFilterCleaningRequired}
              label='Необходимо очистить заливной фильтр'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepOilChangeAndFilterCleaning>
              compact={compact}
              title='Фото заливного фильтра до очистки'
              fieldName={
                MechanicalStepOilChangeAndFilterCleaningFields.fillingFilterBeforeCleaningPhoto
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepOilChangeAndFilterCleaning>
              compact={compact}
              title='Фото заливного фильтра после очистки'
              fieldName={
                MechanicalStepOilChangeAndFilterCleaningFields.fillingFilterAfterCleaningPhoto
              }
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepOilChangeAndFilterCleaningFields.deviations}
          commentFieldName={MechanicalStepOilChangeAndFilterCleaningFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
