import { Outlet } from 'react-router-dom'
import { LeftContent } from '@modules/layout/leftContent'
import { RightContent } from '@modules/layout/rightContent'
import style from './layout.module.less'
import { AppBar } from '@/components/ui/navigation/appBar/appBar'

export function Layout() {
  return (
    <div className={style.layout}>
      <AppBar
        leftContent={<LeftContent />}
        rightContent={<RightContent />}
        hasShadow={false}
        className={style.appBar}
      />

      <div className={style.content}>
        <Outlet />
      </div>
    </div>
  )
}
