import React from 'react'
import { Logo } from '@/components/ui/base/logo/logo'
import style from './leftContent.module.less'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { Urls } from '@/urls'

const menuItems = [
  {
    title: 'Дашборд',
    path: Urls.Root
  },
  {
    title: 'Карта',
    path: Urls.Missions
  },
  {
    title: 'Задача',
    path: Urls.Root
  },
  {
    title: 'Панорама',
    path: Urls.Root
  },
  {
    title: 'Экспорт',
    path: Urls.Root
  }
]

export const LeftContent = () => {
  const location = useLocation()

  return (
    <div className={style.leftContent}>
      <Link to={Urls.Root}>
        <Logo />
      </Link>

      <div className={style.separator} />

      <div className={style.navMenu}>
        <div className={style.navMenu}>
          {menuItems.map(({ title, path }) => (
            <Link
              key={title}
              to={path}
              className={cn(
                style.navMenu__item,
                location.pathname === path && style['navMenu__item-active']
              )}
            >
              {title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
