import { TasksTable } from '@modules/missions/keaz/components/tasksTable'
import { BaseReportFields, ReportSubtype } from '@modules/missions/keaz/types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Urls } from '@/urls'
import { apiCall } from '@api/resources/apiCall'
import { skipToken } from '@reduxjs/toolkit/query'
import { PageTitle } from '@modules/missions/keaz/components/pageTitle'
import { PROJECT } from '@modules/missions/keaz/const'

export function KeazMission() {
  const { id } = useParams()
  const { data: tasks = [], isLoading } = apiCall.useAgentumReportControllerFindQuery(
    id ? [id, PROJECT, { where: { [BaseReportFields.subtype]: ReportSubtype.task } }] : skipToken
  )

  const navigate = useNavigate()

  return (
    <>
      <PageTitle title='Список задач' />

      <TasksTable
        tasks={tasks}
        isLoading={isLoading}
        handleRowClick={(task) =>
          navigate(
            generatePath(Urls.KeazTask, {
              id: id || null,
              taskOrigin: task[BaseReportFields.origin] || null
            })
          )
        }
      />
    </>
  )
}
