import axios, { AxiosResponse } from 'axios'
import { Cookies } from 'react-cookie'
import { COOKIES } from '@api/utils/const'
import { config } from '@/settings'
import { UsersControllerRefresh200Response, UsersControllerRefreshRequest } from '@api/generated'
import { setLogout, setLoggedIn, userLoggedOut } from '@redux/slices/userSlice'
import { store } from '@redux/store/store'
import { Urls } from '@/urls'

const cookies = new Cookies(null, { path: '/' })

export function setInterceptors() {
  const interceptor = axios.interceptors.response.use(
    (response) => {
      return response
    },
    async (err) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      const originalRequest = err.config
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      if (originalRequest.url.search(/users\/login$/) !== -1) {
        throw err
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (err.response.status === 401 && !originalRequest._retry) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        originalRequest._retry = true
        axios.interceptors.response.eject(interceptor)

        try {
          await signInWithRefreshToken()
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          originalRequest.headers.Authorization = `Bearer ${
            cookies.get(COOKIES.JWT_TOKEN) as string
          }`
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return await axios(originalRequest)
        } finally {
          setInterceptors()
        }
      }
      return Promise.reject(err)
    }
  )
}

export function signInWithRefreshToken() {
  return new Promise<string>((resolve) => {
    const refreshToken = cookies.get(COOKIES.REFRESH_TOKEN) as string | undefined
    if (!refreshToken) {
      store.dispatch(userLoggedOut())
      window.location.href = Urls.Login
      return
    }
    axios
      .post(`${config.apiUrl}/refresh`, {
        refreshToken
      } as UsersControllerRefreshRequest)
      .then((response: AxiosResponse<UsersControllerRefresh200Response>) => {
        if (!response.data.accessToken) {
          store.dispatch(userLoggedOut())
          window.location.href = Urls.Login
          return
        }
        setLoggedIn(store.dispatch, response.data.accessToken, refreshToken)
        resolve(response.data.accessToken)
      })
      .catch(() => {
        setLogout()
        store.dispatch(userLoggedOut())
        window.location.href = Urls.Login
        return
      })
  })
}
