import { pick } from 'lodash'
import React, { FC } from 'react'
import PropTypes from 'prop-types'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import { FieldPropTypes } from '@smwb/summer-ui/dist/connects/rff/types/fieldTypes'
import {
  TextArea,
  TextAreaProps,
  textAreaPropTypes
} from '@/components/ui/inputs/textArea/textArea'

export type TextAreaWrapperProps = TextAreaProps &
  FieldProps<string, FieldRenderProps<string, HTMLElement, string>>

const TextAreaWrapper: FC<TextAreaWrapperProps> = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaWrapperProps
>(function TextAreaWrapper({ name, ...rest }, ref) {
  const inputProps = pick(rest, Object.keys(textAreaPropTypes))
  const fieldProps = pick(rest, Object.keys(FieldPropTypes))

  return (
    <Field {...fieldProps} name={name}>
      {({ input, meta }) => {
        return (
          <TextArea
            {...input}
            {...inputProps}
            ref={ref}
            error={meta.invalid && meta.touched}
            helperText={meta.touched && (meta.error as string)}
          />
        )
      }}
    </Field>
  )
})

TextAreaWrapper.propTypes = {
  ...textAreaPropTypes,
  ...FieldPropTypes,
  value: PropTypes.string
}
export default TextAreaWrapper
