import {
  UsersControllerApi,
  MissionsControllerApi,
  GeoJsonControllerApi,
  Configuration,
  AgentumReportControllerApi
} from '../generated'
import { config } from '@/settings'
import { createApi } from '@reduxjs/toolkit/query/react'

import { bindMethodsMutation, bindMethodsQuery } from '@api/utils/bindMethods'
import { COOKIES } from '@api/utils/const'
import { Cookies } from 'react-cookie'
import { signInWithRefreshToken, setInterceptors } from '@api/utils/axios'
import { fakeQuery } from '@api/utils/fakeQuery'

const cookies = new Cookies(null, { path: '/' })

const configuration = new Configuration({
  basePath: config.apiUrl,
  accessToken: async (): Promise<string> => {
    const token = cookies.get(COOKIES.JWT_TOKEN) as string | undefined
    if (token) {
      return token
    } else if (cookies.get(COOKIES.REFRESH_TOKEN)) {
      try {
        return await signInWithRefreshToken()
      } catch (e) {
        console.error(e)
        // TODO возврат на страницу логина
        return ''
      }
    }
    // TODO возврат на страницу логина
    return ''
  }
})
setInterceptors()
const usersControllerApi = new UsersControllerApi(configuration)
const missionsControllerApi = new MissionsControllerApi(configuration)
const geoJsonControllerApi = new GeoJsonControllerApi(configuration)
const agentumReportControllerApi = new AgentumReportControllerApi(configuration)

enum TagTypes {
  users = 'users',
  agentumReport = 'agentumReport',
  missions = 'missions'
}

export const apiCall = createApi({
  baseQuery: fakeQuery(),
  endpoints: (build) => {
    return {
      ...bindMethodsQuery(build, usersControllerApi, ['usersControllerFind'], TagTypes.users),
      ...bindMethodsMutation(build, usersControllerApi, ['usersControllerLogin'], TagTypes.users),

      ...bindMethodsMutation(
        build,
        agentumReportControllerApi,
        ['agentumReportControllerUpdateById'],
        TagTypes.agentumReport
      ),

      ...bindMethodsQuery(
        build,
        missionsControllerApi,
        ['missionsControllerFind', 'missionsControllerGetMissionTemplate'],
        TagTypes.missions
      ),

      ...bindMethodsQuery(
        build,
        agentumReportControllerApi,
        ['agentumReportControllerFind', 'agentumReportControllerFindById'],
        TagTypes.agentumReport
      ),

      ...bindMethodsQuery(build, geoJsonControllerApi, ['geoJsonControllerFind'])
    }
  }
})
