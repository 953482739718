import styles from '../index.module.less'
import { Text } from '@/components/ui/typography/text'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import {
  BaseReportFields,
  ElectricalStepHeaterZonesCheck,
  ElectricalStepHeaterZonesCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import { TextField } from '@smwb/summer-ui/dist/connects/rff'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

export interface HeaterZonesCheckProps {
  compact: boolean
  step: ElectricalStepHeaterZonesCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function HeaterZonesCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: HeaterZonesCheckProps) {
  return (
    <div>
      <StepForm<ElectricalStepHeaterZonesCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <TextField
              name={ElectricalStepHeaterZonesCheckFields.currentReadingPoint1}
              type='number'
              label='Показания тока для точки №1'
              fullWidth={true}
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepHeaterZonesCheck>
              compact={compact}
              title='Фото показаний с дисплея токовых клещей для точки №1'
              fieldName={ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint1}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <TextField
              name={ElectricalStepHeaterZonesCheckFields.currentReadingPoint2}
              type='number'
              label='Показания тока для точки №2'
              fullWidth={true}
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepHeaterZonesCheck>
              compact={compact}
              title='Фото показаний с дисплея токовых клещей для точки №2'
              fieldName={ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint2}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <TextField
              name={ElectricalStepHeaterZonesCheckFields.currentReadingPoint3}
              type='number'
              label='Показания тока для точки №3'
              fullWidth={true}
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepHeaterZonesCheck>
              compact={compact}
              title='Фото показаний с дисплея токовых клещей для точки №3'
              fieldName={ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint3}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <TextField
              name={ElectricalStepHeaterZonesCheckFields.currentReadingPoint4}
              type='number'
              label='Показания тока для точки №4'
              fullWidth={true}
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepHeaterZonesCheck>
              compact={compact}
              title='Фото показаний с дисплея токовых клещей для точки №4'
              fieldName={ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint4}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <TextField
              name={ElectricalStepHeaterZonesCheckFields.currentReadingPoint5}
              type='number'
              label='Показания тока для точки №5'
              fullWidth={true}
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepHeaterZonesCheck>
              compact={compact}
              title='Фото показаний с дисплея токовых клещей для точки №5'
              fieldName={ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint5}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <TextField
              name={ElectricalStepHeaterZonesCheckFields.currentReadingPoint6}
              type='number'
              label='Показания тока для точки №6'
              fullWidth={true}
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<ElectricalStepHeaterZonesCheck>
              compact={compact}
              title='Фото показаний с дисплея токовых клещей для точки №6'
              fieldName={ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint6}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={ElectricalStepHeaterZonesCheckFields.deviations}
          commentFieldName={ElectricalStepHeaterZonesCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
