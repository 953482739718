import style from './index.module.less'
import {
  BaseReportFields,
  ElectricalSteps,
  Groups,
  MechanicalSteps,
  Step,
  StepStatus
} from '@modules/missions/keaz/types'
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { MachineIdentificationContent as MechanicalMachineIdentificationContent } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/machineIdentificationContent'
import { HydraulicConnectionCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/hydraulicConnectionCheck'
import { PressureAndPumpCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/pressureAndPumpCheck'
import { SafetyCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/safetyCheck'
import { LimitSwitchCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/limitSwitchCheck'
import { InjectionUnitLeakCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/injectionUnitLeakCheck'
import { ClampingUnitLeakCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/clampingUnitLeakCheck'
import { EjectionUnitLeakCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/ejectionUnitLeakCheck'
import { FasteningCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/fasteningCheck'
import { CoolingSystemCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/coolingSystemCheck'
import { CoolingCircuitFlush } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/coolingCircuitFlush'
import { OilChangeAndFilterCleaning } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/oilChangeAndFilterCleaning'
import { FineOilFilterCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/fineOilFilterCheck'
import { LubricationSystemCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/lubricationSystemCheck'
import { EquipmentCleaning } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/equipmentCleaning'

import { MachineIdentificationContent as ElectricalMachineIdentificationContent } from '@modules/missions/keaz/step/components/contentBySteps/electrical/machineIdentificationContent'
import { CurrentCheckUnderLoad } from '@modules/missions/keaz/step/components/contentBySteps/electrical/currentCheckUnderLoad'
import { NoiseAndVibrationCheck } from '@modules/missions/keaz/step/components/contentBySteps/electrical/noiseAndVibrationCheck'
import { CabinetAndMotorCleaning } from '@modules/missions/keaz/step/components/contentBySteps/electrical/cabinetAndMotorCleaning'
import { HeaterZonesCheck } from '@modules/missions/keaz/step/components/contentBySteps/electrical/heaterZonesCheck'
import { ElectricalConnectionsCheck } from '@modules/missions/keaz/step/components/contentBySteps/electrical/electricalConnectionsCheck'
import { InjectionMoldingMachineMovementsCheck } from '@modules/missions/keaz/step/components/contentBySteps/electrical/injectionMoldingMachineMovementsCheck'

import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'
import { skipToken } from '@reduxjs/toolkit/query'
import { PageTitle } from '@modules/missions/keaz/components/pageTitle'
import { Title } from '@/components/ui/typography/title'
import { Button, useSnackbar } from '@smwb/summer-ui'
import { Urls } from '@/urls'
import { PROJECT } from '@modules/missions/keaz/const'
import { getStepTitle } from '@modules/missions/keaz/utils/step'
import { RejectStepConfirmationModal } from '@modules/missions/keaz/step/components/rejectStepConfirmationModal'

export function Index() {
  const { id, taskOrigin, groupIndex, stepOrigin } = useParams()

  const group = Object.values(Groups)[parseInt(groupIndex || '0')]
  const { data: step, isLoading } = apiCall.useAgentumReportControllerFindByIdQuery(
    id ? [id, PROJECT, stepOrigin || ''] : skipToken
  )

  const canEditStep = false

  const [compact, setCompact] = useState(!canEditStep)
  const [rejectStepModalOpen, setRejectStepModalOpen] = useState(false)

  const navigate = useNavigate()

  interface UpdateReportByIdArgs {
    isLoading?: boolean
    error?: unknown
    data?: unknown
  }

  const onBack = useCallback(() => {
    navigate(
      generatePath(Urls.KeazGroup, {
        id: id || null,
        taskOrigin: taskOrigin || null,
        groupIndex: groupIndex || null
      }),
      {
        replace: true
      }
    )
  }, [groupIndex, taskOrigin, id, navigate])

  const [
    updateReportById,
    {
      data: updateReportByIdData,
      isLoading: updateReportByIdIsLoading,
      error: updateReportByIdError
    }
  ] = apiCall.useAgentumReportControllerUpdateByIdMutation<UpdateReportByIdArgs>()

  const { addSnackbar } = useSnackbar()
  const addSnackbarRef = useRef(addSnackbar)

  useEffect(() => {
    if (!updateReportByIdIsLoading) {
      if (updateReportByIdData !== undefined) {
        addSnackbarRef.current({
          message: 'Статус отчета изменен',
          position: 'top-right',
          variant: 'success'
        })

        onBack()
      } else if (updateReportByIdError) {
        addSnackbarRef.current({
          message: 'Ошибка изменения статуса отчета',
          position: 'top-right',
          variant: 'danger'
        })
      }
    }
  }, [updateReportByIdData, updateReportByIdIsLoading, updateReportByIdError, onBack])

  const handleRejectStep = (reason: string) => {
    if (id && step) {
      void updateReportById([
        id,
        PROJECT,
        step[BaseReportFields.id] as string,
        {
          [BaseReportFields.status]: StepStatus.rejected,
          [BaseReportFields.statusReason]: reason
        }
      ])
    }
  }

  const handleApproveStep = () => {
    if (id && step) {
      void updateReportById([
        id,
        PROJECT,
        step[BaseReportFields.id] as string,
        {
          [BaseReportFields.status]: StepStatus.approved
        }
      ])
    }
  }

  if (isLoading) {
    return <div>'loading'</div>
  }

  if (!step) {
    return <div>Шаг не найден</div>
  }

  if (!group) {
    return <div>Группа не найдена</div>
  }

  const screenContent: {
    [Groups.mechanical]: Record<MechanicalSteps, ReactElement>
    [Groups.electrical]: Record<ElectricalSteps, ReactElement>
  } = {
    [Groups.mechanical]: {
      [MechanicalSteps.machineIdentification]: (
        <MechanicalMachineIdentificationContent
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.hydraulicConnectionCheck]: (
        <HydraulicConnectionCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.pressureAndPumpCheck]: (
        <PressureAndPumpCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.safetyCheck]: (
        <SafetyCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.limitSwitchCheck]: (
        <LimitSwitchCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.injectionUnitLeakCheck]: (
        <InjectionUnitLeakCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.clampingUnitLeakCheck]: (
        <ClampingUnitLeakCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.ejectionUnitLeakCheck]: (
        <EjectionUnitLeakCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.fasteningCheck]: (
        <FasteningCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.coolingSystemCheck]: (
        <CoolingSystemCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.coolingCircuitFlush]: (
        <CoolingCircuitFlush
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.oilChangeAndFilterCleaning]: (
        <OilChangeAndFilterCleaning
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.fineOilFilterCheck]: (
        <FineOilFilterCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.lubricationSystemCheck]: (
        <LubricationSystemCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [MechanicalSteps.equipmentCleaning]: (
        <EquipmentCleaning
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      )
    },
    [Groups.electrical]: {
      [ElectricalSteps.machineIdentification]: (
        <ElectricalMachineIdentificationContent
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [ElectricalSteps.currentCheckUnderLoad]: (
        <CurrentCheckUnderLoad
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [ElectricalSteps.noiseAndVibrationCheck]: (
        <NoiseAndVibrationCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [ElectricalSteps.cabinetAndMotorCleaning]: (
        <CabinetAndMotorCleaning
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [ElectricalSteps.heaterZonesCheck]: (
        <HeaterZonesCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [ElectricalSteps.electricalConnectionsCheck]: (
        <ElectricalConnectionsCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      ),
      [ElectricalSteps.injectionMoldingMachineMovementsCheck]: (
        <InjectionMoldingMachineMovementsCheck
          compact={compact}
          setCompact={setCompact}
          step={step as unknown as Step}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      )
    }
  }

  return (
    <>
      <PageTitle
        title={getStepTitle(step)}
        onBack={() =>
          navigate(
            generatePath(Urls.KeazGroup, {
              id: id || null,
              taskOrigin: taskOrigin || null,
              groupIndex: groupIndex || null
            }),
            {
              replace: true
            }
          )
        }
      />

      <div className={style.card}>
        <Title level={1}>Данные</Title>

        {
          screenContent[group][
            step[BaseReportFields.stepType] as keyof (typeof screenContent)[Groups]
          ]
        }
      </div>

      {step[BaseReportFields.status] === StepStatus.signed && (
        <div className={style.buttons}>
          <Button
            variant='outlined'
            disabled={updateReportByIdIsLoading}
            onClick={() => setRejectStepModalOpen(true)}
          >
            Отклонить
          </Button>

          <Button disabled={updateReportByIdIsLoading} onClick={handleApproveStep}>
            Принять
          </Button>
        </div>
      )}

      <RejectStepConfirmationModal
        isOpen={rejectStepModalOpen}
        loading={updateReportByIdIsLoading}
        onAccept={handleRejectStep}
        onCancel={() => setRejectStepModalOpen(false)}
      />
    </>
  )
}
