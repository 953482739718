import React from 'react'
import { SearchField } from '@/components/ui/inputs/searchField/searchField'
import style from './rightContent.module.less'
import { Icon } from '@/components/ui/base/icon/icon'
import cn from 'classnames'
import { UserMenu } from '@modules/layout/userMenu'

export const RightContent = () => {
  return (
    <div className={style.rightContent}>
      <SearchField
        onChange={() => ({})}
        resetSearch={() => ({})}
        onSearch={() => ({})}
        isLoading={false}
        fullWidth={true}
        items={[]}
        className={style.search}
      />

      <Icon icon='ring' color={'black'} className={cn(style.icons, style.notificationIcon)} />

      <Icon icon='settings' color={'black'} className={cn(style.icons, style.settingsIcon)} />

      <UserMenu className={style.userMenu} />
    </div>
  )
}
