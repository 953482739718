/* tslint:disable */
/* eslint-disable */
/**
 * agentum-back
 * agentum back
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: hello@agentum.tech
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * @type AgentumHandbookFields
 * @export
 */
export type AgentumHandbookFields = AgentumHandbookFieldsOneOf | Set<string>;

/**
 *
 * @export
 * @interface AgentumHandbookFieldsOneOf
 */
export interface AgentumHandbookFieldsOneOf {
    [key: string]: any;

    /**
     *
     * @type {boolean}
     * @memberof AgentumHandbookFieldsOneOf
     */
    '_id'?: boolean;
}
/**
 *
 * @export
 * @interface AgentumHandbookFilter
 */
export interface AgentumHandbookFilter {
    /**
     *
     * @type {number}
     * @memberof AgentumHandbookFilter
     */
    'offset'?: number;
    /**
     *
     * @type {number}
     * @memberof AgentumHandbookFilter
     */
    'limit'?: number;
    /**
     *
     * @type {number}
     * @memberof AgentumHandbookFilter
     */
    'skip'?: number;
    /**
     *
     * @type {GeoJsonFilterOrder}
     * @memberof AgentumHandbookFilter
     */
    'order'?: GeoJsonFilterOrder;
    /**
     *
     * @type {AgentumHandbookFields}
     * @memberof AgentumHandbookFilter
     */
    'fields'?: AgentumHandbookFields;
}
/**
 * (tsType: AgentumHandbookWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface AgentumHandbookWithRelations
 */
export interface AgentumHandbookWithRelations {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof AgentumHandbookWithRelations
     */
    '_id'?: string;
}
/**
 *
 * @export
 * @interface AgentumReport
 */
export interface AgentumReport {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof AgentumReport
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof AgentumReport
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof AgentumReport
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof AgentumReport
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof AgentumReport
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof AgentumReport
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof AgentumReport
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof AgentumReport
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReport
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof AgentumReport
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof AgentumReport
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof AgentumReport
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof AgentumReport
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof AgentumReport
     */
    'taskId'?: string;
}
/**
 * @type AgentumReportControllerFind200ResponseInner
 * @export
 */
export type AgentumReportControllerFind200ResponseInner = AgentumReport | CabinetAndMotorCleaningModel | ClampingUnitLeakCheckModel | CoolingCircuitFlushModel | CoolingSystemCheckModel | CurrentCheckUnderLoadModel | EjectionUnitLeakCheckModel | ElectricalConnectionsCheckModel | EquipmentCleaningModel | FasteningCheckModel | FineOilFilterCheckModel | HeaterZonesCheckModel | HydraulicConnectionCheckModel | InjectionMoldingMachineMovementsModel | InjectionUnitLeakCheckModel | KeazStepModel | KeazTaskModel | LimitSwitchCheckModel | LubricationSystemCheckModel | MachineIdentificationModel | NoiseAndVibrationCheckModel | OilChangeAndFilterCleaningModel | PressureAndPumpCheckModel | SafetyCheckModel;

/**
 * @type AgentumReportFields
 * @export
 */
export type AgentumReportFields = AgentumReportFieldsOneOf | Set<string>;

/**
 *
 * @export
 * @interface AgentumReportFieldsOneOf
 */
export interface AgentumReportFieldsOneOf {
    [key: string]: any;

    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    '_id'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'origin'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'mission'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'project'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'type'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'creator_firstname'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'creator_lastname'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'creator_profession'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'deleted'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'timestamp'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'location'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'latitude'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'longitude'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportFieldsOneOf
     */
    'taskId'?: boolean;
}
/**
 *
 * @export
 * @interface AgentumReportFilter
 */
export interface AgentumReportFilter {
    /**
     *
     * @type {number}
     * @memberof AgentumReportFilter
     */
    'offset'?: number;
    /**
     *
     * @type {number}
     * @memberof AgentumReportFilter
     */
    'limit'?: number;
    /**
     *
     * @type {number}
     * @memberof AgentumReportFilter
     */
    'skip'?: number;
    /**
     *
     * @type {GeoJsonFilterOrder}
     * @memberof AgentumReportFilter
     */
    'order'?: GeoJsonFilterOrder;
    /**
     *
     * @type {AgentumReportFields}
     * @memberof AgentumReportFilter
     */
    'fields'?: AgentumReportFields;
}
/**
 *
 * @export
 * @interface AgentumReportFilter1
 */
export interface AgentumReportFilter1 {
    /**
     *
     * @type {number}
     * @memberof AgentumReportFilter1
     */
    'offset'?: number;
    /**
     *
     * @type {number}
     * @memberof AgentumReportFilter1
     */
    'limit'?: number;
    /**
     *
     * @type {number}
     * @memberof AgentumReportFilter1
     */
    'skip'?: number;
    /**
     *
     * @type {GeoJsonFilterOrder}
     * @memberof AgentumReportFilter1
     */
    'order'?: GeoJsonFilterOrder;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof AgentumReportFilter1
     */
    'where'?: { [key: string]: any; };
    /**
     *
     * @type {AgentumReportFields}
     * @memberof AgentumReportFilter1
     */
    'fields'?: AgentumReportFields;
}
/**
 * @type AgentumReportLocation
 * @export
 */
export type AgentumReportLocation = AgentumReportLocationOneOf | Array<AgentumReportLocationOneOf>;

/**
 *
 * @export
 * @interface AgentumReportLocationOneOf
 */
export interface AgentumReportLocationOneOf {
    /**
     *
     * @type {number}
     * @memberof AgentumReportLocationOneOf
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof AgentumReportLocationOneOf
     */
    'longitude'?: number;
}
/**
 * (tsType: Omit<Partial<AgentumReport>, \'_id\' | \'creator_firstname\' | \'creator_lastname\' | \'creator_profession\'>, schemaOptions: { partial: true, exclude: [ \'_id\', \'creator_firstname\', \'creator_lastname\', \'creator_profession\' ] })
 * @export
 * @interface AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession
 */
export interface AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession
     */
    'mission'?: string;
    /**
     *
     * @type {string}
     * @memberof AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession
     */
    'project'?: string;
    /**
     *
     * @type {string}
     * @memberof AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession
     */
    'type'?: string;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession
     */
    'taskId'?: string;
}
/**
 * (tsType: AgentumReportWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface AgentumReportWithRelations
 */
export interface AgentumReportWithRelations {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof AgentumReportWithRelations
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof AgentumReportWithRelations
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof AgentumReportWithRelations
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof AgentumReportWithRelations
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof AgentumReportWithRelations
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof AgentumReportWithRelations
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof AgentumReportWithRelations
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof AgentumReportWithRelations
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof AgentumReportWithRelations
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof AgentumReportWithRelations
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof AgentumReportWithRelations
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof AgentumReportWithRelations
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof AgentumReportWithRelations
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof AgentumReportWithRelations
     */
    'taskId'?: string;
}
/**
 * (tsType: CabinetAndMotorCleaningModel, schemaOptions: { title: \'CabinetAndMotorCleaningModel\' })
 * @export
 * @interface CabinetAndMotorCleaningModel
 */
export interface CabinetAndMotorCleaningModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof CabinetAndMotorCleaningModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof CabinetAndMotorCleaningModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof CabinetAndMotorCleaningModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof CabinetAndMotorCleaningModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof CabinetAndMotorCleaningModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof CabinetAndMotorCleaningModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'group'?: CabinetAndMotorCleaningModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'status'?: CabinetAndMotorCleaningModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'subtype'?: CabinetAndMotorCleaningModelSubtypeEnum;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CabinetAndMotorCleaningModel
     */
    'cabinetCleaningPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CabinetAndMotorCleaningModel
     */
    'motorCleaningPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof CabinetAndMotorCleaningModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof CabinetAndMotorCleaningModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof CabinetAndMotorCleaningModel
     */
    'stepType'?: CabinetAndMotorCleaningModelStepTypeEnum;
}

export const CabinetAndMotorCleaningModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type CabinetAndMotorCleaningModelGroupEnum = typeof CabinetAndMotorCleaningModelGroupEnum[keyof typeof CabinetAndMotorCleaningModelGroupEnum];
export const CabinetAndMotorCleaningModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type CabinetAndMotorCleaningModelStatusEnum = typeof CabinetAndMotorCleaningModelStatusEnum[keyof typeof CabinetAndMotorCleaningModelStatusEnum];
export const CabinetAndMotorCleaningModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type CabinetAndMotorCleaningModelSubtypeEnum = typeof CabinetAndMotorCleaningModelSubtypeEnum[keyof typeof CabinetAndMotorCleaningModelSubtypeEnum];
export const CabinetAndMotorCleaningModelStepTypeEnum = {
    1: 'механическая',
    2: 'электрическая',
    MachineIdentification: 'machineIdentification',
    CurrentCheckUnderLoad: 'currentCheckUnderLoad',
    NoiseAndVibrationCheck: 'noiseAndVibrationCheck',
    CabinetAndMotorCleaning: 'cabinetAndMotorCleaning',
    HeaterZonesCheck: 'heaterZonesCheck',
    ElectricalConnectionsCheck: 'electricalConnectionsCheck',
    InjectionMoldingMachineMovementsCheck: 'injectionMoldingMachineMovementsCheck'
} as const;

export type CabinetAndMotorCleaningModelStepTypeEnum = typeof CabinetAndMotorCleaningModelStepTypeEnum[keyof typeof CabinetAndMotorCleaningModelStepTypeEnum];

/**
 * (tsType: ClampingUnitLeakCheckModel, schemaOptions: { title: \'ClampingUnitLeakCheckModel\' })
 * @export
 * @interface ClampingUnitLeakCheckModel
 */
export interface ClampingUnitLeakCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof ClampingUnitLeakCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof ClampingUnitLeakCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof ClampingUnitLeakCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof ClampingUnitLeakCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof ClampingUnitLeakCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof ClampingUnitLeakCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'group'?: ClampingUnitLeakCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'status'?: ClampingUnitLeakCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'subtype'?: ClampingUnitLeakCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof ClampingUnitLeakCheckModel
     */
    'clampingUnitLeakCheck'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof ClampingUnitLeakCheckModel
     */
    'clampingUnitCylindersPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof ClampingUnitLeakCheckModel
     */
    'clampingUnitSealsReplacedPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof ClampingUnitLeakCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof ClampingUnitLeakCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof ClampingUnitLeakCheckModel
     */
    'stepType'?: ClampingUnitLeakCheckModelStepTypeEnum;
}

export const ClampingUnitLeakCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type ClampingUnitLeakCheckModelGroupEnum = typeof ClampingUnitLeakCheckModelGroupEnum[keyof typeof ClampingUnitLeakCheckModelGroupEnum];
export const ClampingUnitLeakCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type ClampingUnitLeakCheckModelStatusEnum = typeof ClampingUnitLeakCheckModelStatusEnum[keyof typeof ClampingUnitLeakCheckModelStatusEnum];
export const ClampingUnitLeakCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type ClampingUnitLeakCheckModelSubtypeEnum = typeof ClampingUnitLeakCheckModelSubtypeEnum[keyof typeof ClampingUnitLeakCheckModelSubtypeEnum];
export const ClampingUnitLeakCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type ClampingUnitLeakCheckModelStepTypeEnum = typeof ClampingUnitLeakCheckModelStepTypeEnum[keyof typeof ClampingUnitLeakCheckModelStepTypeEnum];

/**
 * (tsType: CoolingCircuitFlushModel, schemaOptions: { title: \'CoolingCircuitFlushModel\' })
 * @export
 * @interface CoolingCircuitFlushModel
 */
export interface CoolingCircuitFlushModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof CoolingCircuitFlushModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof CoolingCircuitFlushModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof CoolingCircuitFlushModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof CoolingCircuitFlushModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof CoolingCircuitFlushModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof CoolingCircuitFlushModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'group'?: CoolingCircuitFlushModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'status'?: CoolingCircuitFlushModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'subtype'?: CoolingCircuitFlushModelSubtypeEnum;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CoolingCircuitFlushModel
     */
    'flushingSystemConnectionCoolingCircuitPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CoolingCircuitFlushModel
     */
    'flushingSystemConnectionCoolingRingPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof CoolingCircuitFlushModel
     */
    'noWaterFlow'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CoolingCircuitFlushModel
     */
    'insufficientWaterFlow'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CoolingCircuitFlushModel
     */
    'sufficientWaterFlow'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CoolingCircuitFlushModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof CoolingCircuitFlushModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof CoolingCircuitFlushModel
     */
    'stepType'?: CoolingCircuitFlushModelStepTypeEnum;
}

export const CoolingCircuitFlushModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type CoolingCircuitFlushModelGroupEnum = typeof CoolingCircuitFlushModelGroupEnum[keyof typeof CoolingCircuitFlushModelGroupEnum];
export const CoolingCircuitFlushModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type CoolingCircuitFlushModelStatusEnum = typeof CoolingCircuitFlushModelStatusEnum[keyof typeof CoolingCircuitFlushModelStatusEnum];
export const CoolingCircuitFlushModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type CoolingCircuitFlushModelSubtypeEnum = typeof CoolingCircuitFlushModelSubtypeEnum[keyof typeof CoolingCircuitFlushModelSubtypeEnum];
export const CoolingCircuitFlushModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type CoolingCircuitFlushModelStepTypeEnum = typeof CoolingCircuitFlushModelStepTypeEnum[keyof typeof CoolingCircuitFlushModelStepTypeEnum];

/**
 * (tsType: CoolingSystemCheckModel, schemaOptions: { title: \'CoolingSystemCheckModel\' })
 * @export
 * @interface CoolingSystemCheckModel
 */
export interface CoolingSystemCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof CoolingSystemCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof CoolingSystemCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof CoolingSystemCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof CoolingSystemCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof CoolingSystemCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof CoolingSystemCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'group'?: CoolingSystemCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'status'?: CoolingSystemCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'subtype'?: CoolingSystemCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionLeakCheckPoint1'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionPhotoPoint1'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionReplacementPhotoPoint1'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionLeakCheckPoint2'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionPhotoPoint2'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionReplacementPhotoPoint2'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionLeakCheckPoint3'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionPhotoPoint3'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionReplacementPhotoPoint3'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionLeakCheckPoint4'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionPhotoPoint4'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CoolingSystemCheckModel
     */
    'hoseConnectionReplacementPhotoPoint4'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof CoolingSystemCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof CoolingSystemCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof CoolingSystemCheckModel
     */
    'stepType'?: CoolingSystemCheckModelStepTypeEnum;
}

export const CoolingSystemCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type CoolingSystemCheckModelGroupEnum = typeof CoolingSystemCheckModelGroupEnum[keyof typeof CoolingSystemCheckModelGroupEnum];
export const CoolingSystemCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type CoolingSystemCheckModelStatusEnum = typeof CoolingSystemCheckModelStatusEnum[keyof typeof CoolingSystemCheckModelStatusEnum];
export const CoolingSystemCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type CoolingSystemCheckModelSubtypeEnum = typeof CoolingSystemCheckModelSubtypeEnum[keyof typeof CoolingSystemCheckModelSubtypeEnum];
export const CoolingSystemCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type CoolingSystemCheckModelStepTypeEnum = typeof CoolingSystemCheckModelStepTypeEnum[keyof typeof CoolingSystemCheckModelStepTypeEnum];

/**
 * (tsType: CurrentCheckUnderLoadModel, schemaOptions: { title: \'CurrentCheckUnderLoadModel\' })
 * @export
 * @interface CurrentCheckUnderLoadModel
 */
export interface CurrentCheckUnderLoadModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof CurrentCheckUnderLoadModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof CurrentCheckUnderLoadModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof CurrentCheckUnderLoadModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof CurrentCheckUnderLoadModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof CurrentCheckUnderLoadModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof CurrentCheckUnderLoadModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'group'?: CurrentCheckUnderLoadModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'status'?: CurrentCheckUnderLoadModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'subtype'?: CurrentCheckUnderLoadModelSubtypeEnum;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'currentReadingPoint1'?: string;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CurrentCheckUnderLoadModel
     */
    'currentDisplayPhotoPoint1'?: Array<PhotoUrl>;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'currentReadingPoint2'?: string;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CurrentCheckUnderLoadModel
     */
    'currentDisplayPhotoPoint2'?: Array<PhotoUrl>;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'currentReadingPoint3'?: string;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof CurrentCheckUnderLoadModel
     */
    'currentDisplayPhotoPoint3'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof CurrentCheckUnderLoadModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof CurrentCheckUnderLoadModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof CurrentCheckUnderLoadModel
     */
    'stepType'?: CurrentCheckUnderLoadModelStepTypeEnum;
}

export const CurrentCheckUnderLoadModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type CurrentCheckUnderLoadModelGroupEnum = typeof CurrentCheckUnderLoadModelGroupEnum[keyof typeof CurrentCheckUnderLoadModelGroupEnum];
export const CurrentCheckUnderLoadModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type CurrentCheckUnderLoadModelStatusEnum = typeof CurrentCheckUnderLoadModelStatusEnum[keyof typeof CurrentCheckUnderLoadModelStatusEnum];
export const CurrentCheckUnderLoadModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type CurrentCheckUnderLoadModelSubtypeEnum = typeof CurrentCheckUnderLoadModelSubtypeEnum[keyof typeof CurrentCheckUnderLoadModelSubtypeEnum];
export const CurrentCheckUnderLoadModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    CurrentCheckUnderLoad: 'currentCheckUnderLoad',
    NoiseAndVibrationCheck: 'noiseAndVibrationCheck',
    CabinetAndMotorCleaning: 'cabinetAndMotorCleaning',
    HeaterZonesCheck: 'heaterZonesCheck',
    ElectricalConnectionsCheck: 'electricalConnectionsCheck',
    InjectionMoldingMachineMovementsCheck: 'injectionMoldingMachineMovementsCheck'
} as const;

export type CurrentCheckUnderLoadModelStepTypeEnum = typeof CurrentCheckUnderLoadModelStepTypeEnum[keyof typeof CurrentCheckUnderLoadModelStepTypeEnum];

/**
 * (tsType: EjectionUnitLeakCheckModel, schemaOptions: { title: \'EjectionUnitLeakCheckModel\' })
 * @export
 * @interface EjectionUnitLeakCheckModel
 */
export interface EjectionUnitLeakCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof EjectionUnitLeakCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof EjectionUnitLeakCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof EjectionUnitLeakCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof EjectionUnitLeakCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof EjectionUnitLeakCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof EjectionUnitLeakCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'group'?: EjectionUnitLeakCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'status'?: EjectionUnitLeakCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'subtype'?: EjectionUnitLeakCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof EjectionUnitLeakCheckModel
     */
    'ejectionUnitLeakCheck'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof EjectionUnitLeakCheckModel
     */
    'ejectionUnitCylindersPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof EjectionUnitLeakCheckModel
     */
    'ejectionUnitSealsReplacedPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof EjectionUnitLeakCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof EjectionUnitLeakCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof EjectionUnitLeakCheckModel
     */
    'stepType'?: EjectionUnitLeakCheckModelStepTypeEnum;
}

export const EjectionUnitLeakCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type EjectionUnitLeakCheckModelGroupEnum = typeof EjectionUnitLeakCheckModelGroupEnum[keyof typeof EjectionUnitLeakCheckModelGroupEnum];
export const EjectionUnitLeakCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type EjectionUnitLeakCheckModelStatusEnum = typeof EjectionUnitLeakCheckModelStatusEnum[keyof typeof EjectionUnitLeakCheckModelStatusEnum];
export const EjectionUnitLeakCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type EjectionUnitLeakCheckModelSubtypeEnum = typeof EjectionUnitLeakCheckModelSubtypeEnum[keyof typeof EjectionUnitLeakCheckModelSubtypeEnum];
export const EjectionUnitLeakCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type EjectionUnitLeakCheckModelStepTypeEnum = typeof EjectionUnitLeakCheckModelStepTypeEnum[keyof typeof EjectionUnitLeakCheckModelStepTypeEnum];

/**
 * (tsType: ElectricalConnectionsCheckModel, schemaOptions: { title: \'ElectricalConnectionsCheckModel\' })
 * @export
 * @interface ElectricalConnectionsCheckModel
 */
export interface ElectricalConnectionsCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof ElectricalConnectionsCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof ElectricalConnectionsCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof ElectricalConnectionsCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof ElectricalConnectionsCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof ElectricalConnectionsCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof ElectricalConnectionsCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'group'?: ElectricalConnectionsCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'status'?: ElectricalConnectionsCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'subtype'?: ElectricalConnectionsCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof ElectricalConnectionsCheckModel
     */
    'circuitBreakerTightnessCheck'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ElectricalConnectionsCheckModel
     */
    'contactorTightnessCheck'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ElectricalConnectionsCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof ElectricalConnectionsCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof ElectricalConnectionsCheckModel
     */
    'stepType'?: ElectricalConnectionsCheckModelStepTypeEnum;
}

export const ElectricalConnectionsCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type ElectricalConnectionsCheckModelGroupEnum = typeof ElectricalConnectionsCheckModelGroupEnum[keyof typeof ElectricalConnectionsCheckModelGroupEnum];
export const ElectricalConnectionsCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type ElectricalConnectionsCheckModelStatusEnum = typeof ElectricalConnectionsCheckModelStatusEnum[keyof typeof ElectricalConnectionsCheckModelStatusEnum];
export const ElectricalConnectionsCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type ElectricalConnectionsCheckModelSubtypeEnum = typeof ElectricalConnectionsCheckModelSubtypeEnum[keyof typeof ElectricalConnectionsCheckModelSubtypeEnum];
export const ElectricalConnectionsCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    CurrentCheckUnderLoad: 'currentCheckUnderLoad',
    NoiseAndVibrationCheck: 'noiseAndVibrationCheck',
    CabinetAndMotorCleaning: 'cabinetAndMotorCleaning',
    HeaterZonesCheck: 'heaterZonesCheck',
    ElectricalConnectionsCheck: 'electricalConnectionsCheck',
    InjectionMoldingMachineMovementsCheck: 'injectionMoldingMachineMovementsCheck'
} as const;

export type ElectricalConnectionsCheckModelStepTypeEnum = typeof ElectricalConnectionsCheckModelStepTypeEnum[keyof typeof ElectricalConnectionsCheckModelStepTypeEnum];

/**
 * (tsType: EquipmentCleaningModel, schemaOptions: { title: \'EquipmentCleaningModel\' })
 * @export
 * @interface EquipmentCleaningModel
 */
export interface EquipmentCleaningModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof EquipmentCleaningModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof EquipmentCleaningModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof EquipmentCleaningModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof EquipmentCleaningModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof EquipmentCleaningModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof EquipmentCleaningModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'group'?: EquipmentCleaningModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'status'?: EquipmentCleaningModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'subtype'?: EquipmentCleaningModelSubtypeEnum;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof EquipmentCleaningModel
     */
    'cleanedOilPanPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof EquipmentCleaningModel
     */
    'cleanedToggleGuidesPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof EquipmentCleaningModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof EquipmentCleaningModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof EquipmentCleaningModel
     */
    'stepType'?: EquipmentCleaningModelStepTypeEnum;
}

export const EquipmentCleaningModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type EquipmentCleaningModelGroupEnum = typeof EquipmentCleaningModelGroupEnum[keyof typeof EquipmentCleaningModelGroupEnum];
export const EquipmentCleaningModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type EquipmentCleaningModelStatusEnum = typeof EquipmentCleaningModelStatusEnum[keyof typeof EquipmentCleaningModelStatusEnum];
export const EquipmentCleaningModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type EquipmentCleaningModelSubtypeEnum = typeof EquipmentCleaningModelSubtypeEnum[keyof typeof EquipmentCleaningModelSubtypeEnum];
export const EquipmentCleaningModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type EquipmentCleaningModelStepTypeEnum = typeof EquipmentCleaningModelStepTypeEnum[keyof typeof EquipmentCleaningModelStepTypeEnum];

/**
 * (tsType: FasteningCheckModel, schemaOptions: { title: \'FasteningCheckModel\' })
 * @export
 * @interface FasteningCheckModel
 */
export interface FasteningCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof FasteningCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FasteningCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof FasteningCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof FasteningCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof FasteningCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof FasteningCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'group'?: FasteningCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'status'?: FasteningCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'subtype'?: FasteningCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof FasteningCheckModel
     */
    'tailstockFormEngagementFastening'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof FasteningCheckModel
     */
    'oilScreensFastening'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof FasteningCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof FasteningCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof FasteningCheckModel
     */
    'stepType'?: FasteningCheckModelStepTypeEnum;
}

export const FasteningCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type FasteningCheckModelGroupEnum = typeof FasteningCheckModelGroupEnum[keyof typeof FasteningCheckModelGroupEnum];
export const FasteningCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type FasteningCheckModelStatusEnum = typeof FasteningCheckModelStatusEnum[keyof typeof FasteningCheckModelStatusEnum];
export const FasteningCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type FasteningCheckModelSubtypeEnum = typeof FasteningCheckModelSubtypeEnum[keyof typeof FasteningCheckModelSubtypeEnum];
export const FasteningCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type FasteningCheckModelStepTypeEnum = typeof FasteningCheckModelStepTypeEnum[keyof typeof FasteningCheckModelStepTypeEnum];

/**
 * (tsType: FineOilFilterCheckModel, schemaOptions: { title: \'FineOilFilterCheckModel\' })
 * @export
 * @interface FineOilFilterCheckModel
 */
export interface FineOilFilterCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof FineOilFilterCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FineOilFilterCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof FineOilFilterCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof FineOilFilterCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof FineOilFilterCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof FineOilFilterCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'group'?: FineOilFilterCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'status'?: FineOilFilterCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'subtype'?: FineOilFilterCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof FineOilFilterCheckModel
     */
    'fineOilFilterReplacementRequired'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof FineOilFilterCheckModel
     */
    'manometerReadingBeforeReplacementPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof FineOilFilterCheckModel
     */
    'manometerReadingAfterReplacementPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof FineOilFilterCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof FineOilFilterCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof FineOilFilterCheckModel
     */
    'stepType'?: FineOilFilterCheckModelStepTypeEnum;
}

export const FineOilFilterCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type FineOilFilterCheckModelGroupEnum = typeof FineOilFilterCheckModelGroupEnum[keyof typeof FineOilFilterCheckModelGroupEnum];
export const FineOilFilterCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type FineOilFilterCheckModelStatusEnum = typeof FineOilFilterCheckModelStatusEnum[keyof typeof FineOilFilterCheckModelStatusEnum];
export const FineOilFilterCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type FineOilFilterCheckModelSubtypeEnum = typeof FineOilFilterCheckModelSubtypeEnum[keyof typeof FineOilFilterCheckModelSubtypeEnum];
export const FineOilFilterCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type FineOilFilterCheckModelStepTypeEnum = typeof FineOilFilterCheckModelStepTypeEnum[keyof typeof FineOilFilterCheckModelStepTypeEnum];

/**
 *
 * @export
 * @interface GeoJson
 */
export interface GeoJson {
    /**
     *
     * @type {string}
     * @memberof GeoJson
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeoJson
     */
    'type': GeoJsonTypeEnum;
    /**
     *
     * @type {object}
     * @memberof GeoJson
     */
    'geometry': object;
    /**
     *
     * @type {object}
     * @memberof GeoJson
     */
    'properties'?: object;
}

export const GeoJsonTypeEnum = {
    Feature: 'Feature',
    Point: 'Point',
    MultiPoint: 'MultiPoint',
    LineString: 'LineString',
    MultiLineString: 'MultiLineString',
    Polygon: 'Polygon',
    MultiPolygon: 'MultiPolygon',
    GeometryCollection: 'GeometryCollection'
} as const;

export type GeoJsonTypeEnum = typeof GeoJsonTypeEnum[keyof typeof GeoJsonTypeEnum];

/**
 *
 * @export
 * @interface GeoJsonControllerFileUpload200Response
 */
export interface GeoJsonControllerFileUpload200Response {
    /**
     *
     * @type {string}
     * @memberof GeoJsonControllerFileUpload200Response
     */
    'message'?: string;
    /**
     *
     * @type {object}
     * @memberof GeoJsonControllerFileUpload200Response
     */
    'file'?: object;
    /**
     *
     * @type {number}
     * @memberof GeoJsonControllerFileUpload200Response
     */
    'objectCount'?: number;
}
/**
 * @type GeoJsonFields
 * @export
 */
export type GeoJsonFields = GeoJsonFieldsOneOf | Set<string>;

/**
 *
 * @export
 * @interface GeoJsonFieldsOneOf
 */
export interface GeoJsonFieldsOneOf {
    /**
     *
     * @type {boolean}
     * @memberof GeoJsonFieldsOneOf
     */
    '_id'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof GeoJsonFieldsOneOf
     */
    'type'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof GeoJsonFieldsOneOf
     */
    'geometry'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof GeoJsonFieldsOneOf
     */
    'properties'?: boolean;
}
/**
 *
 * @export
 * @interface GeoJsonFilter
 */
export interface GeoJsonFilter {
    /**
     *
     * @type {number}
     * @memberof GeoJsonFilter
     */
    'offset'?: number;
    /**
     *
     * @type {number}
     * @memberof GeoJsonFilter
     */
    'limit'?: number;
    /**
     *
     * @type {number}
     * @memberof GeoJsonFilter
     */
    'skip'?: number;
    /**
     *
     * @type {GeoJsonFilterOrder}
     * @memberof GeoJsonFilter
     */
    'order'?: GeoJsonFilterOrder;
    /**
     *
     * @type {GeoJsonFields}
     * @memberof GeoJsonFilter
     */
    'fields'?: GeoJsonFields;
}
/**
 *
 * @export
 * @interface GeoJsonFilter1
 */
export interface GeoJsonFilter1 {
    /**
     *
     * @type {number}
     * @memberof GeoJsonFilter1
     */
    'offset'?: number;
    /**
     *
     * @type {number}
     * @memberof GeoJsonFilter1
     */
    'limit'?: number;
    /**
     *
     * @type {number}
     * @memberof GeoJsonFilter1
     */
    'skip'?: number;
    /**
     *
     * @type {GeoJsonFilterOrder}
     * @memberof GeoJsonFilter1
     */
    'order'?: GeoJsonFilterOrder;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof GeoJsonFilter1
     */
    'where'?: { [key: string]: any; };
    /**
     *
     * @type {GeoJsonFields}
     * @memberof GeoJsonFilter1
     */
    'fields'?: GeoJsonFields;
}
/**
 * @type GeoJsonFilterOrder
 * @export
 */
export type GeoJsonFilterOrder = Array<string> | string;

/**
 * (tsType: Partial<GeoJson>, schemaOptions: { partial: true })
 * @export
 * @interface GeoJsonPartial
 */
export interface GeoJsonPartial {
    /**
     *
     * @type {string}
     * @memberof GeoJsonPartial
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeoJsonPartial
     */
    'type'?: GeoJsonPartialTypeEnum;
    /**
     *
     * @type {object}
     * @memberof GeoJsonPartial
     */
    'geometry'?: object;
    /**
     *
     * @type {object}
     * @memberof GeoJsonPartial
     */
    'properties'?: object;
}

export const GeoJsonPartialTypeEnum = {
    Feature: 'Feature',
    Point: 'Point',
    MultiPoint: 'MultiPoint',
    LineString: 'LineString',
    MultiLineString: 'MultiLineString',
    Polygon: 'Polygon',
    MultiPolygon: 'MultiPolygon',
    GeometryCollection: 'GeometryCollection'
} as const;

export type GeoJsonPartialTypeEnum = typeof GeoJsonPartialTypeEnum[keyof typeof GeoJsonPartialTypeEnum];

/**
 * (tsType: GeoJsonWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface GeoJsonWithRelations
 */
export interface GeoJsonWithRelations {
    /**
     *
     * @type {string}
     * @memberof GeoJsonWithRelations
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeoJsonWithRelations
     */
    'type': GeoJsonWithRelationsTypeEnum;
    /**
     *
     * @type {object}
     * @memberof GeoJsonWithRelations
     */
    'geometry': object;
    /**
     *
     * @type {object}
     * @memberof GeoJsonWithRelations
     */
    'properties'?: object;
}

export const GeoJsonWithRelationsTypeEnum = {
    Feature: 'Feature',
    Point: 'Point',
    MultiPoint: 'MultiPoint',
    LineString: 'LineString',
    MultiLineString: 'MultiLineString',
    Polygon: 'Polygon',
    MultiPolygon: 'MultiPolygon',
    GeometryCollection: 'GeometryCollection'
} as const;

export type GeoJsonWithRelationsTypeEnum = typeof GeoJsonWithRelationsTypeEnum[keyof typeof GeoJsonWithRelationsTypeEnum];

/**
 * (tsType: HeaterZonesCheckModel, schemaOptions: { title: \'HeaterZonesCheckModel\' })
 * @export
 * @interface HeaterZonesCheckModel
 */
export interface HeaterZonesCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof HeaterZonesCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof HeaterZonesCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof HeaterZonesCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof HeaterZonesCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof HeaterZonesCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof HeaterZonesCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'group'?: HeaterZonesCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'status'?: HeaterZonesCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'subtype'?: HeaterZonesCheckModelSubtypeEnum;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'currentReadingPoint1'?: string;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof HeaterZonesCheckModel
     */
    'currentDisplayPhotoPoint1'?: Array<PhotoUrl>;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'currentReadingPoint2'?: string;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof HeaterZonesCheckModel
     */
    'currentDisplayPhotoPoint2'?: Array<PhotoUrl>;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'currentReadingPoint3'?: string;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof HeaterZonesCheckModel
     */
    'currentDisplayPhotoPoint3'?: Array<PhotoUrl>;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'currentReadingPoint4'?: string;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof HeaterZonesCheckModel
     */
    'currentDisplayPhotoPoint4'?: Array<PhotoUrl>;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'currentReadingPoint5'?: string;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof HeaterZonesCheckModel
     */
    'currentDisplayPhotoPoint5'?: Array<PhotoUrl>;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'currentReadingPoint6'?: string;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof HeaterZonesCheckModel
     */
    'currentDisplayPhotoPoint6'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof HeaterZonesCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof HeaterZonesCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof HeaterZonesCheckModel
     */
    'stepType'?: HeaterZonesCheckModelStepTypeEnum;
}

export const HeaterZonesCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type HeaterZonesCheckModelGroupEnum = typeof HeaterZonesCheckModelGroupEnum[keyof typeof HeaterZonesCheckModelGroupEnum];
export const HeaterZonesCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type HeaterZonesCheckModelStatusEnum = typeof HeaterZonesCheckModelStatusEnum[keyof typeof HeaterZonesCheckModelStatusEnum];
export const HeaterZonesCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type HeaterZonesCheckModelSubtypeEnum = typeof HeaterZonesCheckModelSubtypeEnum[keyof typeof HeaterZonesCheckModelSubtypeEnum];
export const HeaterZonesCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    CurrentCheckUnderLoad: 'currentCheckUnderLoad',
    NoiseAndVibrationCheck: 'noiseAndVibrationCheck',
    CabinetAndMotorCleaning: 'cabinetAndMotorCleaning',
    HeaterZonesCheck: 'heaterZonesCheck',
    ElectricalConnectionsCheck: 'electricalConnectionsCheck',
    InjectionMoldingMachineMovementsCheck: 'injectionMoldingMachineMovementsCheck'
} as const;

export type HeaterZonesCheckModelStepTypeEnum = typeof HeaterZonesCheckModelStepTypeEnum[keyof typeof HeaterZonesCheckModelStepTypeEnum];

/**
 * (tsType: HydraulicConnectionCheckModel, schemaOptions: { title: \'HydraulicConnectionCheckModel\' })
 * @export
 * @interface HydraulicConnectionCheckModel
 */
export interface HydraulicConnectionCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof HydraulicConnectionCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof HydraulicConnectionCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof HydraulicConnectionCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof HydraulicConnectionCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof HydraulicConnectionCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof HydraulicConnectionCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'group'?: HydraulicConnectionCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'status'?: HydraulicConnectionCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'subtype'?: HydraulicConnectionCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof HydraulicConnectionCheckModel
     */
    'rearToggleMechanismCheckPoint1'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof HydraulicConnectionCheckModel
     */
    'rearToggleMechanismPhotoPoint1'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof HydraulicConnectionCheckModel
     */
    'rearToggleMechanismCheckPoint2'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof HydraulicConnectionCheckModel
     */
    'rearToggleMechanismPhotoPoint2'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof HydraulicConnectionCheckModel
     */
    'rearPlasticizationUnitCheckPoint1'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof HydraulicConnectionCheckModel
     */
    'rearPlasticizationUnitPhotoPoint1'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof HydraulicConnectionCheckModel
     */
    'rearPlasticizationUnitCheckPoint2'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof HydraulicConnectionCheckModel
     */
    'rearPlasticizationUnitPhotoPoint2'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof HydraulicConnectionCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof HydraulicConnectionCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof HydraulicConnectionCheckModel
     */
    'stepType'?: HydraulicConnectionCheckModelStepTypeEnum;
}

export const HydraulicConnectionCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type HydraulicConnectionCheckModelGroupEnum = typeof HydraulicConnectionCheckModelGroupEnum[keyof typeof HydraulicConnectionCheckModelGroupEnum];
export const HydraulicConnectionCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type HydraulicConnectionCheckModelStatusEnum = typeof HydraulicConnectionCheckModelStatusEnum[keyof typeof HydraulicConnectionCheckModelStatusEnum];
export const HydraulicConnectionCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type HydraulicConnectionCheckModelSubtypeEnum = typeof HydraulicConnectionCheckModelSubtypeEnum[keyof typeof HydraulicConnectionCheckModelSubtypeEnum];
export const HydraulicConnectionCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type HydraulicConnectionCheckModelStepTypeEnum = typeof HydraulicConnectionCheckModelStepTypeEnum[keyof typeof HydraulicConnectionCheckModelStepTypeEnum];

/**
 * (tsType: InjectionMoldingMachineMovementsModel, schemaOptions: { title: \'InjectionMoldingMachineMovementsModel\' })
 * @export
 * @interface InjectionMoldingMachineMovementsModel
 */
export interface InjectionMoldingMachineMovementsModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'group'?: InjectionMoldingMachineMovementsModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'status'?: InjectionMoldingMachineMovementsModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'subtype'?: InjectionMoldingMachineMovementsModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'movablePlateMovementCheck'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'movablePlateMovementDisplayPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'injectionUnitMovementCheck'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'injectionUnitMovementDisplayPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'ejectorMovementCheck'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'ejectorMovementDisplayPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'screwMovementCheck'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'screwMovementDisplayPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof InjectionMoldingMachineMovementsModel
     */
    'stepType'?: InjectionMoldingMachineMovementsModelStepTypeEnum;
}

export const InjectionMoldingMachineMovementsModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type InjectionMoldingMachineMovementsModelGroupEnum = typeof InjectionMoldingMachineMovementsModelGroupEnum[keyof typeof InjectionMoldingMachineMovementsModelGroupEnum];
export const InjectionMoldingMachineMovementsModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type InjectionMoldingMachineMovementsModelStatusEnum = typeof InjectionMoldingMachineMovementsModelStatusEnum[keyof typeof InjectionMoldingMachineMovementsModelStatusEnum];
export const InjectionMoldingMachineMovementsModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type InjectionMoldingMachineMovementsModelSubtypeEnum = typeof InjectionMoldingMachineMovementsModelSubtypeEnum[keyof typeof InjectionMoldingMachineMovementsModelSubtypeEnum];
export const InjectionMoldingMachineMovementsModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    CurrentCheckUnderLoad: 'currentCheckUnderLoad',
    NoiseAndVibrationCheck: 'noiseAndVibrationCheck',
    CabinetAndMotorCleaning: 'cabinetAndMotorCleaning',
    HeaterZonesCheck: 'heaterZonesCheck',
    ElectricalConnectionsCheck: 'electricalConnectionsCheck',
    InjectionMoldingMachineMovementsCheck: 'injectionMoldingMachineMovementsCheck'
} as const;

export type InjectionMoldingMachineMovementsModelStepTypeEnum = typeof InjectionMoldingMachineMovementsModelStepTypeEnum[keyof typeof InjectionMoldingMachineMovementsModelStepTypeEnum];

/**
 * (tsType: InjectionUnitLeakCheckModel, schemaOptions: { title: \'InjectionUnitLeakCheckModel\' })
 * @export
 * @interface InjectionUnitLeakCheckModel
 */
export interface InjectionUnitLeakCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof InjectionUnitLeakCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof InjectionUnitLeakCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof InjectionUnitLeakCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof InjectionUnitLeakCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof InjectionUnitLeakCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof InjectionUnitLeakCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'group'?: InjectionUnitLeakCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'status'?: InjectionUnitLeakCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'subtype'?: InjectionUnitLeakCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof InjectionUnitLeakCheckModel
     */
    'injectionUnitLeakCheck'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof InjectionUnitLeakCheckModel
     */
    'injectionUnitCylindersPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof InjectionUnitLeakCheckModel
     */
    'injectionUnitSealsReplacedPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof InjectionUnitLeakCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof InjectionUnitLeakCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof InjectionUnitLeakCheckModel
     */
    'stepType'?: InjectionUnitLeakCheckModelStepTypeEnum;
}

export const InjectionUnitLeakCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type InjectionUnitLeakCheckModelGroupEnum = typeof InjectionUnitLeakCheckModelGroupEnum[keyof typeof InjectionUnitLeakCheckModelGroupEnum];
export const InjectionUnitLeakCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type InjectionUnitLeakCheckModelStatusEnum = typeof InjectionUnitLeakCheckModelStatusEnum[keyof typeof InjectionUnitLeakCheckModelStatusEnum];
export const InjectionUnitLeakCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type InjectionUnitLeakCheckModelSubtypeEnum = typeof InjectionUnitLeakCheckModelSubtypeEnum[keyof typeof InjectionUnitLeakCheckModelSubtypeEnum];
export const InjectionUnitLeakCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type InjectionUnitLeakCheckModelStepTypeEnum = typeof InjectionUnitLeakCheckModelStepTypeEnum[keyof typeof InjectionUnitLeakCheckModelStepTypeEnum];

/**
 * (tsType: KeazStepModel, schemaOptions: { title: \'KeazStepModel\' })
 * @export
 * @interface KeazStepModel
 */
export interface KeazStepModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof KeazStepModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof KeazStepModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof KeazStepModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof KeazStepModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof KeazStepModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof KeazStepModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    'group'?: KeazStepModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    'status'?: KeazStepModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof KeazStepModel
     */
    'subtype'?: KeazStepModelSubtypeEnum;
}

export const KeazStepModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type KeazStepModelGroupEnum = typeof KeazStepModelGroupEnum[keyof typeof KeazStepModelGroupEnum];
export const KeazStepModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type KeazStepModelStatusEnum = typeof KeazStepModelStatusEnum[keyof typeof KeazStepModelStatusEnum];
export const KeazStepModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type KeazStepModelSubtypeEnum = typeof KeazStepModelSubtypeEnum[keyof typeof KeazStepModelSubtypeEnum];

/**
 * (tsType: KeazTaskModel, schemaOptions: { title: \'KeazTaskModel\' })
 * @export
 * @interface KeazTaskModel
 */
export interface KeazTaskModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof KeazTaskModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof KeazTaskModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof KeazTaskModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof KeazTaskModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof KeazTaskModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'taskId'?: string;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'orderId': string;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'number'?: string;
    /**
     *
     * @type {number}
     * @memberof KeazTaskModel
     */
    'date'?: number;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'userId'?: string;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'equipment': string;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'status': KeazTaskModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'typeRepair': KeazTaskModelTypeRepairEnum;
    /**
     *
     * @type {string}
     * @memberof KeazTaskModel
     */
    'subtype'?: KeazTaskModelSubtypeEnum;
}

export const KeazTaskModelStatusEnum = {
    1: 'Выполняется',
    2: 'К выполнению'
} as const;

export type KeazTaskModelStatusEnum = typeof KeazTaskModelStatusEnum[keyof typeof KeazTaskModelStatusEnum];
export const KeazTaskModelTypeRepairEnum = {
    1: 'Техническое обслуживание (ежегодное)'
} as const;

export type KeazTaskModelTypeRepairEnum = typeof KeazTaskModelTypeRepairEnum[keyof typeof KeazTaskModelTypeRepairEnum];
export const KeazTaskModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type KeazTaskModelSubtypeEnum = typeof KeazTaskModelSubtypeEnum[keyof typeof KeazTaskModelSubtypeEnum];

/**
 * (tsType: LimitSwitchCheckModel, schemaOptions: { title: \'LimitSwitchCheckModel\' })
 * @export
 * @interface LimitSwitchCheckModel
 */
export interface LimitSwitchCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof LimitSwitchCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof LimitSwitchCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof LimitSwitchCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof LimitSwitchCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof LimitSwitchCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof LimitSwitchCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'group'?: LimitSwitchCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'status'?: LimitSwitchCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'subtype'?: LimitSwitchCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof LimitSwitchCheckModel
     */
    'mainScreenLimitSwitchCheck'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof LimitSwitchCheckModel
     */
    'mainScreenLimitSwitchDisplayPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof LimitSwitchCheckModel
     */
    'nozzleScreenLimitSwitchCheck'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof LimitSwitchCheckModel
     */
    'nozzleScreenLimitSwitchDisplayPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof LimitSwitchCheckModel
     */
    'guideRailsCheck'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof LimitSwitchCheckModel
     */
    'guideRailsDisplayPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof LimitSwitchCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof LimitSwitchCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof LimitSwitchCheckModel
     */
    'stepType'?: LimitSwitchCheckModelStepTypeEnum;
}

export const LimitSwitchCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type LimitSwitchCheckModelGroupEnum = typeof LimitSwitchCheckModelGroupEnum[keyof typeof LimitSwitchCheckModelGroupEnum];
export const LimitSwitchCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type LimitSwitchCheckModelStatusEnum = typeof LimitSwitchCheckModelStatusEnum[keyof typeof LimitSwitchCheckModelStatusEnum];
export const LimitSwitchCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type LimitSwitchCheckModelSubtypeEnum = typeof LimitSwitchCheckModelSubtypeEnum[keyof typeof LimitSwitchCheckModelSubtypeEnum];
export const LimitSwitchCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type LimitSwitchCheckModelStepTypeEnum = typeof LimitSwitchCheckModelStepTypeEnum[keyof typeof LimitSwitchCheckModelStepTypeEnum];

/**
 *
 * @export
 * @interface LoopbackCount
 */
export interface LoopbackCount {
    /**
     *
     * @type {number}
     * @memberof LoopbackCount
     */
    'count'?: number;
}
/**
 * (tsType: LubricationSystemCheckModel, schemaOptions: { title: \'LubricationSystemCheckModel\' })
 * @export
 * @interface LubricationSystemCheckModel
 */
export interface LubricationSystemCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof LubricationSystemCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof LubricationSystemCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof LubricationSystemCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof LubricationSystemCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof LubricationSystemCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof LubricationSystemCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'group'?: LubricationSystemCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'status'?: LubricationSystemCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'subtype'?: LubricationSystemCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof LubricationSystemCheckModel
     */
    'tubeDamageCheckPoint1'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof LubricationSystemCheckModel
     */
    'tubePhotoPoint1'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof LubricationSystemCheckModel
     */
    'tubeDamageCheckPoint2'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof LubricationSystemCheckModel
     */
    'tubePhotoPoint2'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof LubricationSystemCheckModel
     */
    'lubricantRefillCompleted'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LubricationSystemCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof LubricationSystemCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof LubricationSystemCheckModel
     */
    'stepType'?: LubricationSystemCheckModelStepTypeEnum;
}

export const LubricationSystemCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type LubricationSystemCheckModelGroupEnum = typeof LubricationSystemCheckModelGroupEnum[keyof typeof LubricationSystemCheckModelGroupEnum];
export const LubricationSystemCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type LubricationSystemCheckModelStatusEnum = typeof LubricationSystemCheckModelStatusEnum[keyof typeof LubricationSystemCheckModelStatusEnum];
export const LubricationSystemCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type LubricationSystemCheckModelSubtypeEnum = typeof LubricationSystemCheckModelSubtypeEnum[keyof typeof LubricationSystemCheckModelSubtypeEnum];
export const LubricationSystemCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type LubricationSystemCheckModelStepTypeEnum = typeof LubricationSystemCheckModelStepTypeEnum[keyof typeof LubricationSystemCheckModelStepTypeEnum];

/**
 * (tsType: MachineIdentificationModel, schemaOptions: { title: \'MachineIdentificationModel\' })
 * @export
 * @interface MachineIdentificationModel
 */
export interface MachineIdentificationModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof MachineIdentificationModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof MachineIdentificationModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof MachineIdentificationModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof MachineIdentificationModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof MachineIdentificationModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof MachineIdentificationModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'group'?: MachineIdentificationModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'status'?: MachineIdentificationModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'subtype'?: MachineIdentificationModelSubtypeEnum;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'serialNumber'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof MachineIdentificationModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof MachineIdentificationModel
     */
    'stepType'?: MachineIdentificationModelStepTypeEnum;
}

export const MachineIdentificationModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type MachineIdentificationModelGroupEnum = typeof MachineIdentificationModelGroupEnum[keyof typeof MachineIdentificationModelGroupEnum];
export const MachineIdentificationModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type MachineIdentificationModelStatusEnum = typeof MachineIdentificationModelStatusEnum[keyof typeof MachineIdentificationModelStatusEnum];
export const MachineIdentificationModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type MachineIdentificationModelSubtypeEnum = typeof MachineIdentificationModelSubtypeEnum[keyof typeof MachineIdentificationModelSubtypeEnum];
export const MachineIdentificationModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type MachineIdentificationModelStepTypeEnum = typeof MachineIdentificationModelStepTypeEnum[keyof typeof MachineIdentificationModelStepTypeEnum];

/**
 *
 * @export
 * @interface MissionsControllerGetMissionTemplate200Response
 */
export interface MissionsControllerGetMissionTemplate200Response {
    /**
     *
     * @type {string}
     * @memberof MissionsControllerGetMissionTemplate200Response
     */
    'content'?: string;
    /**
     *
     * @type {string}
     * @memberof MissionsControllerGetMissionTemplate200Response
     */
    'blank'?: string;
}
/**
 * @type MissionsFields
 * @export
 */
export type MissionsFields = MissionsFieldsOneOf | Set<string>;

/**
 *
 * @export
 * @interface MissionsFieldsOneOf
 */
export interface MissionsFieldsOneOf {
    [key: string]: any;

    /**
     *
     * @type {boolean}
     * @memberof MissionsFieldsOneOf
     */
    '_id'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof MissionsFieldsOneOf
     */
    'mission'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof MissionsFieldsOneOf
     */
    'mission_name'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof MissionsFieldsOneOf
     */
    'projects'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof MissionsFieldsOneOf
     */
    'timestamp'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof MissionsFieldsOneOf
     */
    'panorama'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof MissionsFieldsOneOf
     */
    'admin_blank'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof MissionsFieldsOneOf
     */
    'platforms'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof MissionsFieldsOneOf
     */
    'icon'?: boolean;
}
/**
 *
 * @export
 * @interface MissionsFilter
 */
export interface MissionsFilter {
    /**
     *
     * @type {number}
     * @memberof MissionsFilter
     */
    'offset'?: number;
    /**
     *
     * @type {number}
     * @memberof MissionsFilter
     */
    'limit'?: number;
    /**
     *
     * @type {number}
     * @memberof MissionsFilter
     */
    'skip'?: number;
    /**
     *
     * @type {GeoJsonFilterOrder}
     * @memberof MissionsFilter
     */
    'order'?: GeoJsonFilterOrder;
    /**
     *
     * @type {MissionsFields}
     * @memberof MissionsFilter
     */
    'fields'?: MissionsFields;
}
/**
 *
 * @export
 * @interface MissionsFilter1
 */
export interface MissionsFilter1 {
    /**
     *
     * @type {number}
     * @memberof MissionsFilter1
     */
    'offset'?: number;
    /**
     *
     * @type {number}
     * @memberof MissionsFilter1
     */
    'limit'?: number;
    /**
     *
     * @type {number}
     * @memberof MissionsFilter1
     */
    'skip'?: number;
    /**
     *
     * @type {GeoJsonFilterOrder}
     * @memberof MissionsFilter1
     */
    'order'?: GeoJsonFilterOrder;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof MissionsFilter1
     */
    'where'?: { [key: string]: any; };
    /**
     *
     * @type {MissionsFields}
     * @memberof MissionsFilter1
     */
    'fields'?: MissionsFields;
}
/**
 * (tsType: MissionsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface MissionsWithRelations
 */
export interface MissionsWithRelations {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof MissionsWithRelations
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof MissionsWithRelations
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof MissionsWithRelations
     */
    'mission_name': string;
    /**
     *
     * @type {Array<object>}
     * @memberof MissionsWithRelations
     */
    'projects': Array<object>;
    /**
     *
     * @type {number}
     * @memberof MissionsWithRelations
     */
    'timestamp': number;
    /**
     *
     * @type {boolean}
     * @memberof MissionsWithRelations
     */
    'panorama'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof MissionsWithRelations
     */
    'admin_blank'?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof MissionsWithRelations
     */
    'platforms': Array<string>;
    /**
     *
     * @type {string}
     * @memberof MissionsWithRelations
     */
    'icon'?: string;
}
/**
 * (tsType: Omit<AgentumReport, \'_id\' | \'creator_firstname\' | \'creator_lastname\' | \'creator_profession\'>, schemaOptions: { title: \'NewAgentumReport\', exclude: [ \'_id\', \'creator_firstname\', \'creator_lastname\', \'creator_profession\' ] })
 * @export
 * @interface NewAgentumReport
 */
export interface NewAgentumReport {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof NewAgentumReport
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof NewAgentumReport
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof NewAgentumReport
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof NewAgentumReport
     */
    'type': string;
    /**
     *
     * @type {boolean}
     * @memberof NewAgentumReport
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof NewAgentumReport
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof NewAgentumReport
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof NewAgentumReport
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof NewAgentumReport
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof NewAgentumReport
     */
    'taskId'?: string;
}
/**
 * (tsType: GeoJson, schemaOptions: { title: \'NewGeoJson\' })
 * @export
 * @interface NewGeoJson
 */
export interface NewGeoJson {
    /**
     *
     * @type {string}
     * @memberof NewGeoJson
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof NewGeoJson
     */
    'type': NewGeoJsonTypeEnum;
    /**
     *
     * @type {object}
     * @memberof NewGeoJson
     */
    'geometry': object;
    /**
     *
     * @type {object}
     * @memberof NewGeoJson
     */
    'properties'?: object;
}

export const NewGeoJsonTypeEnum = {
    Feature: 'Feature',
    Point: 'Point',
    MultiPoint: 'MultiPoint',
    LineString: 'LineString',
    MultiLineString: 'MultiLineString',
    Polygon: 'Polygon',
    MultiPolygon: 'MultiPolygon',
    GeometryCollection: 'GeometryCollection'
} as const;

export type NewGeoJsonTypeEnum = typeof NewGeoJsonTypeEnum[keyof typeof NewGeoJsonTypeEnum];

/**
 * (tsType: NoiseAndVibrationCheckModel, schemaOptions: { title: \'NoiseAndVibrationCheckModel\' })
 * @export
 * @interface NoiseAndVibrationCheckModel
 */
export interface NoiseAndVibrationCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof NoiseAndVibrationCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof NoiseAndVibrationCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof NoiseAndVibrationCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof NoiseAndVibrationCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof NoiseAndVibrationCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof NoiseAndVibrationCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'group'?: NoiseAndVibrationCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'status'?: NoiseAndVibrationCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'subtype'?: NoiseAndVibrationCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof NoiseAndVibrationCheckModel
     */
    'vibrationCheck'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof NoiseAndVibrationCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof NoiseAndVibrationCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof NoiseAndVibrationCheckModel
     */
    'stepType'?: NoiseAndVibrationCheckModelStepTypeEnum;
}

export const NoiseAndVibrationCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type NoiseAndVibrationCheckModelGroupEnum = typeof NoiseAndVibrationCheckModelGroupEnum[keyof typeof NoiseAndVibrationCheckModelGroupEnum];
export const NoiseAndVibrationCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type NoiseAndVibrationCheckModelStatusEnum = typeof NoiseAndVibrationCheckModelStatusEnum[keyof typeof NoiseAndVibrationCheckModelStatusEnum];
export const NoiseAndVibrationCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type NoiseAndVibrationCheckModelSubtypeEnum = typeof NoiseAndVibrationCheckModelSubtypeEnum[keyof typeof NoiseAndVibrationCheckModelSubtypeEnum];
export const NoiseAndVibrationCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    CurrentCheckUnderLoad: 'currentCheckUnderLoad',
    NoiseAndVibrationCheck: 'noiseAndVibrationCheck',
    CabinetAndMotorCleaning: 'cabinetAndMotorCleaning',
    HeaterZonesCheck: 'heaterZonesCheck',
    ElectricalConnectionsCheck: 'electricalConnectionsCheck',
    InjectionMoldingMachineMovementsCheck: 'injectionMoldingMachineMovementsCheck'
} as const;

export type NoiseAndVibrationCheckModelStepTypeEnum = typeof NoiseAndVibrationCheckModelStepTypeEnum[keyof typeof NoiseAndVibrationCheckModelStepTypeEnum];

/**
 * (tsType: OilChangeAndFilterCleaningModel, schemaOptions: { title: \'OilChangeAndFilterCleaningModel\' })
 * @export
 * @interface OilChangeAndFilterCleaningModel
 */
export interface OilChangeAndFilterCleaningModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'group'?: OilChangeAndFilterCleaningModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'status'?: OilChangeAndFilterCleaningModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'subtype'?: OilChangeAndFilterCleaningModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'oilReplacementRequired'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'oilConditionPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'oilConditionAfterReplacementPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'suctionFilterCleaningRequired'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'suctionFilterBeforeCleaningPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'suctionFilterAfterCleaningPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'fillingFilterCleaningRequired'?: boolean;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'fillingFilterBeforeCleaningPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'fillingFilterAfterCleaningPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof OilChangeAndFilterCleaningModel
     */
    'stepType'?: OilChangeAndFilterCleaningModelStepTypeEnum;
}

export const OilChangeAndFilterCleaningModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type OilChangeAndFilterCleaningModelGroupEnum = typeof OilChangeAndFilterCleaningModelGroupEnum[keyof typeof OilChangeAndFilterCleaningModelGroupEnum];
export const OilChangeAndFilterCleaningModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type OilChangeAndFilterCleaningModelStatusEnum = typeof OilChangeAndFilterCleaningModelStatusEnum[keyof typeof OilChangeAndFilterCleaningModelStatusEnum];
export const OilChangeAndFilterCleaningModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type OilChangeAndFilterCleaningModelSubtypeEnum = typeof OilChangeAndFilterCleaningModelSubtypeEnum[keyof typeof OilChangeAndFilterCleaningModelSubtypeEnum];
export const OilChangeAndFilterCleaningModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type OilChangeAndFilterCleaningModelStepTypeEnum = typeof OilChangeAndFilterCleaningModelStepTypeEnum[keyof typeof OilChangeAndFilterCleaningModelStepTypeEnum];

/**
 *
 * @export
 * @interface PhotoUrl
 */
export interface PhotoUrl {
    /**
     * URL to the original photo
     * @type {string}
     * @memberof PhotoUrl
     */
    'original': string;
    /**
     * URL to the miniature version of the photo
     * @type {string}
     * @memberof PhotoUrl
     */
    'miniature': string;
}
/**
 *
 * @export
 * @interface PingResponse
 */
export interface PingResponse {
    /**
     *
     * @type {string}
     * @memberof PingResponse
     */
    'greeting'?: string;
    /**
     *
     * @type {string}
     * @memberof PingResponse
     */
    'date'?: string;
    /**
     *
     * @type {string}
     * @memberof PingResponse
     */
    'url'?: string;
    /**
     *
     * @type {PingResponseHeaders}
     * @memberof PingResponse
     */
    'headers'?: PingResponseHeaders;
}
/**
 *
 * @export
 * @interface PingResponseHeaders
 */
export interface PingResponseHeaders {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof PingResponseHeaders
     */
    'Content-Type'?: string;
}
/**
 * (tsType: PressureAndPumpCheckModel, schemaOptions: { title: \'PressureAndPumpCheckModel\' })
 * @export
 * @interface PressureAndPumpCheckModel
 */
export interface PressureAndPumpCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof PressureAndPumpCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof PressureAndPumpCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof PressureAndPumpCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof PressureAndPumpCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof PressureAndPumpCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof PressureAndPumpCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'group'?: PressureAndPumpCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'status'?: PressureAndPumpCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'subtype'?: PressureAndPumpCheckModelSubtypeEnum;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'manometerReadings'?: string;
    /**
     *
     * @type {Array<PhotoUrl>}
     * @memberof PressureAndPumpCheckModel
     */
    'manometerDisplayPhoto'?: Array<PhotoUrl>;
    /**
     *
     * @type {boolean}
     * @memberof PressureAndPumpCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PressureAndPumpCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof PressureAndPumpCheckModel
     */
    'stepType'?: PressureAndPumpCheckModelStepTypeEnum;
}

export const PressureAndPumpCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type PressureAndPumpCheckModelGroupEnum = typeof PressureAndPumpCheckModelGroupEnum[keyof typeof PressureAndPumpCheckModelGroupEnum];
export const PressureAndPumpCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type PressureAndPumpCheckModelStatusEnum = typeof PressureAndPumpCheckModelStatusEnum[keyof typeof PressureAndPumpCheckModelStatusEnum];
export const PressureAndPumpCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type PressureAndPumpCheckModelSubtypeEnum = typeof PressureAndPumpCheckModelSubtypeEnum[keyof typeof PressureAndPumpCheckModelSubtypeEnum];
export const PressureAndPumpCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type PressureAndPumpCheckModelStepTypeEnum = typeof PressureAndPumpCheckModelStepTypeEnum[keyof typeof PressureAndPumpCheckModelStepTypeEnum];

/**
 * (tsType: SafetyCheckModel, schemaOptions: { title: \'SafetyCheckModel\' })
 * @export
 * @interface SafetyCheckModel
 */
export interface SafetyCheckModel {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'origin'?: string;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'mission': string;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'project': string;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'type': string;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'creator_firstname': string;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'creator_lastname': string;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'creator_profession': string;
    /**
     *
     * @type {boolean}
     * @memberof SafetyCheckModel
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof SafetyCheckModel
     */
    'timestamp'?: number;
    /**
     *
     * @type {AgentumReportLocation}
     * @memberof SafetyCheckModel
     */
    'location'?: AgentumReportLocation;
    /**
     *
     * @type {number}
     * @memberof SafetyCheckModel
     */
    'latitude'?: number;
    /**
     *
     * @type {number}
     * @memberof SafetyCheckModel
     */
    'longitude'?: number;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'taskId'?: string;
    /**
     *
     * @type {number}
     * @memberof SafetyCheckModel
     */
    'stepNumber'?: number;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'group'?: SafetyCheckModelGroupEnum;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'status'?: SafetyCheckModelStatusEnum;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'subtype'?: SafetyCheckModelSubtypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof SafetyCheckModel
     */
    'frontClampingUnitDoorChecked'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof SafetyCheckModel
     */
    'rearClampingUnitDoorChecked'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof SafetyCheckModel
     */
    'nozzleGuardChecked'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof SafetyCheckModel
     */
    'deviations'?: boolean;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'deviationsComment'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof SafetyCheckModel
     */
    'timeNorm'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof SafetyCheckModel
     */
    'stepType'?: SafetyCheckModelStepTypeEnum;
}

export const SafetyCheckModelGroupEnum = {
    1: 'механическая',
    2: 'электрическая'
} as const;

export type SafetyCheckModelGroupEnum = typeof SafetyCheckModelGroupEnum[keyof typeof SafetyCheckModelGroupEnum];
export const SafetyCheckModelStatusEnum = {
    1: 'Не обследован',
    2: 'В работе',
    3: 'Ожидает проверки',
    4: 'Принят',
    5: 'Отклонен'
} as const;

export type SafetyCheckModelStatusEnum = typeof SafetyCheckModelStatusEnum[keyof typeof SafetyCheckModelStatusEnum];
export const SafetyCheckModelSubtypeEnum = {
    Task: 'task',
    Step: 'step'
} as const;

export type SafetyCheckModelSubtypeEnum = typeof SafetyCheckModelSubtypeEnum[keyof typeof SafetyCheckModelSubtypeEnum];
export const SafetyCheckModelStepTypeEnum = {
    MachineIdentification: 'machineIdentification',
    HydraulicConnectionCheck: 'hydraulicConnectionCheck',
    PressureAndPumpCheck: 'pressureAndPumpCheck',
    SafetyCheck: 'safetyCheck',
    LimitSwitchCheck: 'limitSwitchCheck',
    InjectionUnitLeakCheck: 'injectionUnitLeakCheck',
    ClampingUnitLeakCheck: 'clampingUnitLeakCheck',
    EjectionUnitLeakCheck: 'ejectionUnitLeakCheck',
    FasteningCheck: 'fasteningCheck',
    CoolingSystemCheck: 'coolingSystemCheck',
    CoolingCircuitFlush: 'coolingCircuitFlush',
    OilChangeAndFilterCleaning: 'oilChangeAndFilterCleaning',
    FineOilFilterCheck: 'fineOilFilterCheck',
    LubricationSystemCheck: 'lubricationSystemCheck',
    EquipmentCleaning: 'equipmentCleaning'
} as const;

export type SafetyCheckModelStepTypeEnum = typeof SafetyCheckModelStepTypeEnum[keyof typeof SafetyCheckModelStepTypeEnum];

/**
 *
 * @export
 * @interface UsersControllerLogin200Response
 */
export interface UsersControllerLogin200Response {
    /**
     *
     * @type {string}
     * @memberof UsersControllerLogin200Response
     */
    'accessToken'?: string;
    /**
     *
     * @type {string}
     * @memberof UsersControllerLogin200Response
     */
    'refreshToken'?: string;
}
/**
 *
 * @export
 * @interface UsersControllerLoginRequest
 */
export interface UsersControllerLoginRequest {
    /**
     *
     * @type {string}
     * @memberof UsersControllerLoginRequest
     */
    'username': string;
    /**
     *
     * @type {string}
     * @memberof UsersControllerLoginRequest
     */
    'password': string;
}
/**
 *
 * @export
 * @interface UsersControllerRefresh200Response
 */
export interface UsersControllerRefresh200Response {
    /**
     *
     * @type {object}
     * @memberof UsersControllerRefresh200Response
     */
    'accessToken'?: string;
}
/**
 *
 * @export
 * @interface UsersControllerRefreshRequest
 */
export interface UsersControllerRefreshRequest {
    /**
     *
     * @type {string}
     * @memberof UsersControllerRefreshRequest
     */
    'refreshToken': string;
}
/**
 * @type UsersFields
 * @export
 */
export type UsersFields = Set<string> | UsersFieldsOneOf;

/**
 *
 * @export
 * @interface UsersFieldsOneOf
 */
export interface UsersFieldsOneOf {
    /**
     *
     * @type {boolean}
     * @memberof UsersFieldsOneOf
     */
    '_id'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UsersFieldsOneOf
     */
    'access'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UsersFieldsOneOf
     */
    'password'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UsersFieldsOneOf
     */
    'username'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UsersFieldsOneOf
     */
    'missions'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UsersFieldsOneOf
     */
    'firstname'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UsersFieldsOneOf
     */
    'lastname'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UsersFieldsOneOf
     */
    'profession'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UsersFieldsOneOf
     */
    'externalId'?: boolean;
}
/**
 *
 * @export
 * @interface UsersFilter
 */
export interface UsersFilter {
    /**
     *
     * @type {number}
     * @memberof UsersFilter
     */
    'offset'?: number;
    /**
     *
     * @type {number}
     * @memberof UsersFilter
     */
    'limit'?: number;
    /**
     *
     * @type {number}
     * @memberof UsersFilter
     */
    'skip'?: number;
    /**
     *
     * @type {GeoJsonFilterOrder}
     * @memberof UsersFilter
     */
    'order'?: GeoJsonFilterOrder;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof UsersFilter
     */
    'where'?: { [key: string]: any; };
    /**
     *
     * @type {UsersFields}
     * @memberof UsersFilter
     */
    'fields'?: UsersFields;
}
/**
 * (tsType: UsersWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface UsersWithRelations
 */
export interface UsersWithRelations {
    /**
     *
     * @type {string}
     * @memberof UsersWithRelations
     */
    '_id'?: string;
    /**
     *
     * @type {string}
     * @memberof UsersWithRelations
     */
    'access': string;
    /**
     *
     * @type {string}
     * @memberof UsersWithRelations
     */
    'password': string;
    /**
     *
     * @type {string}
     * @memberof UsersWithRelations
     */
    'username': string;
    /**
     *
     * @type {Array<object>}
     * @memberof UsersWithRelations
     */
    'missions': Array<object>;
    /**
     *
     * @type {string}
     * @memberof UsersWithRelations
     */
    'firstname'?: string;
    /**
     *
     * @type {string}
     * @memberof UsersWithRelations
     */
    'lastname'?: string;
    /**
     *
     * @type {string}
     * @memberof UsersWithRelations
     */
    'profession'?: string;
    /**
     *
     * @type {string}
     * @memberof UsersWithRelations
     */
    'externalId'?: string;
}

/**
 * AgentumHandbookControllerApi - axios parameter creator
 * @export
 */
export const AgentumHandbookControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} [suffix]
         * @param {AgentumHandbookFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumHandbookControllerFind: async (missionId: string, project: string, suffix?: string, filter?: AgentumHandbookFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionId' is not null or undefined
            assertParamExists('agentumHandbookControllerFind', 'missionId', missionId)
            // verify required parameter 'project' is not null or undefined
            assertParamExists('agentumHandbookControllerFind', 'project', project)
            const localVarPath = `/handbook/{missionId}/projects/{project}`
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)))
                .replace(`{${"project"}}`, encodeURIComponent(String(project)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (suffix !== undefined) {
                localVarQueryParameter['suffix'] = suffix;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentumHandbookControllerApi - functional programming interface
 * @export
 */
export const AgentumHandbookControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentumHandbookControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} [suffix]
         * @param {AgentumHandbookFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentumHandbookControllerFind(missionId: string, project: string, suffix?: string, filter?: AgentumHandbookFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentumHandbookWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agentumHandbookControllerFind(missionId, project, suffix, filter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentumHandbookControllerApi.agentumHandbookControllerFind']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AgentumHandbookControllerApi - factory interface
 * @export
 */
export const AgentumHandbookControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentumHandbookControllerApiFp(configuration)
    return {
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} [suffix]
         * @param {AgentumHandbookFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumHandbookControllerFind(missionId: string, project: string, suffix?: string, filter?: AgentumHandbookFilter, options?: any): AxiosPromise<AgentumHandbookWithRelations> {
            return localVarFp.agentumHandbookControllerFind(missionId, project, suffix, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentumHandbookControllerApi - object-oriented interface
 * @export
 * @class AgentumHandbookControllerApi
 * @extends {BaseAPI}
 */
export class AgentumHandbookControllerApi extends BaseAPI {
    /**
     *
     * @param {string} missionId
     * @param {string} project
     * @param {string} [suffix]
     * @param {AgentumHandbookFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentumHandbookControllerApi
     */
    public agentumHandbookControllerFind(missionId: string, project: string, suffix?: string, filter?: AgentumHandbookFilter, options?: RawAxiosRequestConfig) {
        return AgentumHandbookControllerApiFp(this.configuration).agentumHandbookControllerFind(missionId, project, suffix, filter, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AgentumReportControllerApi - axios parameter creator
 * @export
 */
export const AgentumReportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {{ [key: string]: any; }} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerCount: async (missionId: string, project: string, where?: { [key: string]: any; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionId' is not null or undefined
            assertParamExists('agentumReportControllerCount', 'missionId', missionId)
            // verify required parameter 'project' is not null or undefined
            assertParamExists('agentumReportControllerCount', 'project', project)
            const localVarPath = `/missions/{missionId}/projects/{project}/reports/count`
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)))
                .replace(`{${"project"}}`, encodeURIComponent(String(project)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {NewAgentumReport} [newAgentumReport]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerCreate: async (missionId: string, project: string, newAgentumReport?: NewAgentumReport, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionId' is not null or undefined
            assertParamExists('agentumReportControllerCreate', 'missionId', missionId)
            // verify required parameter 'project' is not null or undefined
            assertParamExists('agentumReportControllerCreate', 'project', project)
            const localVarPath = `/missions/{missionId}/projects/{project}/reports`
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)))
                .replace(`{${"project"}}`, encodeURIComponent(String(project)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAgentumReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerDeleteById: async (missionId: string, project: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionId' is not null or undefined
            assertParamExists('agentumReportControllerDeleteById', 'missionId', missionId)
            // verify required parameter 'project' is not null or undefined
            assertParamExists('agentumReportControllerDeleteById', 'project', project)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('agentumReportControllerDeleteById', 'id', id)
            const localVarPath = `/missions/{missionId}/projects/{project}/reports/{id}`
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)))
                .replace(`{${"project"}}`, encodeURIComponent(String(project)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {AgentumReportFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerFind: async (missionId: string, project: string, filter?: AgentumReportFilter1, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionId' is not null or undefined
            assertParamExists('agentumReportControllerFind', 'missionId', missionId)
            // verify required parameter 'project' is not null or undefined
            assertParamExists('agentumReportControllerFind', 'project', project)
            const localVarPath = `/missions/{missionId}/projects/{project}/reports`
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)))
                .replace(`{${"project"}}`, encodeURIComponent(String(project)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} id
         * @param {AgentumReportFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerFindById: async (missionId: string, project: string, id: string, filter?: AgentumReportFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionId' is not null or undefined
            assertParamExists('agentumReportControllerFindById', 'missionId', missionId)
            // verify required parameter 'project' is not null or undefined
            assertParamExists('agentumReportControllerFindById', 'project', project)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('agentumReportControllerFindById', 'id', id)
            const localVarPath = `/missions/{missionId}/projects/{project}/reports/{id}`
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)))
                .replace(`{${"project"}}`, encodeURIComponent(String(project)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} origin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerFindByOrigin: async (missionId: string, project: string, origin: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionId' is not null or undefined
            assertParamExists('agentumReportControllerFindByOrigin', 'missionId', missionId)
            // verify required parameter 'project' is not null or undefined
            assertParamExists('agentumReportControllerFindByOrigin', 'project', project)
            // verify required parameter 'origin' is not null or undefined
            assertParamExists('agentumReportControllerFindByOrigin', 'origin', origin)
            const localVarPath = `/missions/{missionId}/projects/{project}/reports/origin/{origin}`
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)))
                .replace(`{${"project"}}`, encodeURIComponent(String(project)))
                .replace(`{${"origin"}}`, encodeURIComponent(String(origin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} id
         * @param {AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession} [agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerUpdateById: async (missionId: string, project: string, id: string, agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession?: AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionId' is not null or undefined
            assertParamExists('agentumReportControllerUpdateById', 'missionId', missionId)
            // verify required parameter 'project' is not null or undefined
            assertParamExists('agentumReportControllerUpdateById', 'project', project)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('agentumReportControllerUpdateById', 'id', id)
            const localVarPath = `/missions/{missionId}/projects/{project}/reports/{id}`
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)))
                .replace(`{${"project"}}`, encodeURIComponent(String(project)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentumReportControllerApi - functional programming interface
 * @export
 */
export const AgentumReportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentumReportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {{ [key: string]: any; }} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentumReportControllerCount(missionId: string, project: string, where?: { [key: string]: any; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agentumReportControllerCount(missionId, project, where, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentumReportControllerApi.agentumReportControllerCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {NewAgentumReport} [newAgentumReport]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentumReportControllerCreate(missionId: string, project: string, newAgentumReport?: NewAgentumReport, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentumReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agentumReportControllerCreate(missionId, project, newAgentumReport, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentumReportControllerApi.agentumReportControllerCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentumReportControllerDeleteById(missionId: string, project: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agentumReportControllerDeleteById(missionId, project, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentumReportControllerApi.agentumReportControllerDeleteById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {AgentumReportFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentumReportControllerFind(missionId: string, project: string, filter?: AgentumReportFilter1, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgentumReportControllerFind200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agentumReportControllerFind(missionId, project, filter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentumReportControllerApi.agentumReportControllerFind']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} id
         * @param {AgentumReportFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentumReportControllerFindById(missionId: string, project: string, id: string, filter?: AgentumReportFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentumReportWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agentumReportControllerFindById(missionId, project, id, filter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentumReportControllerApi.agentumReportControllerFindById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} origin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentumReportControllerFindByOrigin(missionId: string, project: string, origin: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentumReportWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agentumReportControllerFindByOrigin(missionId, project, origin, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentumReportControllerApi.agentumReportControllerFindByOrigin']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} id
         * @param {AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession} [agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentumReportControllerUpdateById(missionId: string, project: string, id: string, agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession?: AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agentumReportControllerUpdateById(missionId, project, id, agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentumReportControllerApi.agentumReportControllerUpdateById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AgentumReportControllerApi - factory interface
 * @export
 */
export const AgentumReportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentumReportControllerApiFp(configuration)
    return {
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {{ [key: string]: any; }} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerCount(missionId: string, project: string, where?: { [key: string]: any; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.agentumReportControllerCount(missionId, project, where, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {NewAgentumReport} [newAgentumReport]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerCreate(missionId: string, project: string, newAgentumReport?: NewAgentumReport, options?: any): AxiosPromise<AgentumReport> {
            return localVarFp.agentumReportControllerCreate(missionId, project, newAgentumReport, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerDeleteById(missionId: string, project: string, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.agentumReportControllerDeleteById(missionId, project, id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {AgentumReportFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerFind(missionId: string, project: string, filter?: AgentumReportFilter1, options?: any): AxiosPromise<Array<AgentumReportControllerFind200ResponseInner>> {
            return localVarFp.agentumReportControllerFind(missionId, project, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} id
         * @param {AgentumReportFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerFindById(missionId: string, project: string, id: string, filter?: AgentumReportFilter, options?: any): AxiosPromise<AgentumReportWithRelations> {
            return localVarFp.agentumReportControllerFindById(missionId, project, id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} origin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerFindByOrigin(missionId: string, project: string, origin: string, options?: any): AxiosPromise<AgentumReportWithRelations> {
            return localVarFp.agentumReportControllerFindByOrigin(missionId, project, origin, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} id
         * @param {AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession} [agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentumReportControllerUpdateById(missionId: string, project: string, id: string, agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession?: AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession, options?: any): AxiosPromise<any> {
            return localVarFp.agentumReportControllerUpdateById(missionId, project, id, agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentumReportControllerApi - object-oriented interface
 * @export
 * @class AgentumReportControllerApi
 * @extends {BaseAPI}
 */
export class AgentumReportControllerApi extends BaseAPI {
    /**
     *
     * @param {string} missionId
     * @param {string} project
     * @param {{ [key: string]: any; }} [where]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentumReportControllerApi
     */
    public agentumReportControllerCount(missionId: string, project: string, where?: { [key: string]: any; }, options?: RawAxiosRequestConfig) {
        return AgentumReportControllerApiFp(this.configuration).agentumReportControllerCount(missionId, project, where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} missionId
     * @param {string} project
     * @param {NewAgentumReport} [newAgentumReport]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentumReportControllerApi
     */
    public agentumReportControllerCreate(missionId: string, project: string, newAgentumReport?: NewAgentumReport, options?: RawAxiosRequestConfig) {
        return AgentumReportControllerApiFp(this.configuration).agentumReportControllerCreate(missionId, project, newAgentumReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} missionId
     * @param {string} project
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentumReportControllerApi
     */
    public agentumReportControllerDeleteById(missionId: string, project: string, id: string, options?: RawAxiosRequestConfig) {
        return AgentumReportControllerApiFp(this.configuration).agentumReportControllerDeleteById(missionId, project, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} missionId
     * @param {string} project
     * @param {AgentumReportFilter1} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentumReportControllerApi
     */
    public agentumReportControllerFind(missionId: string, project: string, filter?: AgentumReportFilter1, options?: RawAxiosRequestConfig) {
        return AgentumReportControllerApiFp(this.configuration).agentumReportControllerFind(missionId, project, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} missionId
     * @param {string} project
     * @param {string} id
     * @param {AgentumReportFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentumReportControllerApi
     */
    public agentumReportControllerFindById(missionId: string, project: string, id: string, filter?: AgentumReportFilter, options?: RawAxiosRequestConfig) {
        return AgentumReportControllerApiFp(this.configuration).agentumReportControllerFindById(missionId, project, id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} missionId
     * @param {string} project
     * @param {string} origin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentumReportControllerApi
     */
    public agentumReportControllerFindByOrigin(missionId: string, project: string, origin: string, options?: RawAxiosRequestConfig) {
        return AgentumReportControllerApiFp(this.configuration).agentumReportControllerFindByOrigin(missionId, project, origin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} missionId
     * @param {string} project
     * @param {string} id
     * @param {AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession} [agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentumReportControllerApi
     */
    public agentumReportControllerUpdateById(missionId: string, project: string, id: string, agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession?: AgentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession, options?: RawAxiosRequestConfig) {
        return AgentumReportControllerApiFp(this.configuration).agentumReportControllerUpdateById(missionId, project, id, agentumReportPartialExcludingIdCreatorFirstnameCreatorLastnameCreatorProfession, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileStorageControllerApi - axios parameter creator
 * @export
 */
export const FileStorageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileStorageControllerGetPresignedUrl: async (missionId: string, project: string, fileName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionId' is not null or undefined
            assertParamExists('fileStorageControllerGetPresignedUrl', 'missionId', missionId)
            // verify required parameter 'project' is not null or undefined
            assertParamExists('fileStorageControllerGetPresignedUrl', 'project', project)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('fileStorageControllerGetPresignedUrl', 'fileName', fileName)
            const localVarPath = `/mission/{missionId}/project/{project}/files/{fileName}`
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)))
                .replace(`{${"project"}}`, encodeURIComponent(String(project)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileStorageControllerApi - functional programming interface
 * @export
 */
export const FileStorageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileStorageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileStorageControllerGetPresignedUrl(missionId: string, project: string, fileName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileStorageControllerGetPresignedUrl(missionId, project, fileName, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageControllerApi.fileStorageControllerGetPresignedUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FileStorageControllerApi - factory interface
 * @export
 */
export const FileStorageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileStorageControllerApiFp(configuration)
    return {
        /**
         *
         * @param {string} missionId
         * @param {string} project
         * @param {string} fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileStorageControllerGetPresignedUrl(missionId: string, project: string, fileName: string, options?: any): AxiosPromise<void> {
            return localVarFp.fileStorageControllerGetPresignedUrl(missionId, project, fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileStorageControllerApi - object-oriented interface
 * @export
 * @class FileStorageControllerApi
 * @extends {BaseAPI}
 */
export class FileStorageControllerApi extends BaseAPI {
    /**
     *
     * @param {string} missionId
     * @param {string} project
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageControllerApi
     */
    public fileStorageControllerGetPresignedUrl(missionId: string, project: string, fileName: string, options?: RawAxiosRequestConfig) {
        return FileStorageControllerApiFp(this.configuration).fileStorageControllerGetPresignedUrl(missionId, project, fileName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GeoJsonControllerApi - axios parameter creator
 * @export
 */
export const GeoJsonControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {{ [key: string]: any; }} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerCount: async (where?: { [key: string]: any; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geo-jsons/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {NewGeoJson} [newGeoJson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerCreate: async (newGeoJson?: NewGeoJson, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geo-jsons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newGeoJson, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerDeleteById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('geoJsonControllerDeleteById', 'id', id)
            const localVarPath = `/geo-jsons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {File} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerFileUpload: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geo-jsons/file-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GeoJsonFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerFind: async (filter?: GeoJsonFilter1, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geo-jsons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {GeoJsonFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerFindById: async (id: string, filter?: GeoJsonFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('geoJsonControllerFindById', 'id', id)
            const localVarPath = `/geo-jsons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {GeoJson} [geoJson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerReplaceById: async (id: string, geoJson?: GeoJson, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('geoJsonControllerReplaceById', 'id', id)
            const localVarPath = `/geo-jsons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geoJson, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [where]
         * @param {GeoJsonPartial} [geoJsonPartial]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerUpdateAll: async (where?: { [key: string]: any; }, geoJsonPartial?: GeoJsonPartial, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geo-jsons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geoJsonPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {GeoJsonPartial} [geoJsonPartial]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerUpdateById: async (id: string, geoJsonPartial?: GeoJsonPartial, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('geoJsonControllerUpdateById', 'id', id)
            const localVarPath = `/geo-jsons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geoJsonPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeoJsonControllerApi - functional programming interface
 * @export
 */
export const GeoJsonControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeoJsonControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {{ [key: string]: any; }} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoJsonControllerCount(where?: { [key: string]: any; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoJsonControllerCount(where, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeoJsonControllerApi.geoJsonControllerCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {NewGeoJson} [newGeoJson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoJsonControllerCreate(newGeoJson?: NewGeoJson, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoJsonControllerCreate(newGeoJson, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeoJsonControllerApi.geoJsonControllerCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoJsonControllerDeleteById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoJsonControllerDeleteById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeoJsonControllerApi.geoJsonControllerDeleteById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {File} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoJsonControllerFileUpload(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoJsonControllerFileUpload200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoJsonControllerFileUpload(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeoJsonControllerApi.geoJsonControllerFileUpload']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {GeoJsonFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoJsonControllerFind(filter?: GeoJsonFilter1, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeoJsonWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoJsonControllerFind(filter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeoJsonControllerApi.geoJsonControllerFind']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {GeoJsonFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoJsonControllerFindById(id: string, filter?: GeoJsonFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoJsonWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoJsonControllerFindById(id, filter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeoJsonControllerApi.geoJsonControllerFindById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {GeoJson} [geoJson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoJsonControllerReplaceById(id: string, geoJson?: GeoJson, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoJsonControllerReplaceById(id, geoJson, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeoJsonControllerApi.geoJsonControllerReplaceById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [where]
         * @param {GeoJsonPartial} [geoJsonPartial]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoJsonControllerUpdateAll(where?: { [key: string]: any; }, geoJsonPartial?: GeoJsonPartial, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoJsonControllerUpdateAll(where, geoJsonPartial, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeoJsonControllerApi.geoJsonControllerUpdateAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {GeoJsonPartial} [geoJsonPartial]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoJsonControllerUpdateById(id: string, geoJsonPartial?: GeoJsonPartial, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoJsonControllerUpdateById(id, geoJsonPartial, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeoJsonControllerApi.geoJsonControllerUpdateById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * GeoJsonControllerApi - factory interface
 * @export
 */
export const GeoJsonControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeoJsonControllerApiFp(configuration)
    return {
        /**
         *
         * @param {{ [key: string]: any; }} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerCount(where?: { [key: string]: any; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.geoJsonControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {NewGeoJson} [newGeoJson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerCreate(newGeoJson?: NewGeoJson, options?: any): AxiosPromise<GeoJson> {
            return localVarFp.geoJsonControllerCreate(newGeoJson, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerDeleteById(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.geoJsonControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {File} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerFileUpload(file?: File, options?: any): AxiosPromise<GeoJsonControllerFileUpload200Response> {
            return localVarFp.geoJsonControllerFileUpload(file, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GeoJsonFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerFind(filter?: GeoJsonFilter1, options?: any): AxiosPromise<Array<GeoJsonWithRelations>> {
            return localVarFp.geoJsonControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {GeoJsonFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerFindById(id: string, filter?: GeoJsonFilter, options?: any): AxiosPromise<GeoJsonWithRelations> {
            return localVarFp.geoJsonControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {GeoJson} [geoJson]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerReplaceById(id: string, geoJson?: GeoJson, options?: any): AxiosPromise<any> {
            return localVarFp.geoJsonControllerReplaceById(id, geoJson, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {{ [key: string]: any; }} [where]
         * @param {GeoJsonPartial} [geoJsonPartial]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerUpdateAll(where?: { [key: string]: any; }, geoJsonPartial?: GeoJsonPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.geoJsonControllerUpdateAll(where, geoJsonPartial, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {GeoJsonPartial} [geoJsonPartial]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoJsonControllerUpdateById(id: string, geoJsonPartial?: GeoJsonPartial, options?: any): AxiosPromise<any> {
            return localVarFp.geoJsonControllerUpdateById(id, geoJsonPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeoJsonControllerApi - object-oriented interface
 * @export
 * @class GeoJsonControllerApi
 * @extends {BaseAPI}
 */
export class GeoJsonControllerApi extends BaseAPI {
    /**
     *
     * @param {{ [key: string]: any; }} [where]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoJsonControllerApi
     */
    public geoJsonControllerCount(where?: { [key: string]: any; }, options?: RawAxiosRequestConfig) {
        return GeoJsonControllerApiFp(this.configuration).geoJsonControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {NewGeoJson} [newGeoJson]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoJsonControllerApi
     */
    public geoJsonControllerCreate(newGeoJson?: NewGeoJson, options?: RawAxiosRequestConfig) {
        return GeoJsonControllerApiFp(this.configuration).geoJsonControllerCreate(newGeoJson, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoJsonControllerApi
     */
    public geoJsonControllerDeleteById(id: string, options?: RawAxiosRequestConfig) {
        return GeoJsonControllerApiFp(this.configuration).geoJsonControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoJsonControllerApi
     */
    public geoJsonControllerFileUpload(file?: File, options?: RawAxiosRequestConfig) {
        return GeoJsonControllerApiFp(this.configuration).geoJsonControllerFileUpload(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GeoJsonFilter1} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoJsonControllerApi
     */
    public geoJsonControllerFind(filter?: GeoJsonFilter1, options?: RawAxiosRequestConfig) {
        return GeoJsonControllerApiFp(this.configuration).geoJsonControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {GeoJsonFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoJsonControllerApi
     */
    public geoJsonControllerFindById(id: string, filter?: GeoJsonFilter, options?: RawAxiosRequestConfig) {
        return GeoJsonControllerApiFp(this.configuration).geoJsonControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {GeoJson} [geoJson]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoJsonControllerApi
     */
    public geoJsonControllerReplaceById(id: string, geoJson?: GeoJson, options?: RawAxiosRequestConfig) {
        return GeoJsonControllerApiFp(this.configuration).geoJsonControllerReplaceById(id, geoJson, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {{ [key: string]: any; }} [where]
     * @param {GeoJsonPartial} [geoJsonPartial]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoJsonControllerApi
     */
    public geoJsonControllerUpdateAll(where?: { [key: string]: any; }, geoJsonPartial?: GeoJsonPartial, options?: RawAxiosRequestConfig) {
        return GeoJsonControllerApiFp(this.configuration).geoJsonControllerUpdateAll(where, geoJsonPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {GeoJsonPartial} [geoJsonPartial]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoJsonControllerApi
     */
    public geoJsonControllerUpdateById(id: string, geoJsonPartial?: GeoJsonPartial, options?: RawAxiosRequestConfig) {
        return GeoJsonControllerApiFp(this.configuration).geoJsonControllerUpdateById(id, geoJsonPartial, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * KeazExchangeControllerApi - axios parameter creator
 * @export
 */
export const KeazExchangeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keazExchangeControllerTaskGenerate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/keaz/taskGenerate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KeazExchangeControllerApi - functional programming interface
 * @export
 */
export const KeazExchangeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KeazExchangeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keazExchangeControllerTaskGenerate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keazExchangeControllerTaskGenerate(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['KeazExchangeControllerApi.keazExchangeControllerTaskGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * KeazExchangeControllerApi - factory interface
 * @export
 */
export const KeazExchangeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeazExchangeControllerApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keazExchangeControllerTaskGenerate(options?: any): AxiosPromise<any> {
            return localVarFp.keazExchangeControllerTaskGenerate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KeazExchangeControllerApi - object-oriented interface
 * @export
 * @class KeazExchangeControllerApi
 * @extends {BaseAPI}
 */
export class KeazExchangeControllerApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeazExchangeControllerApi
     */
    public keazExchangeControllerTaskGenerate(options?: RawAxiosRequestConfig) {
        return KeazExchangeControllerApiFp(this.configuration).keazExchangeControllerTaskGenerate(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MissionsControllerApi - axios parameter creator
 * @export
 */
export const MissionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {{ [key: string]: any; }} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionsControllerCount: async (where?: { [key: string]: any; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/missions/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MissionsFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionsControllerFind: async (filter?: MissionsFilter1, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/missions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {MissionsFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionsControllerFindById: async (id: string, filter?: MissionsFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('missionsControllerFindById', 'id', id)
            const localVarPath = `/missions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionsControllerGetMissionTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('missionsControllerGetMissionTemplate', 'id', id)
            const localVarPath = `/missions/{id}/template`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MissionsControllerApi - functional programming interface
 * @export
 */
export const MissionsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MissionsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {{ [key: string]: any; }} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionsControllerCount(where?: { [key: string]: any; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionsControllerCount(where, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MissionsControllerApi.missionsControllerCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {MissionsFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionsControllerFind(filter?: MissionsFilter1, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MissionsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionsControllerFind(filter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MissionsControllerApi.missionsControllerFind']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {MissionsFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionsControllerFindById(id: string, filter?: MissionsFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionsControllerFindById(id, filter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MissionsControllerApi.missionsControllerFindById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionsControllerGetMissionTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionsControllerGetMissionTemplate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionsControllerGetMissionTemplate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MissionsControllerApi.missionsControllerGetMissionTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MissionsControllerApi - factory interface
 * @export
 */
export const MissionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MissionsControllerApiFp(configuration)
    return {
        /**
         *
         * @param {{ [key: string]: any; }} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionsControllerCount(where?: { [key: string]: any; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.missionsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MissionsFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionsControllerFind(filter?: MissionsFilter1, options?: any): AxiosPromise<Array<MissionsWithRelations>> {
            return localVarFp.missionsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {MissionsFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionsControllerFindById(id: string, filter?: MissionsFilter, options?: any): AxiosPromise<MissionsWithRelations> {
            return localVarFp.missionsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionsControllerGetMissionTemplate(id: string, options?: any): AxiosPromise<MissionsControllerGetMissionTemplate200Response> {
            return localVarFp.missionsControllerGetMissionTemplate(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MissionsControllerApi - object-oriented interface
 * @export
 * @class MissionsControllerApi
 * @extends {BaseAPI}
 */
export class MissionsControllerApi extends BaseAPI {
    /**
     *
     * @param {{ [key: string]: any; }} [where]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionsControllerApi
     */
    public missionsControllerCount(where?: { [key: string]: any; }, options?: RawAxiosRequestConfig) {
        return MissionsControllerApiFp(this.configuration).missionsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MissionsFilter1} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionsControllerApi
     */
    public missionsControllerFind(filter?: MissionsFilter1, options?: RawAxiosRequestConfig) {
        return MissionsControllerApiFp(this.configuration).missionsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {MissionsFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionsControllerApi
     */
    public missionsControllerFindById(id: string, filter?: MissionsFilter, options?: RawAxiosRequestConfig) {
        return MissionsControllerApiFp(this.configuration).missionsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionsControllerApi
     */
    public missionsControllerGetMissionTemplate(id: string, options?: RawAxiosRequestConfig) {
        return MissionsControllerApiFp(this.configuration).missionsControllerGetMissionTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PingControllerApi - axios parameter creator
 * @export
 */
export const PingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingControllerPing: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingControllerApi - functional programming interface
 * @export
 */
export const PingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingControllerPing(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingControllerPing(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PingControllerApi.pingControllerPing']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PingControllerApi - factory interface
 * @export
 */
export const PingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingControllerApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingControllerPing(options?: any): AxiosPromise<PingResponse> {
            return localVarFp.pingControllerPing(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingControllerApi - object-oriented interface
 * @export
 * @class PingControllerApi
 * @extends {BaseAPI}
 */
export class PingControllerApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingControllerApi
     */
    public pingControllerPing(options?: RawAxiosRequestConfig) {
        return PingControllerApiFp(this.configuration).pingControllerPing(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersControllerApi - axios parameter creator
 * @export
 */
export const UsersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {UsersFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFind: async (filter?: UsersFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UsersControllerLoginRequest} usersControllerLoginRequest The input of login function
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLogin: async (usersControllerLoginRequest: UsersControllerLoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersControllerLoginRequest' is not null or undefined
            assertParamExists('usersControllerLogin', 'usersControllerLoginRequest', usersControllerLoginRequest)
            const localVarPath = `/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersControllerLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UsersControllerRefreshRequest} usersControllerRefreshRequest Reissuing Access Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRefresh: async (usersControllerRefreshRequest: UsersControllerRefreshRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersControllerRefreshRequest' is not null or undefined
            assertParamExists('usersControllerRefresh', 'usersControllerRefreshRequest', usersControllerRefreshRequest)
            const localVarPath = `/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersControllerRefreshRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerWhoAmI: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/whoAmI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersControllerApi - functional programming interface
 * @export
 */
export const UsersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {UsersFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFind(filter?: UsersFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UsersWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFind(filter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersControllerApi.usersControllerFind']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {UsersControllerLoginRequest} usersControllerLoginRequest The input of login function
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerLogin(usersControllerLoginRequest: UsersControllerLoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersControllerLogin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerLogin(usersControllerLoginRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersControllerApi.usersControllerLogin']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {UsersControllerRefreshRequest} usersControllerRefreshRequest Reissuing Access Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRefresh(usersControllerRefreshRequest: UsersControllerRefreshRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersControllerRefresh200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRefresh(usersControllerRefreshRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersControllerApi.usersControllerRefresh']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerWhoAmI(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerWhoAmI(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersControllerApi.usersControllerWhoAmI']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UsersControllerApi - factory interface
 * @export
 */
export const UsersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersControllerApiFp(configuration)
    return {
        /**
         *
         * @param {UsersFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFind(filter?: UsersFilter, options?: any): AxiosPromise<Array<UsersWithRelations>> {
            return localVarFp.usersControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UsersControllerLoginRequest} usersControllerLoginRequest The input of login function
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLogin(usersControllerLoginRequest: UsersControllerLoginRequest, options?: any): AxiosPromise<UsersControllerLogin200Response> {
            return localVarFp.usersControllerLogin(usersControllerLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UsersControllerRefreshRequest} usersControllerRefreshRequest Reissuing Access Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRefresh(usersControllerRefreshRequest: UsersControllerRefreshRequest, options?: any): AxiosPromise<UsersControllerRefresh200Response> {
            return localVarFp.usersControllerRefresh(usersControllerRefreshRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerWhoAmI(options?: any): AxiosPromise<UsersWithRelations> {
            return localVarFp.usersControllerWhoAmI(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersControllerApi - object-oriented interface
 * @export
 * @class UsersControllerApi
 * @extends {BaseAPI}
 */
export class UsersControllerApi extends BaseAPI {
    /**
     *
     * @param {UsersFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerFind(filter?: UsersFilter, options?: RawAxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UsersControllerLoginRequest} usersControllerLoginRequest The input of login function
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerLogin(usersControllerLoginRequest: UsersControllerLoginRequest, options?: RawAxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerLogin(usersControllerLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UsersControllerRefreshRequest} usersControllerRefreshRequest Reissuing Access Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerRefresh(usersControllerRefreshRequest: UsersControllerRefreshRequest, options?: RawAxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerRefresh(usersControllerRefreshRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerWhoAmI(options?: RawAxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerWhoAmI(options).then((request) => request(this.axios, this.basePath));
    }
}



