import { useMemo } from 'react'
import * as THREE from 'three'
import earcut from 'earcut'
import { useCursor } from '@react-three/drei'
import { ThreeEvent } from '@react-three/fiber'

interface FilledPolygonProps {
  points: THREE.Vector3[]
  color: string | number
  onSelected: (event: ThreeEvent<MouseEvent>) => void
  isHovered: boolean
  onPointerOver: (event: ThreeEvent<MouseEvent>) => void
  onPointerOut: (event: ThreeEvent<MouseEvent>) => void
}

export const FilledPolygon = ({
  points,
  color,
  onSelected,
  isHovered,
  onPointerOver,
  onPointerOut
}: FilledPolygonProps) => {
  useCursor(isHovered)

  const geom = useMemo(() => {
    const geometry = new THREE.BufferGeometry()
    const flatPoints = points.map((el) => el.toArray()).flat()
    const vertices = new Float32Array(flatPoints)

    const triangles = earcut(flatPoints, [], 3)
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3))
    geometry.setIndex(triangles)
    geometry.computeVertexNormals()

    return geometry
  }, [points])

  if (points.length < 3) {
    return null
  }

  return (
    <mesh
      geometry={geom}
      onClick={onSelected}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
    >
      <meshBasicMaterial color={color} side={THREE.DoubleSide} transparent={true} opacity={0.6} />
    </mesh>
  )
}
