import type { BaseQueryFn, EndpointBuilder } from '@reduxjs/toolkit/query'
import type { AxiosError, AxiosPromise } from 'axios'
import { ErrorHandler } from '@api/utils/errorHandler'
import { ResultDescription } from '@reduxjs/toolkit/src/query/endpointDefinitions'
import type { BaseQueryError, BaseQueryMeta } from '@reduxjs/toolkit/src/query/baseQueryTypes'

export function rtkBuilderMutation<P1 extends unknown[], TagTypes extends string, R = unknown>(
  build: EndpointBuilder<BaseQueryFn, TagTypes, string>,
  apiCall: (...args1: P1) => AxiosPromise<R>,
  invalidatesTags?: ResultDescription<
    TagTypes,
    R,
    P1 | undefined,
    BaseQueryError<BaseQueryFn>,
    BaseQueryMeta<BaseQueryFn>
  >
) {
  return build.mutation<
    Awaited<ReturnType<typeof apiCall>>['data'],
    Parameters<typeof apiCall> | undefined
  >({
    async queryFn(args) {
      try {
        return await apiCall(...(args ?? ([] as unknown as P1)))
      } catch (e) {
        const err = ErrorHandler(e as AxiosError)
        return {
          error: err
          // data: '' as R,
          // meta: ''
        }
      }
    },
    providesTags: undefined,
    invalidatesTags
  })
}
