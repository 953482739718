import styles from '../index.module.less'
import { Text } from '@/components/ui/typography/text'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import {
  BaseReportFields,
  MechanicalStepCoolantFlow,
  MechanicalStepCoolingCircuitFlush,
  MechanicalStepCoolingCircuitFlushFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import { Title } from '@/components/ui/typography/title'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

import RadioButtonWrapper from '@smwb/summer-ui/dist/connects/rff/radioButton/radioButton'
import { ErrorMessage } from '@modules/missions/keaz/step/components/contentBySteps/components/errorMessage'

export interface CoolingCircuitFlushProps {
  compact: boolean
  step: MechanicalStepCoolingCircuitFlush
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function CoolingCircuitFlush({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: CoolingCircuitFlushProps) {
  return (
    <div>
      <StepForm<MechanicalStepCoolingCircuitFlush>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepCoolingCircuitFlush>
              compact={compact}
              title='Фото подключенной установки промывки к контуру охлаждения гидравлического масла'
              fieldName={
                MechanicalStepCoolingCircuitFlushFields.flushingSystemConnectionCoolingCircuitPhoto
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepCoolingCircuitFlush>
              compact={compact}
              title='Фото подключенной установки промывки к кольцу охлаждения на загрузочном отверстии'
              fieldName={
                MechanicalStepCoolingCircuitFlushFields.flushingSystemConnectionCoolingRingPhoto
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <RadioButtonWrapper
              label='Проток воды отсутствует'
              name={MechanicalStepCoolingCircuitFlushFields.coolantFlow}
              value={MechanicalStepCoolantFlow.noWaterFlow}
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <RadioButtonWrapper
              label='Проток воды присутствует, но недостаточен'
              name={MechanicalStepCoolingCircuitFlushFields.coolantFlow}
              value={MechanicalStepCoolantFlow.insufficientWaterFlow}
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <RadioButtonWrapper
              label='Проток воды достаточный'
              name={MechanicalStepCoolingCircuitFlushFields.coolantFlow}
              value={MechanicalStepCoolantFlow.sufficientWaterFlow}
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <ErrorMessage<MechanicalStepCoolingCircuitFlush>
          fieldName={MechanicalStepCoolingCircuitFlushFields.coolantFlow}
          className={styles.errorMessage}
        />

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepCoolingCircuitFlushFields.deviations}
          commentFieldName={MechanicalStepCoolingCircuitFlushFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
