import { AxiosError } from 'axios'
import { isErrorType } from '@api/utils/fakeQuery'

function hasErrorInData<T>(e: AxiosError<{ error: object }> | AxiosError<T>): e is AxiosError<{
  error: object
}> {
  return 'error' in (e.response?.data || {})
}

export function ErrorHandler<T>(e: AxiosError<T>) {
  if (e.response && hasErrorInData(e)) {
    return e.response.data.error
  }
  return e
}

export function getErrorMessage(e: unknown): string {
  if (isErrorType(e)) {
    return e.message
  }
  console.error('Добавить обработку для ошибки', e)
  return JSON.stringify(e)
}
