import { Duplet, GeoData, Point } from '@modules/panorama/types'
import { clickToCoords, getDuplet } from '@modules/panorama/utils/geoMath'

export function getCoords(pointObjects: Point[][], geo: GeoData) {
  const {
    geometry: {
      coordinates: [x, y, z]
    },
    properties: { yaw }
  } = geo

  const coords: number[][] = []

  for (const pointObject of pointObjects) {
    const duplets: Duplet[] = []

    for (const point of pointObject) {
      duplets.push(getDuplet(y, x, z, yaw, point))
    }

    coords.push(clickToCoords(duplets))
  }

  return coords
}
