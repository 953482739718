import React from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { Login } from '@pages/login/login'
import { Layout } from '@modules/layout/layout'
import { Missions } from '@pages/missions/missions'
import { Mission } from '@pages/missions/mission/mission'
import { KeazTask } from '@pages/missions/mission/keaz/keazTask'
import { KeazGroup } from '@pages/missions/mission/keaz/keazGroup'
import { KeazStep } from '@pages/missions/mission/keaz/keazStep'
import { Urls } from '@/urls'

export const router = createBrowserRouter([
  {
    path: Urls.Root,
    element: <Navigate to={Urls.Missions} />
  },
  {
    element: <Layout />,
    children: [
      {
        path: Urls.Missions,
        element: <Missions />
      },
      {
        path: Urls.Mission,
        element: <Mission />
      },
      {
        path: Urls.KeazTask,
        element: <KeazTask />
      },
      {
        path: Urls.KeazGroup,
        element: <KeazGroup />
      },
      {
        path: Urls.KeazStep,
        element: <KeazStep />
      }
    ]
  },
  {
    path: Urls.Login,
    element: <Login />
  }
])
