import * as THREE from 'three'
import { ThreeEvent } from '@react-three/fiber'
import { useHovered } from '@modules/panorama/hooks/useHovered'
import { colors } from '@styles/colors'

export interface PointProps {
  position: THREE.Vector3
  isSelected?: boolean
  onSelected?: () => void
}

export function Point({ position, isSelected, onSelected }: PointProps) {
  const { isHovered, onPointerOver, onPointerOut } = useHovered()

  const onClick = (event: ThreeEvent<MouseEvent>) => {
    if (event.ctrlKey === true) {
      return
    }

    onSelected?.()
  }

  const color = isSelected ? colors.red : isHovered ? colors.yellow : colors.green

  return (
    <mesh
      position={position}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
      onClick={onClick}
    >
      <sphereGeometry args={[10, 32, 32]} />
      <meshBasicMaterial color={color} />
    </mesh>
  )
}
