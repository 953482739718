import { SelectField } from '@/components/ui/inputs/selectField/selectField'
import { apiCall } from '@api/resources/apiCall'
import React, { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react'
import { SelectFieldProps } from '@smwb/summer-ui/dist/components/inputs/selectField/selectField'
import { PROJECT } from '@modules/missions/keaz/const'
import { BaseReportFields } from '@modules/missions/keaz/types'
import { useParams } from 'react-router-dom'
import { AgentumReportWithRelations } from '@api/generated'
import { useSnackbar } from '@smwb/summer-ui'

interface AssigneeProps {
  task: AgentumReportWithRelations
  className?: string
}

interface Item {
  key: string
  text: string
  value: string
}

interface UpdateReportByIdArgs {
  isLoading?: boolean
  error?: unknown
  data?: unknown
}

export function Assignee({ className, task }: AssigneeProps) {
  const { id } = useParams()
  const [assignees, setAssignees] = useState<Item['value'][]>(
    (task[BaseReportFields.assignees] || []) as Item['value'][]
  )
  const [searchValue, setSearchValue] = useState('')

  const { addSnackbar } = useSnackbar()
  const addSnackbarRef = useRef(addSnackbar)

  const [
    updateReportById,
    {
      data: updateReportByIdData,
      isLoading: updateReportByIdIsLoading,
      error: updateReportByIdError
    }
  ] = apiCall.useAgentumReportControllerUpdateByIdMutation<UpdateReportByIdArgs>()

  useEffect(() => {
    if (!updateReportByIdIsLoading) {
      if (updateReportByIdData !== undefined) {
        addSnackbarRef.current({
          message: 'Исполнители обновлены',
          position: 'top-right',
          variant: 'success'
        })
      } else if (updateReportByIdError) {
        addSnackbarRef.current({
          message: 'Ошибка изменения исполнителей',
          position: 'top-right',
          variant: 'danger'
        })
      }
    }
  }, [updateReportByIdData, updateReportByIdIsLoading, updateReportByIdError])

  const { data: users = [] } = apiCall.useUsersControllerFindQuery([
    {
      where: {
        or: [
          {
            firstname: {
              like: searchValue,
              options: 'i'
            }
          },
          {
            _id: {
              inq: assignees
            }
          }
        ]
      }
    }
  ])

  const items: Item[] = useMemo(
    () =>
      users.map((user) => ({
        key: user._id!,
        text: String(user.firstname) + (user.lastname ? ` ${user.lastname}` : ''),
        value: user._id!
      })),
    [users]
  )

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('onSearch')
    setSearchValue(e.target.value)
  }

  const onChange = (e: SyntheticEvent, data: SelectFieldProps) => {
    setAssignees(data.value as Item['value'][])
    // setSearchValue('')

    if (id) {
      void updateReportById([
        id,
        PROJECT,
        task[BaseReportFields.id] as string,
        {
          [BaseReportFields.assignees]: data.value
        }
      ])
    }
  }

  return (
    <div className={className}>
      <SelectField
        label={'Исполнитель'}
        items={items}
        search='menu'
        menuNoItemsMessage={'Исполнителей не найдено'}
        fullWidth={true}
        multiple={true}
        value={assignees}
        onChange={onChange}
        onSearch={onSearch}
      />
    </div>
  )
}
