import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import '@smwb/summer-ui/styles/normalize.css'
import './styles/main.less'
import { store } from '@redux/store/store'
import { router } from '@/routes'
import { COOKIES } from '@api/utils/const'
import { Cookies } from 'react-cookie'
import { setLoggedIn } from '@redux/slices/userSlice'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SnackbarProvider } from '@smwb/summer-ui'

const cookies = new Cookies(null, { path: '/' })

export function App() {
  useEffect(() => {
    const token = cookies.get(COOKIES.JWT_TOKEN) as string | undefined
    const refreshToken = cookies.get(COOKIES.REFRESH_TOKEN) as string | undefined
    if (token && refreshToken) {
      setLoggedIn(store.dispatch, token, refreshToken)
    }
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <SnackbarProvider>
          <RouterProvider router={router} future={{ v7_startTransition: true }} />
        </SnackbarProvider>
      </Provider>
    </DndProvider>
  )
}
