import styles from '../index.module.less'
import { Text } from '@/components/ui/typography/text'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import {
  BaseReportFields,
  MechanicalStepLimitSwitchCheck,
  MechanicalStepLimitSwitchCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

import { ErrorMessage } from '@modules/missions/keaz/step/components/contentBySteps/components/errorMessage'

export interface LimitSwitchCheckProps {
  compact: boolean
  step: MechanicalStepLimitSwitchCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function LimitSwitchCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: LimitSwitchCheckProps) {
  return (
    <div>
      <StepForm<MechanicalStepLimitSwitchCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepLimitSwitchCheckFields.mainScreenLimitSwitchCheck}
              label='Проверка концевиков основного защитного экрана проведена'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={MechanicalStepLimitSwitchCheckFields.mainScreenLimitSwitchCheck}
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepLimitSwitchCheck>
              compact={compact}
              title='Фото дисплея с результатом проверки концевиков основного защитного экрана'
              fieldName={MechanicalStepLimitSwitchCheckFields.mainScreenLimitSwitchDisplayPhoto}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepLimitSwitchCheckFields.nozzleScreenLimitSwitchCheck}
              label='Проверка концевиков экрана сопла проведена'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={MechanicalStepLimitSwitchCheckFields.nozzleScreenLimitSwitchCheck}
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepLimitSwitchCheck>
              compact={compact}
              title='Фото дисплея с результатом проверки концевиков экрана'
              fieldName={MechanicalStepLimitSwitchCheckFields.nozzleScreenLimitSwitchDisplayPhoto}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepLimitSwitchCheckFields.guideRailsCheck}
              label='Проверка направляющих проведена'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={MechanicalStepLimitSwitchCheckFields.guideRailsCheck}
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepLimitSwitchCheck>
              compact={compact}
              title='Фото дисплея с результатом проверки направляющих'
              fieldName={MechanicalStepLimitSwitchCheckFields.guideRailsDisplayPhoto}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepLimitSwitchCheckFields.deviations}
          commentFieldName={MechanicalStepLimitSwitchCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
