import React, { SyntheticEvent, useState } from 'react'
import { SelectField, MenuItem } from '@smwb/summer-ui'
import { DropdownMenuPropTypes } from '@smwb/summer-ui/dist/components/inputs/selectField/dropdownMenu'
import {
  OnSelectChange,
  SelectFieldProps
} from '@smwb/summer-ui/dist/components/inputs/selectField/selectField'
import { InferProps } from 'prop-types'
import { Icon } from '@/components/ui/base/icon/icon'
import style from './searchField.module.less'
import cn from 'classnames'

export type MenuItemType = InferProps<typeof DropdownMenuPropTypes>['items'][number]

export interface SearchFieldProps {
  className?: string
  items: MenuItemType[]
  onSearch: (text: string) => void
  resetSearch: () => void
  onChange: OnSelectChange
  isLoading?: boolean
  fullWidth?: boolean
}

export const SearchField = ({ className, items, ...props }: SearchFieldProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [value, setValue] = useState<number | null>(null)

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    props.onSearch(e.target.value)
  }

  const resetSearch = () => {
    setSearchTerm('')
  }

  const onChange = (e: SyntheticEvent, data: SelectFieldProps) => {
    setSearchTerm('')
    setValue(data.value as number)

    props.onChange(e, data)
  }

  const endAdornment = React.useMemo(
    () =>
      searchTerm.length || value !== null ? (
        <Icon
          icon={'close'}
          className={style['icon-close']}
          onClick={() => {
            resetSearch()
            setValue(null)
            props.resetSearch()
          }}
        />
      ) : (
        <Icon icon={'search'} className={style['icon-search']} />
      ),
    [props, searchTerm.length, value]
  )

  const menuNoItemsMessage = React.useMemo(
    () =>
      searchTerm.length ? <span>Ничего не найдено</span> : <span>Введите текст для поиска</span>,
    [searchTerm]
  )

  return (
    <div className={cn(className, style['search-field'])}>
      <SelectField
        {...props}
        value={value}
        onSearch={onSearch}
        resetSearch={resetSearch}
        search={true}
        endAdornment={endAdornment}
        hideSelectArrowIcon={true}
        menuNoItemsMessage={menuNoItemsMessage}
        menuMaxHeight={200}
        variant={'filled'}
        items={items}
        onChange={onChange}
        optionRenderer={({ ...props }) => {
          const item = items.find((item) => item.value === props.value)
          const menuItemProps = { ...props, optionRenderer: undefined }

          return (
            <MenuItem {...menuItemProps}>
              <span>
                <span className={style['menu-item__header']}>{item?.text}</span>
                <span className={style['menu-item__text']}>{item?.helperText}</span>
              </span>
            </MenuItem>
          )
        }}
      />
    </div>
  )
}
