import style from './formGroup.module.less'
import { ReactNode } from 'react'

interface FormGroupProps {
  children: ReactNode | ReactNode[]
}

export function FormGroup({ children }: FormGroupProps) {
  return <div className={style.formGroup}>{children}</div>
}
