import React, { useState } from 'react'
import style from './userMenu.module.less'
import { Menu, MenuItem, MenuItemText } from '@smwb/summer-ui'
import cn from 'classnames'
import { generatePath, useNavigate } from 'react-router-dom'
import { Urls } from '@/urls'
import { setLogout } from '@redux/slices/userSlice'

interface UserMenuProps {
  className?: string
}

export const UserMenu = ({ className }: UserMenuProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null)

  const navigate = useNavigate()

  return (
    <>
      <div
        className={cn(className, style.user)}
        ref={setAnchorElement}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        <img
          src='https://static.tildacdn.com/tild3466-6161-4865-a333-653665656439/Agentum___Red.svg'
          alt=''
          className={style.user__image}
        />
      </div>

      <Menu
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        anchorElement={anchorElement}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 6]
            }
          }
        ]}
      >
        <MenuItem
          onClick={() => {
            setLogout()
            navigate(generatePath(Urls.Login))
          }}
        >
          <MenuItemText>Выйти</MenuItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
