import style from './textArea.module.less'
import React, { ChangeEventHandler } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import cn from 'classnames'
import { useForkRef } from '@smwb/summer-ui/dist/hooks/useForkRef'
import { isFilled } from '@smwb/summer-ui/dist/components/inputs/textField/utils'
import { useSafeLayoutEffect } from '@smwb/summer-ui/dist/hooks/useSafeLayoutEffect'
import { TextFieldWrapper } from '@smwb/summer-ui'

export const textAreaPropTypes = {
  label: PropTypes.string,
  helperText: PropTypes.node,
  variant: PropTypes.oneOf(['outlined', 'filled']),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  id: PropTypes.string,
  inline: PropTypes.bool,
  autoComplete: PropTypes.string,
  rows: PropTypes.number
}

export type TextAreaProps = Omit<
  InferProps<typeof textAreaPropTypes>,
  'onChange' | 'onFocus' | 'onBlur' | 'inputMode'
> & {
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => void
} & Pick<
    JSX.IntrinsicElements['textarea'],
    | 'defaultValue'
    | 'name'
    | 'readOnly'
    | 'placeholder'
    | 'disabled'
    | 'value'
    | 'onFocus'
    | 'onBlur'
    | 'id'
    | 'autoComplete'
    | 'inputMode'
    | 'rows'
  >

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
  props,
  ref
) {
  const {
    label,
    helperText,
    variant = 'outlined',
    className,
    disabled,
    value,
    defaultValue,
    onChange: onChangeProp,
    onFocus: onFocusProp,
    onBlur: onBlurProp,
    error,
    name,
    readOnly,
    placeholder,
    fullWidth,
    id,
    startAdornment,
    endAdornment,
    inline,
    autoComplete,
    inputMode,
    rows,
    ...rest
  } = props
  const [isFocused, setIsFocused] = React.useState<boolean>(false)
  const [isFilledState, setIsFilledState] = React.useState<boolean>(false)

  const { current: isControlled } = React.useRef<boolean>(value != null)
  const inputRef = React.useRef<HTMLTextAreaElement>(null)
  const inputForkRef = useForkRef<HTMLTextAreaElement>(ref, inputRef)

  const onFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (disabled) {
      event.stopPropagation()
      return
    }

    onFocusProp?.(event)

    setIsFocused(true)
  }

  const onBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    onBlurProp?.(event)

    setIsFocused(false)
  }

  const checkDirty = React.useCallback(
    (obj: Parameters<typeof isFilled>[0]) => {
      if (isFilled(obj)) {
        if (!isFilledState) {
          setIsFilledState(true)
        }
      } else if (isFilledState) {
        setIsFilledState(false)
      }
    },
    [isFilledState]
  )

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    if (!isControlled) {
      const element = event.target || inputRef.current

      checkDirty({
        value: element.value
      })
    }
    onChangeProp?.(event, props)
  }

  const onHolderMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (inputRef.current && event.currentTarget === event.target) {
      event.preventDefault()
      inputRef.current.focus()
    }
  }

  React.useEffect(() => {
    if (disabled && isFocused) {
      setIsFocused(false)
    }
  }, [disabled, isFocused])

  React.useEffect(() => {
    checkDirty(inputRef.current)
  }, [checkDirty])

  useSafeLayoutEffect(() => {
    if (isControlled) {
      checkDirty({ value })
    }
  }, [value, isControlled, checkDirty])

  const inputClasses = cn(
    'smwb-text-field__input',
    (!label || inline) && 'smwb-text-field__input--show-placeholder',
    startAdornment && 'smwb-text-field__input--adornment-start',
    endAdornment && 'smwb-text-field__input--adornment-end',
    inline && 'smwb-text-field__input--inline',
    style.textarea
  )

  return (
    <TextFieldWrapper
      label={label}
      helperText={helperText}
      variant={variant}
      disabled={disabled}
      className={className}
      error={error}
      fullWidth={fullWidth}
      isFilledState={isFilledState}
      isFocused={isFocused}
      htmlFor={id}
      shrink={Boolean(startAdornment)}
      onHolderMouseDown={onHolderMouseDown}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      inline={inline}
      {...rest}
    >
      <textarea
        defaultValue={defaultValue}
        name={name}
        readOnly={readOnly}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        className={inputClasses}
        ref={inputForkRef}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        id={id}
        autoComplete={autoComplete}
        inputMode={inputMode}
        rows={rows}
      />
    </TextFieldWrapper>
  )
})

TextArea.propTypes = textAreaPropTypes
