import React from 'react'
import { Line2 } from 'three/examples/jsm/lines/Line2'
import { LineProps as DreiLineProps, Line as DreiLine } from '@react-three/drei'
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2'
import { ForwardRefComponent } from '@react-three/drei/helpers/ts-utils'
import { ThreeEvent } from '@react-three/fiber'
import { useHovered } from '@modules/panorama/hooks/useHovered'
import { colors } from '@styles/colors'

interface LineProps extends DreiLineProps {
  isSelected?: boolean
  onSelected?: () => void
}

export const Line: ForwardRefComponent<LineProps, Line2 | LineSegments2> = React.forwardRef<
  Line2 | LineSegments2,
  LineProps
>(function Line(props, ref) {
  const { points, isSelected, onSelected, ...rest } = props
  const { isHovered, onPointerOver, onPointerOut } = useHovered()

  const onClick = (event: ThreeEvent<MouseEvent>) => {
    if (event.ctrlKey === true) {
      return
    }

    onSelected?.()
  }

  const color = isSelected ? colors.red : isHovered ? colors.yellow : colors.green

  return (
    <DreiLine
      ref={ref}
      points={points}
      lineWidth={2}
      color={color}
      {...rest}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
      onClick={onClick}
    />
  )
})
